import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../Format/Library/fontawesome/css/all.css';
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";
// import { isMobile, isTablet, isDesktop, browserName, osName, engineName } from 'react-device-detect';

import { setCookie } from '../Format/En/Cookie';


function Register() {

    const [countdownTime, setCountdownTime] = useState(180);
    const [isCounting, setIsCounting] = useState(false);
    const [selectedOption, setSelectedOption] = useState('Personal');



    const [section_Part_w_1, section_Part_Re_w_1] = useState(1);
    const [section_Part_w_2, section_Part_Re_w_2] = useState(1);


    const Section_Part_1 = () => {
        document.getElementById('Section_1').classList.add('hidden');
        document.getElementById('Section_2').classList.remove('hidden');
    };




    const Section_Part_2 = () => {




        const email            = document.getElementById('Email').value;
        const emailPattern     = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (emailPattern.test(email)) {
            section_Part_Re_w_1(2);


            var data = {
                Email:            email,
            };

            var actionURL = `${window.Web_Url_Api}/v1/Registration/Send-Email.php`;

            fetch(actionURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then(response => response.json())
                .then(data => {
                    if ( data.status === 'true'  ){
                        section_Part_Re_w_1(3);
                        document.getElementById('Section_2_1').classList.remove('hidden');
                        window.code_email_Register = data.Code
                        handleSendCodeClick()
                    }else {
                        section_Part_Re_w_1(1);
                        toast(data.message);
                    }


                })
                .catch((error) => {
                    toast('An error has occurred');
                    section_Part_Re_w_1(1);
                });


        } else {
            toast('The email address is not valid ');
        }

    };

    const Section_Part_3 = () => {

        const Code_Emial            = document.getElementById('Code_Emial').value;


        if (Code_Emial !== ''){
            if (Code_Emial ===  window.code_email_Register){

                document.getElementById('Section_2').classList.add('hidden');
                document.getElementById('Section_3').classList.remove('hidden');


            }else {
                toast('The code section must not be empty');
            }
        }else {
            toast('The code section must not be empty');
        }


    }


    const Section_Part_4 = () => {


        section_Part_Re_w_2(2);

        var country                 = document.getElementById('country').value;
        var account_type            = document.querySelector('input[name="account_type"]:checked').value;
        var email                   = document.getElementById('Email').value;


        var password                   = document.getElementById('password').value;
        var Repeat_password            = document.getElementById('Repeat_password').value;

        if (password !== '' && Repeat_password !== ''){
            if (password === Repeat_password ){




                var data = {
                    country:                 country,
                    account_type:            account_type,
                    email:                   email,
                    password:                password,
                };

                var actionURL = `${window.Web_Url_Api}/v1/Registration/Register.php`;

                fetch(actionURL, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                })
                    .then(response => response.json())
                    .then(data => {

                        if (data.Link !== ''){



                            setCookie("email_cookie", `${email}`, 7);
                            // const username = getCookie("active_meeting");
                            // console.log(username);

                            window.location.href = data.Link ;
                        }
                        toast(data.message);


                    })
                    .catch((error) => {
                        toast('An error has occurred');
                        section_Part_Re_w_1(1);
                    });


            }else {
                toast('Choose an equal password');
            }
        }else {
            toast('The password must not be empty');
        }

    }

    useEffect(() => {
        let countdownInterval;
        if (isCounting) {
            countdownInterval = setInterval(() => {
                setCountdownTime(prevTime => {
                    if (prevTime <= 1) {
                        clearInterval(countdownInterval);
                        setIsCounting(false);
                        return 0;
                    }
                    return prevTime - 1;
                });
            }, 1000);
        }
        return () => clearInterval(countdownInterval);
    }, [isCounting]);

    const handleSendCodeClick = () => {


        setCountdownTime(180);
        setIsCounting(true);


    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
    };

    const toggleBackground = (selected) => {
        setSelectedOption(selected);
    };

    return (
        <>
            <ToastContainer
                className="custom-toast-P1"
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme='dark'
            />

            {/* Section 1 */}
            <div id="Section_1" className="bg-gray-900 h-screen flex flex-col justify-between">
                <div className="flex-grow flex md:items-center justify-center h-full">
                    <div className="md:bg-gray-800 md:shadow-lg md:rounded-lg md:p-8 p-4 w-full max-w-md">
                        <div className="flex md:justify-center mb-4 ">
                            <img src="https://emorad.com/Image/Logo/White-logo.png" alt="Logo" className="w-36 hidden md:block h-12" />
                        </div>
                        <h2 className="text-white sm:text-center text-left text-xl mb-4">Register at Emorad Bank</h2>

                        <form>
                            <div className="mb-4">
                                <label htmlFor="entity-name" className="block text-gray-400 ">Your country of residence</label>
                                <select name="country" className="text-white w-full p-3 bg-gray-700 rounded-lg focus:outline-none focus:ring-1 focus:ring-yellow-400 mt-1" id="country">
                                    <option value="Afghanistan">Afghanistan</option>
                                    <option value="Iran">Iran</option>

                                </select>
                            </div>

                            <label htmlFor="entity-name" className="block text-gray-400 mb-2">Account type</label>

                            <div className="flex space-x-4 w-full">
                                <label
                                    className={`w-1/2 flex items-center ${selectedOption === 'Personal' ? 'bg-purple-800 text-white' : 'bg-gray-800 text-gray-400'} border rounded-lg py-2 px-4 cursor-pointer`}
                                    onClick={() => toggleBackground('Personal')}
                                >
                                    <input
                                        id="account_type_personal"
                                        type="radio"
                                        name="account_type"
                                        className="form-radio hidden"
                                        value="Personal"
                                        checked={selectedOption === 'Personal'}
                                        readOnly
                                    />
                                    <span className="ml-2">Personal Account</span>
                                </label>

                                <label
                                    className={`w-1/2 flex items-center ${selectedOption === 'Business' ? 'bg-purple-800 text-white' : 'bg-gray-800 text-gray-400'} border rounded-lg py-2 px-4 cursor-pointer`}
                                    onClick={() => toggleBackground('Business')}
                                >
                                    <input
                                        id="account_type_business"
                                        type="radio"
                                        name="account_type"
                                        className="form-radio hidden"
                                        value="Business"
                                        checked={selectedOption === 'Business'}
                                        readOnly
                                    />
                                    <span className="ml-2">Business Account</span>
                                </label>
                            </div>
                        </form>




                        <div className="hidden md:block mt-6">
                            <button
                                className="w-full bg-yellow-500 text-black p-3 rounded-lg mb-4"
                                onClick={Section_Part_1}
                            >
                                Next
                            </button>
                            <div className="text-center text-sm text-gray-500">
                                Don't have an account? <Link to="/login" className="text-yellow-500">Sign up</Link>
                            </div>
                        </div>


                    </div>
                </div>

                <div className="block md:hidden flex flex-col items-center pb-12">
                    <button   onClick={Section_Part_1} className="w-4/5 bg-yellow-500 text-black p-3 rounded-lg mb-2">
                        Login to the account
                    </button>
                    <Link to="/login" className="text-yellow-500">Sign up</Link>
                </div>

            </div>

            {/* Section 2 */}
            <div id="Section_2" className="flex bg-gray-900 h-screen  flex-col justify-between hidden">
                <div className="flex-grow flex md:items-center justify-center h-full">
                    <div className="md:bg-gray-800 md:shadow-lg md:rounded-lg md:p-8 p-4 w-full max-w-md">
                        <div className="flex md:justify-center mb-4 ">
                            <img src="https://emorad.com/Image/Logo/White-logo.png" alt="Logo" className="w-36 hidden md:block h-12" />
                        </div>
                        <h2 className="text-white sm:text-center text-left text-xl mb-4">Register at Emorad Bank</h2>

                        <form>
                            <div className="mb-4">
                                <label htmlFor="email" className="block text-sm font-medium text-gray-200">Email Address</label>
                                <input
                                    type="email"
                                    id="Email"
                                    name="Email"
                                    autoComplete="email"
                                    placeholder="Enter the email address"
                                    className="text-white w-full p-3 bg-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-400 mt-1"
                                />
                            </div>

                            <div id="Section_2_1" className="relative hidden">
                                <label htmlFor="Code_Emial" className="block text-white text-sm font-medium mb-2">
                                    Verification Code
                                </label>
                                <input
                                    required
                                    id="Code_Emial"
                                    type="text"
                                    className="text-white w-full p-3 bg-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-400 mt-1"
                                    placeholder="Enter code"
                                />

                                <div
                                    id="Send_code_button"
                                    className={`absolute right-0 top-0 mt-[45px] mr-2 flex items-center ${isCounting ? 'hidden' : ''}`}
                                >
                                    <button
                                        onClick={Section_Part_2}
                                        type="button"
                                        id="button_text"
                                        className="text-gray-200 text-sm"
                                    >
                                        Send Code
                                    </button>
                                </div>

                                <div
                                    id="Countdown_timer"
                                    className={`absolute right-0 top-0 mt-[45px] mr-2 flex items-center ${isCounting ? '' : 'hidden'}`}
                                >
                  <span className="text-gray-200 text-sm">
                    {isCounting ? formatTime(countdownTime) : ""}
                  </span>
                                </div>
                            </div>
                        </form>


                        {section_Part_w_1 === 1 && (
                            <div className="hidden md:block mt-4">
                                <div>
                                    <button className="w-full bg-yellow-500 text-black p-3 rounded-lg mb-4" onClick={Section_Part_2}>
                                        Send Email
                                    </button>
                                </div>
                            </div>
                        )}

                        {section_Part_w_1 === 2 && (
                            <div className="hidden md:block mt-4">
                                <button disabled className="mt-6 mb-4 disabled loader_Page_Part_1 w-full bg-yellow-500 text-black py-2 rounded-lg font-semibold flex justify-center items-center not-allowed">
                                    <div></div><div></div><div></div><div></div><div></div><div></div>
                                </button>
                            </div>
                        )}

                        {section_Part_w_1 === 3 && (
                            <div className="hidden md:block mt-4">
                                <div id="Section_2_button_1">
                                    <button className="w-full bg-yellow-500 text-black p-3 rounded-lg mb-4" onClick={Section_Part_3}>
                                        Confirm code
                                    </button>
                                </div>
                            </div>
                        )}





                        <div className="text-center text-sm text-gray-500 hidden md:block mt-4">
                                Don't have an account? <Link to="/login" className="text-yellow-500">Sign up</Link>
                        </div>


                    </div>
                </div>
                <div className="block md:hidden flex flex-col items-center pb-12">
                    {section_Part_w_1 === 1 && (
                        <button onClick={Section_Part_2} className="w-4/5 bg-yellow-500 text-black p-3 rounded-lg mb-2">
                            Send Email
                        </button>
                    )}
                    {section_Part_w_1 === 2 && (
                        <button onClick={Section_Part_2} className="w-4/5 disabled  loader_Page_Part_1 bg-yellow-500 text-black p-2 rounded-lg mb-2  disabled justify-center items-center not-allowed">
                            <div></div><div></div><div></div><div></div><div></div><div></div>
                        </button>

                    )}
                    {section_Part_w_1 === 3 && (
                        <button onClick={Section_Part_3} className="w-4/5 bg-yellow-500 text-black p-3 rounded-lg mb-2">
                            Confirm code
                        </button>

                    )}


                    <Link to="/login" className="text-yellow-500">Sign up</Link>
                </div>
            </div>

            <div id="Section_3" className="bg-gray-900 h-screen flex flex-col justify-between hidden">
                <div className="flex-grow flex md:items-center justify-center h-full">
                    <div className="md:bg-gray-800 md:shadow-lg md:rounded-lg md:p-8 p-4 w-full max-w-md">
                        <h2 className="text-white sm:text-center text-left text-xl mb-4">Choose a Password</h2>
                        <div className="mb-4">
                            <label htmlFor="password" className="block text-sm font-medium text-gray-200">Password</label>
                            <input
                                type="password"
                                id="password"
                                autoComplete="password"
                                placeholder="******"
                                className="text-white w-full p-3 bg-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-400 mt-1"
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="Repeat_password" className="block text-sm font-medium text-gray-200">Repeat password</label>
                            <input
                                type="password"
                                id="Repeat_password"
                                autoComplete="password"
                                placeholder="******"
                                className="text-white w-full p-3 bg-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-400 mt-1"
                            />
                        </div>

                        <div className="hidden md:block mt-4">

                            {section_Part_w_2 === 1 && (
                                <button className="w-full bg-yellow-500 text-black p-3 rounded-lg mb-4" onClick={Section_Part_4}>
                                    Registration
                                </button>
                            )}

                            {section_Part_w_2 === 2 && (
                                <div>
                                    <button disabled className="mt-6 mb-4 disabled loader_Page_Part_1 w-full bg-yellow-500 text-black py-2 rounded-lg font-semibold flex justify-center items-center not-allowed">
                                        <div></div><div></div><div></div><div></div><div></div><div></div>
                                    </button>
                                </div>
                            )}



                            <div className="text-center text-sm text-gray-500">
                                Don't have an account? <Link to="/login" className="text-yellow-500">Sign up</Link>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="block md:hidden flex flex-col items-center pb-12">
                    {section_Part_w_2 === 1 && (
                        <button onClick={Section_Part_4} className="w-4/5 bg-yellow-500 text-black p-3 rounded-lg mb-2">
                            Registration
                        </button>
                    )}

                    {section_Part_w_2 === 2 && (
                        <button onClick={Section_Part_4} className=" disabled loader_Page_Part_1 w-4/5 bg-yellow-500 text-black p-3 rounded-lg mb-2 justify-center items-center not-allowed">
                            <div></div><div></div><div></div><div></div><div></div><div></div>
                        </button>

                    )}

                    <Link to="/login" className="text-yellow-500">Sign up</Link>
                </div>
            </div>
        </>
    );
}

export default Register;
