import React, {useEffect, useState} from 'react';
import {getCookie} from "../../Format/En/Cookie";
import {toast} from "react-toastify";
import LoadingPage from "../Loading-Page";
import {Link} from "react-router-dom";

const Profilesecurity = () => {
    // Connect to run page
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    useEffect(() => {
        if (!isDataLoaded) {
            Load_Pages_Min().then(() => {
                setIsDataLoaded(true);
            });
        }
    }, [isDataLoaded]);
    //  END Connect to run page

    const [profileData, setProfileData] = useState(null);

    async function Load_Pages_Min() {

        const loaderElement = document.getElementById('Loading_Page');
        const pageElement = document.getElementById('Page');

        // loaderElement.classList.add('hidden');

        let meeting = getCookie("meeting");


        try {
            const requestData = {
                Session_ID:            meeting,
            };
            const response = await fetch(`${window.Web_Url_Api}/v1/Profile/security/Information.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData)
            });

            if (!response.ok) {
                toast('Internet connection error');
                return;
            }

            pageElement.classList.remove('hidden');
            loaderElement.classList.add('hidden');


            const data = await response.json();
            setProfileData(data);


        } catch (error) {
            toast('An error has occurred');
        }
    }




    // -----------







    return (
        <div>
            <LoadingPage/>
            <div id="Page" className="hidden p-2">

                <div className="flex items-center space-x-2 text-black dark:text-white ">
                    <Link to="/account/profile" className=" text-2xl">
                        <i className="fa-solid fa-arrow-left"></i>
                    </Link>
                    <h1 className='text-2xl  p-5 rounded-[3px]'>Security </h1>

                </div>


                <div className="bg-gray-100 text-black dark:bg-gray-800 dark:text-white p-6   w-full">
                    <h2 className="text-xl font-semibold mb-4">Two-Factor Authentication (2FA)</h2>

                    <div className="space-y-4">


                        <div className="flex items-center justify-between">

                            <div className="flex items-center space-x-2">

                            <span
                                className="inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-800 text-white">
                                <i className="fa-duotone fa-solid fa-shield-halved"></i>
                            </span>

                                <div>
                                    <p className="font-medium">Authenticator App</p>
                                    <p className="text-sm text-gray-400 hidden sm:hidden md:inline-flex">Use Emorad - Google Authenticator to protect your account and transactions.</p>
                                </div>

                            </div>

                            <div className="flex items-center space-x-2">

                                {profileData?.Authenticator_status === 'ON' ? (
                                    <p className="text-green-500">On</p>
                                ) : (
                                    <p className="text-red-500">Off</p>
                                )}

                                <Link to="/account/profile/Authentication" className="bg-gray-300 dark:bg-gray-700 px-4 py-2 rounded text-sm">Manage</Link>
                            </div>
                        </div>


                        <div className="flex items-center justify-between">

                            <div className="flex items-center space-x-2">

                            <span
                                className="inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-800 text-white">
                               <i className="fa-duotone fa-solid fa-envelope"></i>
                            </span>

                                <div>
                                    <p className="font-medium">Email</p>
                                    <p className="text-sm text-gray-400 hidden sm:hidden md:inline-flex">
                                        Use your email to protect your account. When the word is unknown
                                    </p>
                                </div>

                            </div>

                            <div className="flex items-center space-x-2">
                                {profileData?.Two_step_Email === 'ON' ? (
                                    <p className="text-green-500">On</p>
                                ) : (
                                    <p className="text-red-500">Off</p>
                                )}

                                <Link to="/account/profile/Security-Email"
                                      className="bg-gray-300 dark:bg-gray-700 px-4 py-2 rounded text-sm">Manage</Link>
                            </div>
                        </div>


                    </div>
                </div>


                <div className="bg-gray-100 text-black dark:bg-gray-800 dark:text-white p-6 mt-2 w-full">
                    <h2 className="text-xl font-semibold mb-4"> Security </h2>

                    <div className="space-y-4">


                        <div className="flex items-center justify-between">

                            <div className="flex items-center space-x-2">

                            <span
                                className="inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-800 text-white">
                                <i className="fa-duotone fa-solid fa-shield-halved"></i>
                            </span>

                                <div>
                                    <p className="font-medium">Login Password</p>
                                    <p className="text-sm text-gray-400 hidden sm:hidden md:inline-flex">
                                        Manage your password
                                    </p>
                                </div>

                            </div>

                            <div className="flex items-center space-x-2">
                                <Link to="/account/profile/password" className="bg-gray-300 dark:bg-gray-700 px-4 py-2 rounded text-sm">Manage</Link>
                            </div>
                        </div>


                    </div>
                </div>


            </div>


            <div>
                <br/><br/><br/>
            </div>

        </div>


    );
};

export default Profilesecurity;
