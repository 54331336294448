import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../Format/Library/fontawesome/css/all.css';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import { setCookie} from '../Format/En/Cookie';

function Login() {

    const [section_Part_w_1, setSection_Part_w_1] = useState(1);
    const [section_Part_w_2, setSection_Part_w_2] = useState(1);
    const [section_Part_w_3, setSection_Part_w_3] = useState(1);
    const [section_Part_w_4, setSection_Part_w_4] = useState(1);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const Section_Part_1 = async () => {
        setSection_Part_w_1(2);

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (email !== '' && password !== '') {
            if (emailPattern.test(email)) {
                const data = {
                    email: email,
                    password: password,
                };

                const actionURL = `${window.Web_Url_Api}/v1/Login/login.php`;

                try {
                    const response = await fetch(actionURL, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(data),
                    });
                    const result = await response.json();

                    toast(result.message);

                    if (result.status === 'true') {
                        if (typeof result.Authenticator !== 'undefined') {
                            setSection_Part_w_2(2);
                            window.login_Authenticator_Email = result.Email ;
                        }else{
                            setCookie("email_cookie", `${result.Email}`, 7);
                            window.location.href = result.Link;
                        }

                    } else {
                        setSection_Part_w_1(1);
                    }
                } catch (error) {
                    toast('An error has occurred');
                    setSection_Part_w_1(1);
                }
            } else {
                setSection_Part_w_1(1);
                toast('Invalid email format');
            }
        } else {
            setSection_Part_w_1(1);
            toast('Please enter both email and password');
        }
    };

    const Section_Part_2 = async () => {
        setSection_Part_w_3(2);

        const code_a               = document.getElementById('code').value;


        if (code_a !== '') {


                const data = {
                    email: window.login_Authenticator_Email,
                    code_a: code_a,
                };

                const actionURL = `${window.Web_Url_Api}/v1/Login/Authenticator.php`;

                try {
                    const response = await fetch(actionURL, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(data),
                    });
                    const result = await response.json();

                    toast(result.message);
                    setSection_Part_w_3(1);

                    if (result.status  === 'true'){
                        if (typeof result.Two_step_Email !== 'undefined') {
                            setSection_Part_w_2(3);
                            window.code_email_login = result.Two_step_Email
                            window.Link_email_login =result.Link
                            window.email_login =result.Email
                        }else {
                            setCookie("email_cookie", `${result.Email}`, 7);
                            window.location.href = result.Link;
                        }
                    }



                } catch (error) {
                    toast('An error has occurred');
                    setSection_Part_w_1(1);
                }

        } else {
            setSection_Part_w_1(1);
            toast('Please enter both email and password');
        }
    };

    const Section_Part_3 = async () => {
        setSection_Part_w_4(2);

        const code_m = document.getElementById('code_m').value;


        if (code_m === window.code_email_login) {

            setCookie("email_cookie",   window.email_login, 7);

            toast('Login was successful');
            window.location.href = window.Link_email_login;

        }else {
            toast('The two-step code is not correct');
            setSection_Part_w_4(1);

        }
    }

    return (

        <div>

            {section_Part_w_2 === 1 && (
                <div className="bg-gray-900 h-screen flex flex-col justify-between">
                    <div className="flex-grow flex md:items-center justify-center h-full">
                        <div className="md:bg-gray-800 md:shadow-lg md:rounded-lg md:p-8 p-4 w-full max-w-md">
                            <div className="flex md:justify-center mb-4">
                                <img src="https://emorad.com/Image/Logo/White-logo.png" alt="Logo"
                                     className="w-36 hidden md:block h-12"/>
                            </div>

                            <h2 className="text-white sm:text-center text-left text-xl mb-4">Login to Emorad Bank</h2>

                            <form>
                                <div className="mb-4">
                                    <label htmlFor="Email" className="block text-sm font-medium text-gray-200">Email
                                        Address</label>
                                    <input
                                        type="email"
                                        id="Email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        autoComplete="email"
                                        placeholder="Enter the email address"
                                        className="text-white w-full p-3 bg-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-400 mt-1"
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="password"
                                           className="block text-sm font-medium text-gray-200">Password</label>
                                    <input
                                        type="password"
                                        id="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        autoComplete="current-password"
                                        placeholder="Enter the password"
                                        className="text-white w-full p-3 bg-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-400 mt-1"
                                    />
                                </div>
                            </form>
                            <div className="text-left mb-4">
                                <Link to='/login' className="text-sm text-gray-300">Forgot password</Link>
                            </div>
                            <div className="hidden md:block mt-2">
                                {section_Part_w_1 === 1 && (
                                    <button className="w-full bg-yellow-500 text-black p-3 rounded-lg mb-4"
                                            onClick={Section_Part_1}>
                                        Login
                                    </button>
                                )}
                                {section_Part_w_1 === 2 && (
                                    <div>
                                        <button disabled
                                                className="mt-6 mb-4 disabled loader_Page_Part_1 w-full bg-yellow-500 text-black py-2 rounded-lg font-semibold flex justify-center items-center not-allowed">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </button>
                                    </div>
                                )}
                                <div className="text-center text-sm text-gray-500">
                                    Don't have an account? <Link to="/Register" className="text-yellow-500">Sign
                                    up</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" md:hidden flex  flex-col items-center pb-12">
                        {section_Part_w_1 === 1 && (
                            <button onClick={Section_Part_1}
                                    className="w-4/5 bg-yellow-500 text-black p-3 rounded-lg mb-2">
                                Login
                            </button>
                        )}

                        {section_Part_w_1 === 2 && (
                            <button onClick={Section_Part_1}
                                    className="w-4/5 disabled loader_Page_Part_1 bg-yellow-500 text-black p-2 rounded-lg mb-2 justify-center items-center not-allowed">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </button>
                        )}

                        <Link to="/Register" className="text-yellow-500">Sign up</Link>
                    </div>
                    <ToastContainer
                        className="custom-toast-P1"
                        position="bottom-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme='dark'
                    />

                </div>
            )}

            {section_Part_w_2 === 2 && (
                <div className="bg-gray-900 h-screen flex flex-col justify-between">
                    <div className="flex-grow flex md:items-center justify-center h-full">
                        <div className="md:bg-gray-800 md:shadow-lg md:rounded-lg md:p-8 p-4 w-full max-w-md">
                            <div className="flex md:justify-center mb-4">
                                <img src="https://emorad.com/Image/Logo/White-logo.png" alt="Logo"
                                     className="w-36 hidden md:block h-12"/>
                            </div>

                            <h2 className="text-white sm:text-center text-left text-xl mb-4">Account Authenticator</h2>

                            <form>
                                <div className="mb-4">
                                    <label htmlFor="Email" className="block text-sm font-medium text-gray-200">
                                        Two-step code
                                    </label>
                                    <input
                                        type="text"
                                        id="code"
                                        placeholder="*** ***"
                                        className="text-white w-full p-3 bg-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-400 mt-1"
                                    />
                                </div>

                            </form>
                            <div className="text-left mb-4">
                                <Link to="/login" className="text-sm text-gray-300">Forget Authenticator</Link>
                            </div>
                            <div className="hidden md:block mt-2">
                                {section_Part_w_3 === 1 && (
                                    <button className="w-full bg-yellow-500 text-black p-3 rounded-lg mb-4"
                                            onClick={Section_Part_2}>
                                        Login
                                    </button>
                                )}
                                {section_Part_w_3 === 2 && (
                                    <div>
                                        <button disabled
                                                className="mt-6 mb-4 disabled loader_Page_Part_1 w-full bg-yellow-500 text-black py-2 rounded-lg font-semibold flex justify-center items-center not-allowed">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </button>
                                    </div>
                                )}
                                <div className="text-center text-sm text-gray-500">
                                    Don't have an account? <Link to="/Register" className="text-yellow-500">Sign
                                    up</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" md:hidden flex flex-col items-center pb-12">
                        {section_Part_w_3 === 1 && (
                            <button onClick={Section_Part_2}
                                    className="w-4/5 bg-yellow-500 text-black p-3 rounded-lg mb-2">
                                Login
                            </button>
                        )}

                        {section_Part_w_3 === 2 && (
                            <button
                                    className="w-4/5 disabled loader_Page_Part_1 bg-yellow-500 text-black p-2 rounded-lg mb-2 justify-center items-center not-allowed">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </button>
                        )}

                        <Link to="/Register" className="text-yellow-500">Sign up</Link>
                    </div>
                    <ToastContainer
                        className="custom-toast-P1"
                        position="bottom-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme='dark'
                    />

                </div>
            )}


            {section_Part_w_2 === 3 && (
                <div className="bg-gray-900 h-screen flex flex-col justify-between">
                    <div className="flex-grow flex md:items-center justify-center h-full">
                        <div className="md:bg-gray-800 md:shadow-lg md:rounded-lg md:p-8 p-4 w-full max-w-md">
                            <div className="flex md:justify-center mb-4">
                                <img src="https://emorad.com/Image/Logo/White-logo.png" alt="Logo"
                                     className="w-36 hidden md:block h-12"/>
                            </div>

                            <h2 className="text-white sm:text-center text-left text-xl mb-4">Two-step Email</h2>

                            <form>
                                <div className="mb-4">
                                    <label htmlFor="code_m" className="block text-sm font-medium text-gray-200">
                                        Enter the code sent
                                    </label>
                                    <input
                                        type="text"
                                        id="code_m"
                                        placeholder="******"
                                        className="text-white w-full p-3 bg-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-400 mt-1"
                                    />
                                </div>

                            </form>

                            <div className="hidden md:block mt-2">
                                {section_Part_w_4 === 1 && (
                                    <button className="w-full bg-yellow-500 text-black p-3 rounded-lg mb-4"
                                            onClick={Section_Part_3}>
                                        Login
                                    </button>
                                )}
                                {section_Part_w_4 === 2 && (
                                    <div>
                                        <button disabled
                                                className="mt-6 mb-4 disabled loader_Page_Part_1 w-full bg-yellow-500 text-black py-2 rounded-lg font-semibold flex justify-center items-center not-allowed">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </button>
                                    </div>
                                )}
                                <div className="text-center text-sm text-gray-500">
                                    Don't have an account? <Link to="/Register" className="text-yellow-500">Sign
                                    up</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" md:hidden flex flex-col items-center pb-12">
                        {section_Part_w_4 === 1 && (
                            <button onClick={Section_Part_3}
                                    className="w-4/5 bg-yellow-500 text-black p-3 rounded-lg mb-2">
                                Login
                            </button>
                        )}

                        {section_Part_w_4 === 2 && (
                            <button
                                className="w-4/5 disabled loader_Page_Part_1 bg-yellow-500 text-black p-2 rounded-lg mb-2 justify-center items-center not-allowed">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </button>
                        )}

                        <Link to="/Register" className="text-yellow-500">Sign up</Link>
                    </div>
                    <ToastContainer
                        className="custom-toast-P1"
                        position="bottom-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme='dark'
                    />

                </div>
            )}

        </div>

    );
}

export default Login;
