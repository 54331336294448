import { getCookie, deleteCookie } from "../Format/En/Cookie";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Sign_out_account = () => {
    console.log('123');
    let email_cookie = getCookie("email_cookie");
    let meeting = getCookie("meeting");

    var data = {
        meeting: meeting,
        email_cookie: email_cookie,
    };
    var actionURL = `${window.Web_Url_Api}/v1/License/exit.php`;
    fetch(actionURL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
        .then(response => response.json())
        .then(data => {
            toast(data.message);
            if (data.status === "true") {

                deleteCookie("email_cookie");
                deleteCookie("meeting");
                localStorage.removeItem("Allowed_request");
                window.location.href = "/register/";
            }
        })
        .catch((error) => {
            toast.error('An error occurred!');
        });
}
