import {Link, useParams} from 'react-router-dom';
import React, {useState} from "react";

function Withdraw() {
    const {symbol } = useParams();
    const symbolS = symbol || 'USD';


    const [inputValue, setInputValue] = useState("");

    const handleInputChange = (e) => {
        const rawValue = e.target.value.replace(/[^0-9]/g, ""); // فقط اعداد
        const limitedValue = rawValue.slice(0, 12); // محدود به ۱۲ رقم
        const formattedValue = limitedValue
            .match(/.{1,4}/g) // گروه‌بندی هر ۴ کاراکتر
            ?.join("-") || "";
        setInputValue(formattedValue);
    };


    const [section_Part_w_1, section_Part_Re_w_1] = useState(1);
    async function Section_Part_1() {
        section_Part_Re_w_1(2);

    }



    return (
        <div className="p-3">


            <div className="flex items-center space-x-2 text-black dark:text-white ">
                <Link to="/account/wallet/index" className=" text-2xl">
                    <i className="fa-solid fa-arrow-left"></i>
                </Link>
                <h1 className='text-2xl  p-5 rounded-[3px]'>Send Money </h1>
            </div>

            <div
                className="mx-auto p-6 bg-white dark:bg-gray-800 dark:text-white border border-gray-200 rounded-lg shadow-md flex flex-col md:flex-row gap-6">




                {section_Part_w_1 === 1 && (

                    <div className="w-full md:w-2/3 flex flex-col items-center">


                        <div className="flex items-center space-x-4">

                            <div className="flex-shrink-0">
                                <div className=" rounded-full flex items-center justify-center">
                                    <img
                                        src="https://icons.veryicon.com/png/o/miscellaneous/ionicons/logo-usd-1.png"
                                        alt="USD Logo"
                                        className="w-16 h-16 md:w-20 md:h-20 mb-2"
                                    />
                                </div>
                            </div>


                            <div className="flex items-center space-x-2">
                                <h1 className="text-2xl font-bold">USD</h1>
                            </div>
                        </div>



                        <div className="mb-4 w-full">
                            <label className="block text-sm font-medium mb-1">Account</label>
                            <input
                                placeholder="****-****-****"
                                type="text"
                                className="text-3xl w-full p-2 border border-gray-300 rounded-md bg-gray-100 text-black"
                                value={inputValue}
                                onChange={handleInputChange}
                            />
                        </div>

                        <button onClick={Section_Part_1}
                                className="w-full bg-green-600 text-white py-3 rounded-md hover:bg-green-700">
                            Next
                        </button>
                    </div>
                )}

                {section_Part_w_1 === 2 && (

                    <div className="w-full md:w-2/3 flex flex-col items-center">

                        <div
                            className="w-full  bg-white p-1  flex flex-col items-center">
                            <img
                                src="https://cdn.pixabay.com/photo/2020/07/01/12/58/icon-5359553_640.png"
                                alt="Profile"
                                className="w-20 h-20 rounded-full mb-4"
                            />
                            <h2 className="text-lg font-semibold"> Alireza Daneshmandi </h2>
                            <p className="text-sm text-gray-500">6037 6915 2750 3345</p>
                        </div>


                        <div className="w-full bg-white p-1 mt-4 max-w-md">
                            <label htmlFor="amount" className="block text-sm font-medium text-gray-700 mb-2">
                                Transfer amount
                            </label>
                            <input
                                id="amount"
                                type="text"
                                placeholder="Enter the amount"
                                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                onInput={(e) => {
                                    const value = e.target.value;

                                    if (!/^\d*\.?\d{0,2}$/.test(value)) {
                                        e.target.value = value.slice(0, -1);
                                    }
                                }}
                            />

                            <button className="mt-4 w-full bg-blue-600 text-white py-3 rounded-md hover:bg-blue-700">
                                Next
                            </button>
                        </div>

                    </div>

                )}


                {/* Right Section */}
                <div className="w-full md:w-1/3 bg-gray-50 p-4 rounded-lg border border-gray-200">
                    <h3 className="text-lg font-semibold mb-2">Transfer to PAYEER Wallet</h3>
                    <div className="text-sm text-gray-700 mb-2">
                        <p>0.02 $ / €</p>
                        <p>Min. per transaction</p>
                    </div>
                    <div className="text-sm text-gray-700">
                        <p>Instantly</p>
                        <p>Transfer term</p>
                    </div>
                </div>
            </div>
            <div>
                <br/><br/><br/><br/><br/>
                <br/><br/><br/><br/><br/>
                <br/><br/><br/><br/><br/>
            </div>


        </div>
    );
}

export default Withdraw;
