import React, { useEffect, useState } from 'react';
import { getCookie } from "../../Format/En/Cookie";
import { toast } from "react-toastify";
import LoadingPage from "../Loading-Page";
import { Link } from "react-router-dom";

const ProfileNotification = () => {
    // Connect to run page
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    useEffect(() => {
        if (!isDataLoaded) {
            Load_Pages_Min().then(() => {
                setIsDataLoaded(true);
            });
        }
    }, [isDataLoaded]);
    //  END Connect to run page
    const [profileData, setProfileData] = useState([]);
    async function Load_Pages_Min() {
        const loaderElement = document.getElementById('Loading_Page');
        const pageElement = document.getElementById('Page');

        let meeting = getCookie("meeting");

        try {
            const requestData = {
                Session_ID: meeting,
            };
            const response = await fetch(`${window.Web_Url_Api}/v1/Profile/Notification/Information.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            });

            if (!response.ok) {
                toast('Internet connection error');
                return;
            }

            const data = await response.json();
            // بررسی می‌کنیم که آیا داده یک آرایه است
            if (Array.isArray(data)) {
                setProfileData(data);
            } else {
                console.error("The response is not an array:", data);
                toast('Received invalid data format');
            }

            pageElement.classList.remove('hidden');
            loaderElement.classList.add('hidden');

        } catch (error) {
            toast('An error has occurred');
        }
    }

    return (
        <div>
            <LoadingPage />
            <div id="Page" className="hidden p-2">
                <div className="flex items-center space-x-2 text-black dark:text-white ">
                    <Link to="/account/profile/" className="text-2xl">
                        <i className="fa-solid fa-arrow-left"></i>
                    </Link>
                    <h1 className='text-2xl p-5 rounded-[3px]'>Notification </h1>
                </div>

                {profileData
                    .filter(notification => notification.condition === "true")
                    .map((notification, index) => (
                        <div key={index} className="p-4 bg-gray-100 text-black dark:bg-gray-800 dark:text-white rounded-lg shadow-md mt-4 relative">
                            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
                                <div className="flex items-start flex-1">
                                    <i className="fa-solid fa-envelope text-2xl text-blue-400 mr-3 mt-1"></i>
                                    <div className="flex-1">
                                        <span className="font-semibold text-lg block whitespace-nowrap overflow-hidden overflow-ellipsis">
                                            {notification.type}
                                        </span>
                                        <span className="text-sm text-gray-400 sm:max-w-2xl max-w-[220px] block mt-1 truncate">
                                            {`Email: ${notification.Email}`}
                                        </span>
                                    </div>
                                </div>
                                <div className="mt-3 sm:mt-0 flex items-center space-x-2 sm:space-x-4">
                                    <span className={`w-3 h-3 ${notification.condition === 'true' ? 'bg-green-500' : 'bg-red-500'} rounded-full sm:static absolute top-[23px] right-2 sm:top-auto sm:right-auto`}></span>
                                    <span className="text-gray-400 text-sm">
                                        {new Date(parseInt(notification.Time_Code) * 1000).toLocaleString()}
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>

            <div>
                <br /><br /><br />
            </div>
        </div>
    );
};

export default ProfileNotification;
