import React from 'react';
import {Link} from "react-router-dom";

function Application() {
    return (
        <div>
            <h1>Application Page</h1>
            <p>This is the starting page for our installed application.</p>
            <div>
                <h1>Welcome to the Home Page</h1>
                <Link to="/login">Login</Link>
                <br/>
                <Link to="/register">Register</Link>
                <br/>
                <Link to="/Application">Application</Link>
            </div>
        </div>
    );
}

export default Application;
