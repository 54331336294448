import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import {getCookie} from "../../Format/En/Cookie";
import LoadingPage from "../Loading-Page";
import {QRCodeSVG} from "qrcode.react";

function Walletdeposit() {

    const [items, setItems] = useState([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [is_model_1, Modal_Full_1] = useState(false);
    const [selectedToken, setSelectedToken] = useState(null);
    const [walletData, setwalletData] = useState(null);
    const [isChecked, setIsChecked] = useState(false);

    const [section_Part_w_1, section_Part_Re_w_1] = useState(1);
    const [section_Part_w_2, section_Part_Re_w_2] = useState(1);
    const [section_Part_w_3, section_Part_Re_w_3] = useState(1);
    const [section_Part_w_4, section_Part_Re_w_4] = useState(1);

    useEffect(() => {
        if (!isDataLoaded) {
            Load_Pages_Min().then(() => {
                setIsDataLoaded(true);
            });
        }
    }, [isDataLoaded]);

    async function Load_Pages_Min() {
        const urlParams = new URLSearchParams(window.location.search);
        const newParam = urlParams.get('symbol');
        const Network = urlParams.get('Network');
        if (!newParam) {
            const newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?symbol=BTC&Network=Bitcoin';
            window.history.pushState({ path: newUrl }, '', newUrl);
            window.Section_Part_4           = "BTC";
            window.Section_Part_4_Network   = "Bitcoin";
        }else {

            window.Section_Part_4            = newParam;
            window.Section_Part_4_Network    = Network;
        }

        Section_Part_3()
        let meeting = getCookie("meeting");
        const loaderElement = document.getElementById('Loading_Page');
        const pageElement = document.getElementById('Page');
        try {
            const requestData = {
                Session_ID: meeting,
            };
            const response = await fetch(`${window.Web_Url_Api}/v1/wallet/deposit/Information.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData)
            });
            if (!response.ok) {
                toast('Internet connection error');
                return;
            }

            const data = await response.json();

            if (data.status === "true" && Array.isArray(data.data)) {
                setItems(data.data);

                const tokenToShow = data.data.find(item => item.symbol.toLowerCase() === window.Section_Part_4.toLowerCase()); // نماد مورد نظر را به جای 'btc' قرار دهید
                if (tokenToShow) {
                    setSelectedToken(tokenToShow);
                }

            }
            pageElement.classList.remove('hidden');
            loaderElement.classList.add('hidden');
        } catch (error) {
            toast('An error has occurred');
        }

        // window.Section_Part_4 = "1";
        Section_Part_4()
    }

    const filteredItems = items.filter(
        item =>
            item.symbol.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const Section_Part_1 = (token) => {
        Modal_Full_1(!is_model_1);
        setSelectedToken(token);
        Modal_Full_1(false);
        section_Part_Re_w_1(1);
        window.Section_Part_4 = token.symbol;
        Section_Part_4()
        let meeting = getCookie("meeting");
        const data = {
            Session_ID:            meeting,
            token_symbol:          token.symbol,
            token_Network:         token.Network,

        };

        const actionURL = `${window.Web_Url_Api}/v1/wallet/deposit/Check-wallet.php`;

        fetch(actionURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(data => {
                setwalletData(data);


                if (data.Structure === "Wallet") {
                    section_Part_Re_w_1(2);
                    section_Part_Re_w_2(3);
                }else{

                    window.New_TokenId = data.TokenId
                    section_Part_Re_w_1(2);
                    section_Part_Re_w_2(1);
                }
                const newUrls = window.location.protocol + "//" + window.location.host + window.location.pathname + `?symbol=${data.symbol}`;
                window.history.pushState({ path: newUrls }, '', newUrls);

            })
            .catch(() => {
                toast('An error has occurred');
            });






    };

    const Section_Part_2 = () => {


        let meeting = getCookie("meeting");
        const data = {
            Session_ID:             meeting,
            New_TokenId:            window.New_TokenId,


        };

        const actionURL = `${window.Web_Url_Api}/v1/wallet/deposit/Create-wallet.php`;

        fetch(actionURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(data => {
                setwalletData(data);
                section_Part_Re_w_2(3);
                toast(data.message);
            })
            .catch(() => {
                toast('An error has occurred');
            });



    };

    const Target_model_1 = () => {
        Modal_Full_1(!is_model_1);
    };
    const [is_model_2, Modal_Full_2] = useState(false);

    const Target_model_2 = () => {
        Modal_Full_2(!is_model_2);
    };
    // ------------------
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };
    // ------------------
    const Section_Part_3 = () => {
        let meeting = getCookie("meeting");
        const data = {
            Session_ID:            meeting,
            token_symbol:          window.Section_Part_4,

        };
        const actionURL = `${window.Web_Url_Api}/v1/wallet/deposit/Check-wallet.php`;
        fetch(actionURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(data => {
                setwalletData(data);
                if (data.Structure === "Wallet") {
                    section_Part_Re_w_1(2);
                    section_Part_Re_w_2(3);
                }else{
                    window.New_TokenId = data.TokenId
                    section_Part_Re_w_1(2);
                    section_Part_Re_w_2(1);
                }

                if (data.NoToken === "on") {
                    const newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?symbol=BTC';
                    window.history.pushState({ path: newUrl }, '', newUrl);
                    window.Section_Part_4 = "BTC"
                }




            })
            .catch(() => {
                toast('An error has occurred');
            });

    };
    // ------------------
    function Copy_wallet_address() {
        const text =  walletData ? walletData.address : 'Loading...'
        navigator.clipboard.writeText(text)
            .then(() => toast('The wallet was copied'))
    }


    // ------------------
    const [token_depositData, set_token_depositData] = useState([]);

    async function Section_Part_4() {
        section_Part_Re_w_3(2);

        let meeting = getCookie("meeting");
        try {
            const requestData = {
                Session_ID:      meeting,
                symbol_token:    window.Section_Part_4,
            };
            const response = await fetch(`${window.Web_Url_Api}/v1/wallet/deposit/History.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            });

            if (!response.ok) {
                toast('Internet connection error');
                return;
            }

            const data = await response.json();

            if (Array.isArray(data)) {
                set_token_depositData(data);
            }
            section_Part_Re_w_3(1);
            // pageElement.classList.remove('hidden');
            // loaderElement.classList.add('hidden');


        } catch (error) {
            toast('An error has occurred');
        }

    }
    // ------------------
    const [is_model_3, Modal_Full_3] = useState(false);
    const [historyselection, sethistoryselection] = useState(null);
    // ------------------
    const Target_model_3 = () => {
        Modal_Full_3(!is_model_3);
    };

    async function Section_Part_5(id_History) {
        Modal_Full_3(!is_model_3);
        section_Part_Re_w_4(1);

        let meeting = getCookie("meeting");

        try {
            const requestData = {
                Session_ID:            meeting,
                id_History:            id_History,
            };
            const response = await fetch(`${window.Web_Url_Api}/v1/wallet/deposit/History-selection.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData)
            });

            if (!response.ok) {
                toast('Internet connection error');
                return;
            }


            const data = await response.json();
            sethistoryselection(data);
            section_Part_Re_w_4(2);

        } catch (error) {
            toast('An error has occurred');
        }


    }

    function Copy_Transaction() {
        const text =  historyselection ? historyselection.Transaction : 'Loading...'
        navigator.clipboard.writeText(text)
            .then(() => toast('The transaction ID was copied'))
    }

    function Copy_wallet_Received() {
        const text =  historyselection ? historyselection.wallet_send : 'Loading...'
        navigator.clipboard.writeText(text)
            .then(() => toast('The wallet was copied'))
    }



    return (
        <div className="p-3">
            <LoadingPage/>

            <div id="Page" className='hidden'>
                <div className="flex items-center space-x-2 text-black dark:text-white ">
                    <Link to="/account/wallet/index" className=" text-2xl">
                        <i className="fa-solid fa-arrow-left"></i>
                    </Link>
                    <h1 className='text-2xl  p-5 rounded-[3px]'>Deposit </h1>
                </div>


                <div
                    className="bg-white dark:bg-gray-800 dark:text-white rounded-lg p-4 w-full mx-auto mt-2 grid grid-cols-1 md:grid-cols-3 gap-4">

                    <div className="md:col-span-2">

                        <div>

                            <div className="mb-6">
                                <h2 className="text-lg font-semibold mb-2">Select Coin/Token</h2>
                                <button onClick={Target_model_1} className="w-full">
                                    {selectedToken ? (
                                        <div className="flex items-center bg-gray-100 dark:bg-gray-700 p-3 rounded-lg">
                                            <img src={selectedToken.icon} alt={selectedToken.symbol}
                                                 className="mr-2 h-8 w-8"/>
                                            <span className="text-md font-medium">{selectedToken.symbol}</span>
                                            <span className="ml-2 text-gray-400">{selectedToken.name}</span>
                                        </div>
                                    ) : (
                                        <div className="flex items-center bg-gray-100 dark:bg-gray-700 p-3 rounded-lg">
                                            <img src="https://cryptologos.cc/logos/bitcoin-btc-logo.png" alt="BTC"
                                                 className="mr-2 h-8 w-8"/>
                                            <span className="text-black dark:text-white text-md font-medium">BTC</span>
                                            <span className="ml-2 text-gray-400">Bitcoin</span>
                                        </div>
                                    )}
                                </button>
                            </div>


                            {is_model_1 && (
                                <div>
                                    <div
                                        className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
                                        onClick={Target_model_1}>
                                        <div className="relative w-full max-w-lg dark:bg-gray-800 bg-white p-6
                      md:max-w-4xl md:m-auto
                      md:p-3 md:rounded-lg md:shadow-lg
                      md:relative
                      h-full md:h-auto

                      "
                                             onClick={(e) => e.stopPropagation()}
                                        >

                                            <div className="flex justify-between items-center mb-4"><h2
                                                className="text-xl font-semibold">Choose a Coin/Token</h2>
                                                <button onClick={Target_model_1}
                                                        className="text-xl dark:text-gray-400 text-gray-400 dark:hover:text-white hover:text-gray-900">
                                                    <i className="fa-solid fa-xmark"></i></button>
                                            </div>
                                            <div className="w-full ">

                                                <div className="relative mb-4">
                                                    <input
                                                        type="text"
                                                        placeholder="Search by coin/token"
                                                        className="w-full px-4  bg-gray-200 py-2 dark:bg-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 mb-4"
                                                        value={searchTerm}
                                                        onChange={(e) => setSearchTerm(e.target.value)}
                                                    />
                                                    <button className="absolute right-2 top-2">

                                                    </button>
                                                </div>


                                                {searchTerm === "" && (
                                                    <div className="hidden">
                                                        <div className="mb-4">
                                                            <h2 className="text-gray-500 text-sm mb-2">History</h2>
                                                            <div className="flex flex-wrap gap-2">
                                                                <span
                                                                    className="bg-gray-100 text-gray-700 px-3 py-1 rounded-full text-sm">AURORA</span>
                                                                <span
                                                                    className="bg-gray-100 text-gray-700 px-3 py-1 rounded-full text-sm">USDT</span>
                                                                <span
                                                                    className="bg-gray-100 text-gray-700 px-3 py-1 rounded-full text-sm">TRX</span>
                                                                <span
                                                                    className="bg-gray-100 text-gray-700 px-3 py-1 rounded-full text-sm">BTC</span>
                                                            </div>
                                                        </div>
                                                        <div className="mb-4">
                                                            <h2 className="text-gray-500 text-sm mb-2 flex items-center">
                                                                Popular Coins
                                                                <span className="ml-1 text-yellow-500">🔥</span>
                                                            </h2>
                                                            <div className="flex flex-wrap gap-2">
                                                                <span
                                                                    className="bg-gray-100 text-gray-700 px-3 py-1 rounded-full text-sm">USDT</span>
                                                                <span
                                                                    className="bg-gray-100 text-gray-700 px-3 py-1 rounded-full text-sm">BELLSCOIN</span>
                                                                <span
                                                                    className="bg-gray-100 text-gray-700 px-3 py-1 rounded-full text-sm">FB</span>
                                                                <span
                                                                    className="bg-gray-100 text-gray-700 px-3 py-1 rounded-full text-sm">KAS</span>
                                                                <span
                                                                    className="bg-gray-100 text-gray-700 px-3 py-1 rounded-full text-sm">TRX</span>
                                                                <span
                                                                    className="bg-gray-100 text-gray-700 px-3 py-1 rounded-full text-sm">XNA</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}


                                                <div>
                                                    <h2 className="text-gray-500 dark:text-gray-300 text-sm mb-2">Crypto
                                                        List</h2>

                                                    {filteredItems.length > 0 ? (
                                                        <div
                                                            className="w-full bg-white dark:bg-gray-800 dark:text-gray-100">
                                                            {filteredItems.map((item, index) => (
                                                                <div onClick={() => Section_Part_1(item)} key={index}
                                                                     className="cursor-pointer  flex items-center p-2  hover:bg-gray-200 dark:hover:bg-gray-700 rounded-md">
                                                                    <div className="flex items-center space-x-2">
                                                                        <img src={item.icon} alt="icon"
                                                                             className="w-8 h-8  rounded-full"/>
                                                                        <span
                                                                            className="font-bold truncate min-w-16 md:min-w-36">{item.symbol}</span>
                                                                    </div>
                                                                    <span
                                                                        className="ml-4 text-gray-500 dark:text-gray-300 truncate max-w-xs">{item.name} - {item.Network}</span>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ) : (
                                                        <div className="text-center text-gray-500 p-4">
                                                            دیتا ای نیست
                                                        </div>
                                                    )}
                                                </div>


                                            </div>

                                        </div>
                                    </div>


                                </div>
                            )}
                        </div>

                        {section_Part_w_1 === 1 && (
                            <div className="h-60 items-center justify-center   py-20 rounded   ">
                                <div className="flex items-center justify-center  centered">
                                    <div className="loader_Page flex ">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {section_Part_w_1 === 2 && (
                            <div>
                                <div className="mb-6">
                                    <h2 className="text-lg font-semibold mb-2">Generate Deposit Address</h2>
                                    <p className="text-sm text-gray-600 dark:text-gray-400 mb-2">Select Network</p>
                                    <div
                                        className="w-full md:w-1/4 bg-gray-100 dark:text-white dark:bg-gray-600 border border-y-amber-200  dark:border-y-amber-200 text-green-600 px-4 py-2 rounded-lg  text-left">
                                        {walletData ? walletData.Network : 'Loading...'}
                                    </div>
                                </div>

                                {section_Part_w_2 === 1 && (
                                    <div>
                                        <div
                                            className="bg-red-50 border-l-4 border-red-500 dark:bg-gray-700 p-4 mb-6 rounded-lg">
                                            <h3 className="text-red-600 font-semibold mb-2">
                                                TRX-TRON (TRC20) Risk Reminder</h3>
                                            <p className="text-sm text-gray-700 mb-4 dark:text-gray-300">
                                                {walletData ? walletData.License_text : 'Loading...'}
                                            </p>
                                            <div className="flex items-center">


                                                <div className="flex">
                                                    <div className="flex items-center h-5">
                                                        <input
                                                            id="checkbox_w"
                                                            type="checkbox"
                                                            checked={isChecked}
                                                            onChange={handleCheckboxChange}
                                                            aria-describedby="helper-checkbox-text"
                                                            value=""
                                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                                    </div>
                                                    <div className="ms-2 text-sm">
                                                        <label htmlFor="checkbox_w"
                                                               className="font-medium text-gray-900 dark:text-gray-300">
                                                            I’m aware of the risks and would like to proceed
                                                        </label>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <button onClick={Section_Part_2}
                                                className={`bg-green-500 text-white py-3 px-8 rounded-lg w-full ${isChecked ? 'hover:bg-green-600' : 'bg-opacity-50 cursor-not-allowed'}`}
                                                disabled={!isChecked}
                                        >
                                            Create a wallet
                                        </button>
                                    </div>
                                )}

                                {section_Part_w_2 === 2 && (
                                    <div>
                                        LODING
                                    </div>
                                )}

                                {section_Part_w_2 === 3 && (
                                    <div className=" rounded-md  mx-auto">
                                        <div className="flex flex-col space-y-2">
                                            <div
                                                className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-2 md:space-y-0">
                                                <span className="text-base md:text-lg font-semibold">Deposit To</span>
                                                <div
                                                    className="flex flex-col md:flex-row space-y-2 md:space-x-4 md:space-y-0 text-sm text-blue-600">
                                                    {/*<a href="#" className="hover:underline">Historical addresses</a>*/}
                                                    {/*<a href="#" className="hover:underline">Use new address</a>*/}
                                                </div>
                                            </div>
                                            <div
                                                className="flex flex-col dark:bg-gray-700 md:flex-row items-start md:items-center justify-between bg-gray-200 p-2 rounded-md space-y-2 md:space-y-0">
                                                <span
                                                    className="text-xs md:text-sm  font-mono text-gray-700 ">
                                                    <b className="md:text-xl dark:text-white">
                                                     {walletData ? walletData.address : 'Loading...'}
                                                    </b>
                                                </span>
                                                <div className="flex space-x-2">
                                                    <button onClick={Copy_wallet_address}
                                                            className="p-1  rounded hover:bg-gray-400 dark:hover:bg-gray-800">
                                                        <i className="fa-solid fa-copy text-xl"></i>
                                                    </button>
                                                    <button onClick={Target_model_2}
                                                            className="p-1 rounded hover:bg-gray-400 dark:hover:bg-gray-800">
                                                        <i className="fa-solid fa-qrcode text-xl"></i>
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                )}


                            </div>
                        )}


                    </div>


                    <div className="md:col-span-1">


                        {section_Part_w_1 === 1 && (
                            <div>


                                <div>
                                    <div className="mb-6">
                                        <h3 className="text-lg font-semibold">Total Assets</h3>
                                        <div>
                                            <div
                                                className="w-1/4 mt-2  h-4 bg-gray-300 rounded animate-pulse"></div>
                                        </div>
                                        <div className="flex justify-between text-gray-400 text-sm mt-2">
                                            <span>Available</span>
                                            <span className="text-red-600">---</span>
                                        </div>
                                        <div className="flex justify-between text-gray-400 text-sm mt-2">
                                            <span>Frozen</span>
                                            <span className="text-red-600">---</span>
                                        </div>
                                    </div>
                                    <div className="mb-6">
                                        <h3 className="text-lg font-semibold">Attention</h3>
                                        <div className="text-sm text-orange-500">
                                            <div className="w-2/4 mt-1 h-4 bg-gray-300 rounded animate-pulse"></div>
                                        </div>
                                        <div className="text-sm text-orange-500">
                                            <div className="w-1/4 mt-1 h-4 bg-gray-300 rounded animate-pulse"></div>
                                        </div>
                                        <div className="text-sm text-orange-500">
                                            <div className="w-2/4 mt-1 h-4 bg-gray-300 rounded animate-pulse"></div>
                                        </div>
                                        <div className="text-green-500 text-sm mt-2 block">
                                            <div className="w-1/4 mt-1 h-4 bg-gray-300 rounded animate-pulse"></div>
                                        </div>
                                    </div>

                                    <div>
                                        <h3 className="text-lg font-semibold">FAQ</h3>
                                        <div className="text-sm mb-2">
                                            <div className="text-blue-500">
                                                <div className="w-3/4 mt-1 h-4 bg-gray-300 rounded animate-pulse"></div>
                                            </div>
                                        </div>
                                        <div className="text-sm mb-2">
                                            <div className="text-blue-500">
                                                <div className="w-3/4 mt-1 h-4 bg-gray-300 rounded animate-pulse"></div>
                                            </div>
                                        </div>
                                        <div className="flex space-x-2 mt-2">
                                            <button
                                                className="bg-green-100 text-green-500 px-3 py-1 rounded-lg text-sm">
                                                <div className="w-2/4 mt-1 h-4 bg-gray-300 rounded animate-pulse"></div>
                                            </button>
                                            <button
                                                className="bg-green-100 text-green-500 px-3 py-1 rounded-lg text-sm">
                                                <div className="w-2/4 mt-1 h-4 bg-gray-300 rounded animate-pulse"></div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}


                        {section_Part_w_1 === 2 && (
                            <div>
                                <div className="mb-6">
                                    <h3 className="text-lg font-semibold">Total Assets</h3>
                                    <p>0 {walletData ? walletData.symbol : 'Loading...'}</p>
                                    <div className="flex justify-between text-gray-400 text-sm mt-2">
                                        <span>Available</span>
                                        <span>   {walletData ? walletData.amount : 'Loading...'} {walletData ? walletData.symbol : 'Loading...'}</span>
                                    </div>
                                    <div className="flex justify-between text-gray-400 text-sm mt-2">
                                        <span>Frozen</span>
                                        <span>   {walletData ? walletData.Frozen_amount : 'Loading...'} {walletData ? walletData.symbol : 'Loading...'}</span>
                                    </div>
                                </div>
                                <div className="mb-6">
                                    <h3 className="text-lg font-semibold">Attention</h3>
                                    <p className="text-sm text-orange-500">Minimum
                                        deposit: {walletData ? walletData.Minimum_deposit : 'Loading...'} {walletData ? walletData.symbol : 'Loading...'}</p>
                                    <p className="text-sm text-orange-500">Deposit
                                        arrival: {walletData ? walletData.Confirmation_deposit : 'Loading...'} Confirmation(s)</p>
                                    <p className="text-sm text-orange-500">Withdrawal
                                        unlock: {walletData ? walletData.Confirmation_deposit : 'Loading...'} Confirmation(s)</p>
                                    <a href="#" className="text-green-500 text-sm mt-2 block">View More</a>
                                </div>

                                <div>
                                    <h3 className="text-lg font-semibold">FAQ</h3>
                                    <p className="text-sm mb-2"><a href="#" className="text-blue-500">Deposit
                                        uncredited?</a></p>
                                    <p className="text-sm mb-2"><a href="#" className="text-blue-500">How to choose the
                                        public chain
                                        for your deposit?</a></p>
                                    <div className="flex space-x-2 mt-2">
                                        <button
                                            className="bg-green-100 text-green-500 px-3 py-1 rounded-lg text-sm">Newbie
                                            Guide
                                        </button>
                                        <button
                                            className="bg-green-100 text-green-500 px-3 py-1 rounded-lg text-sm">Video
                                            Tutorial
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}


                    </div>
                </div>


                {is_model_2 && (
                    <div>


                        <div
                            className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
                            onClick={Target_model_2}>
                            <div className="relative w-full max-w-lg dark:bg-gray-800 bg-white p-6
                      md:max-w-xl md:m-auto
                      md:p-3 md:rounded-lg md:shadow-lg
                      md:relative
                      h-full md:h-auto"
                                 onClick={(e) => e.stopPropagation()}
                            >
                                <div className="flex justify-between items-center mb-4">
                                    <h2 className="text-xl dark:text-white text-black font-semibold">Deposit now</h2>
                                    <button onClick={Target_model_2}
                                            className="text-xl dark:text-gray-400 text-gray-400 dark:hover:text-white hover:text-gray-900">
                                        <i className="fa-solid fa-xmark"></i>
                                    </button>
                                </div>
                                <div className="flex justify-center mb-6 mt-8">

                                    <div className="relative">
                                        <div>
                                            <QRCodeSVG
                                                value={walletData ? walletData.address : 'Loading...'}
                                                size={256}
                                                className="bg-black"
                                                level="H"

                                            />
                                        </div>

                                        <div className="mt-2 flex items-center justify-center">
                                            <input
                                                id="walletAddress"
                                                type="text"
                                                value={walletData ? walletData.address : 'Loading...'}
                                                readOnly
                                                className="bg-gray-300 border rounded px-4 py-2 text-gray-600 w-full max-w-xs overflow-hidden text-ellipsis focus:outline-none"
                                            />
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>


                    </div>
                )}


                <div className="dark:bg-gray-800 rounded-lg shadow-lg p-2 mt-3">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                            <h2 className="text-lg font-semibold text-gray-700 dark:text-white">Last 5 Deposit
                                Records</h2>
                            <button onClick={Section_Part_4}>
                                <i className="fa-solid  fa-arrows-rotate text-yellow-400  cursor-pointer"></i>
                            </button>

                        </div>


                        <button
                            className="text-teal-600 border border-teal-600 rounded-lg px-3 py-1 hover:bg-teal-100 transition duration-200">
                            All Records
                        </button>

                    </div>
                    <div className="mt-2  text-gray-400 h-48">
                        {/*   */}


                        {/*<div*/}
                        {/*    className="hidden sm:grid dark:bg-gray-900 dark:text-white grid-cols-5 gap-4 items-center bg-gray-100 text-black p-4 border-b border-gray-600">*/}

                        {/*    <div className="text-center">Deposit</div>*/}
                        {/*    <div className="text-center">60</div>*/}
                        {/*    <div className="text-center">1403/08/02 13:31</div>*/}
                        {/*    <div className="text-center">*/}
                        {/*        <span className="px-3 py-1 bg-green-600 text-white rounded-full text-xs">successful</span>*/}
                        {/*    </div>*/}
                        {/*    <button className="text-center text-purple-400"> Information</button>*/}
                        {/*</div>*/}

                        {/*/!*-----*!/*/}
                        {/*<div className="sm:hidden bg-gray-100 text-black dark:bg-gray-800 dark:text-white p-4 mb-4 rounded-lg shadow">*/}
                        {/*    <div className="flex justify-between items-center mb-2">*/}
                        {/*        <span className="text-xs">Type</span>*/}
                        {/*        <span className="text-gray-400">Deposit</span>*/}
                        {/*    </div>*/}
                        {/*    <div className="flex justify-between items-center mb-2">*/}
                        {/*        <span className="text-xs">Receipt</span>*/}
                        {/*        <span className="text-gray-400">60</span>*/}
                        {/*    </div>*/}
                        {/*    <div className="flex justify-between items-center mb-2">*/}
                        {/*        <span className="text-xs">Time</span>*/}
                        {/*        <span className="text-gray-400">1403/08/02 13:31</span>*/}
                        {/*    </div>*/}

                        {/*    <div className="flex justify-between items-center mb-2">*/}
                        {/*        <span className="text-xs">status</span>*/}
                        {/*        <span className="px-2 py-1 bg-green-600 text-white rounded-full text-xs">successful</span>*/}
                        {/*    </div>*/}
                        {/*    <button className="text-center mt-2 text-purple-400"> Information </button>*/}
                        {/*</div>*/}

                        {section_Part_w_3 === 1 && (
                            <div>
                                {token_depositData && token_depositData.length > 0 ? (
                                    <div>
                                        <div className="hidden sm:grid grid-cols-5 gap-4 dark:bg-gray-900 bg-gray-200 text-gray-900 dark:text-gray-100 p-4 text-center font-semibold rounded-t-lg">
                                            <div>Type</div>
                                            <div>Receipt</div>
                                            <div> Time</div>
                                            <div>status</div>
                                            <div></div>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <div className="flex flex-col items-center justify-center h-48 text-gray-400">
                                            <i className="fa-solid fa-database text-6xl text-yellow-400"></i>
                                            <p className="mt-4 text-gray-500">No data</p>
                                        </div>
                                    </div>

                                )}


                                {token_depositData
                                    .map((deposit, index) => (
                                        <div key={index}>
                                            <div
                                                className="hidden sm:grid dark:bg-gray-900 dark:text-white grid-cols-5 gap-4 items-center bg-gray-100 text-black p-4 border-b border-gray-600">

                                                <div className="text-center">{deposit.Type}</div>
                                                <div className="text-center">{deposit.amount}</div>
                                                <div className="text-center">{deposit.time}</div>
                                                <div className="text-center">
                                                    {deposit.status === "true" ? (
                                                        <span className="px-2 py-1 bg-green-600 text-white rounded-full text-xs">Successful</span>
                                                    ) : deposit.status === "pending" ? (
                                                        <span className="px-2 py-1 bg-yellow-500 text-white rounded-full text-xs">Pending</span>
                                                    ) : (
                                                        <span className="px-2 py-1 bg-red-600 text-white rounded-full text-xs">Failed</span>
                                                    )}
                                                </div>
                                                <button onClick={() => Section_Part_5(deposit.id)} className="text-center text-purple-400"> Information</button>
                                            </div>

                                            {/*-----*/}
                                            <div
                                                className="sm:hidden bg-gray-100 text-black dark:bg-gray-800 dark:text-white p-4 mb-4 rounded-lg shadow">
                                                <div className="flex justify-between items-center mb-2">
                                                    <span className="text-xs">Type</span>
                                                    <span className="text-gray-400">{deposit.Type}</span>
                                                </div>
                                                <div className="flex justify-between items-center mb-2">
                                                    <span className="text-xs">Receipt</span>
                                                    <span className="text-gray-400">    {deposit.amount}</span>
                                                </div>
                                                <div className="flex justify-between items-center mb-2">
                                                    <span className="text-xs">Time</span>
                                                    <span className="text-gray-400">{deposit.time}</span>
                                                </div>

                                                <div className="flex justify-between items-center mb-2">
                                                    <span className="text-xs">status</span>
                                                    {deposit.status === "true" ? (
                                                        <span className="px-2 py-1 bg-green-600 text-white rounded-full text-xs">Successful</span>
                                                    ) : deposit.status === "pending" ? (
                                                        <span className="px-2 py-1 bg-yellow-500 text-white rounded-full text-xs">Pending</span>
                                                    ) : (
                                                        <span className="px-2 py-1 bg-red-600 text-white rounded-full text-xs">Failed</span>
                                                    )}

                                                </div>
                                                <button onClick={() => Section_Part_5(deposit.id)} className="text-center mt-2 text-purple-400"> Information</button>
                                            </div>
                                        </div>
                                    ))}

                            </div>
                        )}

                        {section_Part_w_3 === 2 && (
                            <div>
                                <div className="flex flex-col items-center justify-center h-48 text-gray-400">
                                    <div className="loader_Page flex ">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>

                                </div>
                            </div>
                        )}


                    </div>
                </div>


                {is_model_3 && (
                    <div>


                        <div
                            className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
                            onClick={Target_model_3}>
                            <div className="relative w-full max-w-lg dark:bg-gray-800 bg-white p-6
                      md:max-w-xl md:m-auto
                      md:p-3 md:rounded-lg md:shadow-lg
                      md:relative
                      h-full md:h-auto"
                                 onClick={(e) => e.stopPropagation()}
                            >
                                <div className="flex justify-between items-center mb-4">
                                    <h2 className="text-xl dark:text-white text-black font-semibold">
                                        Deposit information
                                    </h2>
                                    <button onClick={Target_model_3}
                                            className="text-xl dark:text-gray-400 text-gray-400 dark:hover:text-white hover:text-gray-900">
                                        <i className="fa-solid fa-xmark"></i>
                                    </button>
                                </div>

                                {section_Part_w_4 === 1 && (
                                    <div>
                                        <div className="flex flex-col items-center justify-center h-48 text-gray-400">
                                            <div className="loader_Page flex ">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>

                                        </div>
                                    </div>
                                )}

                                {section_Part_w_4 === 2 && (
                                    <div>
                                        <div
                                            className=" bg-gray-100 text-black dark:bg-gray-800 dark:text-white p-4 mb-4 rounded-lg shadow">

                                            <div className="flex justify-between items-center mb-2">
                                                <span>Symbol: </span>
                                                <span
                                                    className="text-gray-400">{historyselection ? historyselection.token : 'Loading...'}</span>
                                            </div>
                                            <div className="flex justify-between items-center mb-2">
                                                <span>Network: </span>
                                                <span
                                                    className="text-gray-400">{historyselection ? historyselection.Network : 'Loading...'}</span>
                                            </div>


                                            <div className="flex justify-between items-center mb-2">
                                                <span>Type: </span>
                                                <span
                                                    className="text-gray-400">{historyselection ? historyselection.Type : 'Loading...'}</span>
                                            </div>


                                            <div className="flex justify-between items-center mb-2">
                                                <span>Amount: </span>
                                                <span
                                                    className="text-gray-400">{historyselection ? historyselection.amount : 'Loading...'}</span>
                                            </div>


                                            <div className="flex justify-between items-center mb-2">
                                                <span className="">Transaction ID: </span>
                                                <input
                                                    type="text"
                                                    value={historyselection ? historyselection.Transaction : 'Loading...'}
                                                    className="text-gray-400 bg-gray-100 dark:bg-gray-800 p-1 rounded w-1/2 overflow-hidden text-ellipsis outline-none focus:ring-0"
                                                    readOnly
                                                />
                                                <button onClick={Copy_Transaction}
                                                        className="p-1  rounded hover:bg-gray-400 dark:hover:bg-gray-800">
                                                    <i className="fa-solid fa-copy text-xl"></i>
                                                </button>
                                            </div>

                                            <div className="flex justify-between items-center mb-2">
                                                <span className="">Sender's wallet: </span>
                                                <input
                                                    type="text"
                                                    value={historyselection ? historyselection.wallet_send : 'Loading...'}
                                                    className="text-gray-400 bg-gray-100 dark:bg-gray-800 p-1 rounded w-1/2 overflow-hidden text-ellipsis outline-none focus:ring-0"
                                                    readOnly
                                                />
                                                <button onClick={Copy_wallet_Received}
                                                        className="p-1  rounded hover:bg-gray-400 dark:hover:bg-gray-800">
                                                    <i className="fa-solid fa-copy text-xl"></i>
                                                </button>
                                            </div>
                                            <div className="flex justify-between items-center mb-2">
                                                <span>Time: </span>
                                                <span
                                                    className="text-gray-400">{historyselection ? historyselection.time : 'Loading...'}</span>
                                            </div>


                                            <div className="flex justify-between items-center mb-2">
                                                <span>Status:</span>
                                                <span className="px-2 py-1 bg-green-600 text-white rounded-full text-xs">{historyselection ? historyselection.status_deposit : 'Loading...'}</span>
                                            </div>

                                        </div>

                                    </div>
                                )}


                            </div>
                        </div>
                    </div>
                )}


                <div>
                    <br/><br/><br/><br/><br/>
                </div>
            </div>


        </div>
    );


}

export default Walletdeposit;
