import React, {useEffect, useState} from 'react';
import {getCookie} from "../../Format/En/Cookie";
import {toast} from "react-toastify";
import LoadingPage from "../Loading-Page";
import {Link} from "react-router-dom";

import { useSpring, animated, useTransition } from 'react-spring';
import { useDrag } from '@use-gesture/react';
import { useMediaQuery } from 'react-responsive';

const UserCard = () => {
    // Connect to run page
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    useEffect(() => {
        if (!isDataLoaded) {
            Load_Pages_Min().then(() => {
                setIsDataLoaded(true);
            });
        }
    }, [isDataLoaded]);
    //  END Connect to run page
    const [profileData, setProfileData] = useState(null);
    async function Load_Pages_Min() {

        const loaderElement = document.getElementById('Loading_Page');
        const pageElement = document.getElementById('Page');

        // loaderElement.classList.add('hidden');

        let meeting = getCookie("meeting");


        try {
            const requestData = {
                Session_ID:            meeting,
            };
            const response = await fetch(`${window.Web_Url_Api}/v1/Profile/user/Information.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData)
            });

            if (!response.ok) {
                toast('Internet connection error');
                return;
            }

            pageElement.classList.remove('hidden');
            loaderElement.classList.add('hidden');


            const data = await response.json();
            setProfileData(data);


            if (data.status_Account === 'verified'){

                section_Part_Re_w_2(1);
            }else {
                section_Part_Re_w_2(2);
            }

        } catch (error) {
            toast('An error has occurred');
        }
    }




    // -----------
    const [isOpen, setIsOpen] = useState(false);
    const toggleDetails = () => {
        setIsOpen(!isOpen);
    };


    // ---

    // ---
    const [is_model_2, Modal_Full_2] = useState(false);

    const Target_model_2 = () => {
        Modal_Full_2(!is_model_2);
    };
    // -----------------------------
    const [section_Part_w_1, section_Part_Re_w_1] = useState(1);
    const [section_Part_w_2, section_Part_Re_w_2] = useState(1);
    const [section_Part_w_3, section_Part_Re_w_3] = useState(1);
    const [section_Part_w_4, section_Part_Re_w_4] = useState(1);
    // --------------------
    const Section_Part_3 = () => {
        var Code_Delete_Account = document.getElementById('Code_Delete_Account').value;
            if (Code_Delete_Account !== ''){
            if (Code_Delete_Account ===   Window.Code_delete_account){
            section_Part_Re_w_3(2);
            let meeting = getCookie("meeting");


                var data = {
                    Session_ID:            meeting,
                    Code_Delete_Account:   Code_Delete_Account,
                };

                var actionURL = `${window.Web_Url_Api}/v1/Profile/user/delete-account.php`;

                fetch(actionURL, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                })
                    .then(response => response.json())
                    .then(data => {

                        toast(data.message);

                        if (data.status === 'true') {
                            section_Part_Re_w_4(2);
                            section_Part_Re_w_3(3);
                            Window.Code_delete_account = data.Code
                        }

                    })
                    .catch((error) => {
                        toast('An error has occurred P01');
                        section_Part_Re_w_3(1);
                    });


            }else {toast('The security code is not correct');}
            }else {toast('It should not be empty.');}
    }


    // -------
    const Section_Part_2 = () => {
        let meeting = getCookie("meeting");
        var Validation = document.getElementById('Validation').value;

        if (Validation !== ''){
            if (Validation === 'Delete'){

                section_Part_Re_w_3(2);

                var data = {
                    Session_ID:            meeting,
                    Validation:            Validation,
                };

                var actionURL = `${window.Web_Url_Api}/v1/Profile/user/delete-account-code.php`;

                fetch(actionURL, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                })
                    .then(response => response.json())
                    .then(data => {

                        toast(data.message);

                        if (data.status === 'true') {
                            section_Part_Re_w_4(2);
                            section_Part_Re_w_3(3);
                           Window.Code_delete_account = data.Code
                        }

                    })
                    .catch((error) => {
                        toast('An error has occurred P01');
                        section_Part_Re_w_3(1);
                    });

            }else {
                toast('Error in security code');
            }
        }else {
            toast('It should not be empty');
        }




    }

    // ---------
    const Section_Part_1 = () => {
        let meeting = getCookie("meeting");



        var username = document.getElementById('username').value;

// Regular Expression for validation
        var usernamePattern = /^[a-zA-Z0-9]+$/;

        if (username === '') {
            toast('An error has occurred 2');

        } else if (!usernamePattern.test(username)) {
            toast('An error has occurred 1');

        } else {

            section_Part_Re_w_1(2);

            var data = {
                Session_ID:            meeting,
                username:              username,
            };

            var actionURL = `${window.Web_Url_Api}/v1/Profile/user/Set-username.php`;

            fetch(actionURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then(response => response.json())
                .then(data => {

                    section_Part_Re_w_1(1);

                    toast(data.message);

                    if (data.status === 'true') {
                        Target_model_1();

                        setProfileData((prevData) => ({
                            ...prevData,
                            Username: username,
                        }));
                    }


                })
                .catch(() => {
                    toast('An error has occurred');
                    section_Part_Re_w_1(1);
                });





        }






    };
//--------------------------------------------------------------------------------------------------------------------//
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

//--------------------------------------------------------------------------------------------------------------------//

    const [is_model_1, Modal_Full_1] = useState(false);
    const Target_model_1 = () => Modal_Full_1(!is_model_1);
    const [{ y1 }, api1] = useSpring(() => ({ y1: 0 }));
    const bind1 = useDrag(
        ({ last, movement: [, my], cancel }) => {
            if (my > 300) cancel(); // جلوگیری از کشیدن بیش از حد

            if (last) {
                if (my > 150) Modal_Full_1(false); // مدل را می‌بندد
                api1.start({ y1: 0 }); // بازگشت به موقعیت اولیه
            } else {
                api1.start({ y1: my });
            }
        },
        { from: () => [0, y1.get()], bounds: { top: 0 }, rubberband: true }
    );
    const transitions_1 = useTransition(is_model_1, {
        from: isMobile ? { opacity: 0, transform: 'translateY(100%)' } : { opacity: 0 },
        enter: isMobile ? { opacity: 1, transform: 'translateY(0%)' } : { opacity: 1 },
        leave: isMobile ? { opacity: 0, transform: 'translateY(100%)' } : { opacity: 0 },
    });

//--------------------------------------------------------------------------------------------------------------------//

//--------------------------------------------------------------------------------------------------------------------//
    return (


        <div>
            <LoadingPage/>
            <div id="Page" className="hidden">


                <div className="p-2">

                    <div
                        className="flex mb-2 items-center space-x-4 text-black dark:text-white p-4 ">
                        <Link to='/account/profile/security'
                              className="text-3xl text-gray-600 hover:text-gray-800 dark:text-white dark:hover:text-white transition-colors">
                            <i className="fa-solid fa-arrow-left"></i>
                        </Link>
                        <div className="flex items-center space-x-3">
                            <i className="fa-solid fa-user text-3xl text-yellow-500 "></i>
                            <h1 className='text-2xl font-semibold'>Information</h1>
                        </div>
                    </div>


                    <div
                        className="bg-gray-100  text-black dark:bg-gray-800 dark:text-white  p-6 rounded-lg shadow-lg w-full">


                        <div className="hidden md:flex items-center justify-between">
                            <div className="flex items-center space-x-4">
                                <div className="bg-yellow-200 w-12 h-12 rounded-full flex items-center justify-center">
                                    <span className="text-3xl text-gray-800  font-bold">
                                        <i className="fa-duotone fa-solid fa-user p-2"></i>
                                    </span>
                                </div>
                                <div
                                    className="text-lg font-semibold">{profileData ? profileData.Username : 'Loading...'}</div>
                            </div>

                            <div className="text-gray-400 text-sm flex flex-col">
                                <span className="text-xs">Account level</span>
                                <div className="flex items-center">
                                    <span
                                        className="text-black dark:text-white">{profileData ? profileData.level : 'Loading...'}</span>
                                </div>
                            </div>

                            <div className="text-gray-400 text-sm flex flex-col">
                                <span className="text-xs">Registration time</span>
                                <div className="flex items-center">
                                    <span
                                        className="text-black dark:text-white">{profileData ? profileData.Time_Registration : 'Loading...'}</span>
                                </div>
                            </div>

                        </div>


                        <div className="md:hidden">
                            <div className="flex items-center justify-between">
                                <div className="flex items-center space-x-4">
                                    <div
                                        className="bg-yellow-200 w-12 h-12 rounded-full flex items-center justify-center">
                                        <i className="fa-duotone text-black text-2xl fa-solid fa-user p-2"></i>
                                    </div>
                                    <div
                                        className="text-lg font-semibold">{profileData ? profileData.Username : 'Loading...'}</div>
                                </div>

                                <button onClick={toggleDetails} className="text-gray-400">
                                    {isOpen ? (
                                        <svg
                                            className="w-6 h-6"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M5 15l7-7 7 7"
                                            ></path>
                                        </svg>
                                    ) : (
                                        <svg
                                            className="w-6 h-6"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M19 9l-7 7-7-7"
                                            ></path>
                                        </svg>
                                    )}
                                </button>
                            </div>


                            {isOpen && (
                                <div className="mt-4 space-y-2">

                                    <div className="flex justify-between text-gray-400 text-sm">
                                        <span className="font-bold">Account level:</span>
                                        <span
                                            className="text-black dark:text-white">{profileData ? profileData.level : 'Loading...'}</span>
                                    </div>

                                    <div className="flex justify-between text-gray-400 text-sm">
                                        <span className="font-bold">Registration time: </span>
                                        <span
                                            className="text-black dark:text-white">{profileData ? profileData.Time_Registration : 'Loading...'}</span>
                                    </div>


                                </div>

                            )}
                        </div>
                    </div>


                    <div
                        className="bg-gray-100  text-black dark:bg-gray-800 dark:text-white p-6 rounded-lg shadow-lg mt-3  w-full">
                        <h2 className="text-xl font-semibold mb-4">Profile Management</h2>

                        <div className="space-y-4">


                            <div className="flex items-center justify-between">
                                <div className="flex items-center space-x-2">
                            <span
                                className="inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-800 text-white">
                                   <i className="fa-duotone fa-solid fa-address-card text-3xl "></i>
                            </span>
                                    <div>
                                        <p className="font-medium">Username</p>
                                        <p className="text-sm text-gray-600 dark:text-gray-400 hidden sm:hidden md:inline-flex">
                                            Set a username for yourself
                                        </p>
                                    </div>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <button onClick={Target_model_1}
                                            className="dark:bg-gray-700  bg-gray-300 px-4 py-2 rounded text-sm">Manage
                                    </button>



                                    {transitions_1(
                                        (styles, item) =>
                                            item && (
                                                <animated.div className="overlayStyles"
                                                              {...(isMobile ? bind1() : {})}
                                                              style={{
                                                                  ...styles,
                                                                  touchAction: 'none',
                                                                  position: 'fixed',
                                                                  inset: -7,
                                                                  display: 'flex',
                                                                  justifyContent: 'center',
                                                                  alignItems: 'center',
                                                                  zIndex: 50,

                                                              }}
                                                              onClick={(e) => e.stopPropagation()}
                                                >
                                                    <animated.div
                                                        style={{
                                                            ...styles,
                                                            transform: isMobile ? y1.to((y) => `translateY(${y}px)`) : 'none',
                                                        }}
                                                        className="relative w-full  top-1/3 md:top-0 max-w-lg dark:bg-gray-800 bg-gray-200
                        md:max-w-xl md:m-auto md:p-3 md:rounded-lg
                        md:relative h-full md:h-auto
                        shadow-1xl
                       rounded-t-[30px]
                       dark:text-white -ml-2 p-2  "
                                                    >
                                                        <div>
                                                            <div className="w-20 h-2 dark:bg-gray-500 md:hidden rounded-2xl bg-gray-400 mb-4 m-auto"></div>
                                                            <div className="p-3">

                                                                <div className="flex justify-between items-center mb-4">
                                                                    <h2 className="text-xl font-semibold">Set
                                                                        Username</h2>
                                                                    <button onClick={Target_model_1}
                                                                            className="text-xl dark:text-gray-400 text-gray-400 dark:hover:text-white hover:text-gray-900">
                                                                        <i className="fa-solid fa-xmark"></i>
                                                                    </button>
                                                                </div>
                                                                <p className="mb-4">
                                                                    You can choose a username for yourself. to share it
                                                                    with others.
                                                                    Please use only letters and numbers. Do not mark
                                                                    anything
                                                                </p>


                                                                <div className="mb-4">
                                                                    <label
                                                                        className="block  text-gray-800 dark:text-gray-400 text-sm mb-2"
                                                                        htmlFor="username">
                                                                        username
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        id="username"
                                                                        className="w-full p-2 rounded-lg dark:bg-gray-900 dark:text-white bg-gray-300 text-black focus:outline-none focus:border-white"
                                                                        defaultValue={profileData.Username || ''}
                                                                    />
                                                                </div>

                                                                <p className="mb-4">
                                                                    Username selected. It should not be repeated
                                                                </p>

                                                                <div className="flex justify-between">
                                                                    <button onClick={Target_model_1}
                                                                            className="bg-gray-600 text-white px-4 py-2 rounded hover:bg-gray-500">Cancel
                                                                    </button>


                                                                    {section_Part_w_1 === 1 && (
                                                                        <button onClick={Section_Part_1}
                                                                                className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600">Save
                                                                        </button>
                                                                    )}


                                                                    {section_Part_w_1 === 2 && (
                                                                        <button disabled
                                                                                className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600">
                                                                            <div
                                                                                className="flex items-center justify-center  centered">
                                                                                <div
                                                                                    className="loader_Page_Part_1 flex ">
                                                                                    <div></div>
                                                                                    <div></div>
                                                                                    <div></div>
                                                                                    <div></div>
                                                                                    <div></div>
                                                                                    <div></div>
                                                                                </div>
                                                                            </div>
                                                                        </button>

                                                                    )}


                                                                </div>
                                                            </div>


                                                        </div>
                                                    </animated.div>
                                                </animated.div>
                                            )
                                    )}


                                    {/*              {is_model_1 && (*/}
                                    {/*                  <div>*/}


                                    {/*                      <div*/}
                                    {/*                          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"*/}
                                    {/*                          onClick={Target_model_1}>*/}
                                    {/*                          <div className="relative w-full max-w-lg dark:bg-gray-800 bg-white p-6*/}
                                    {/*md:max-w-xl md:m-auto*/}
                                    {/*md:p-3 md:rounded-lg md:shadow-lg*/}
                                    {/*md:relative*/}
                                    {/*h-full md:h-auto"*/}
                                    {/*                               onClick={(e) => e.stopPropagation()}*/}
                                    {/*                          >*/}
                                    {/*                              <div className="flex justify-between items-center mb-4">*/}
                                    {/*                                  <h2 className="text-xl font-semibold">Set Username</h2>*/}
                                    {/*                                  <button onClick={Target_model_1}*/}
                                    {/*                                          className="text-xl dark:text-gray-400 text-gray-400 dark:hover:text-white hover:text-gray-900">*/}
                                    {/*                                      <i className="fa-solid fa-xmark"></i>*/}
                                    {/*                                  </button>*/}
                                    {/*                              </div>*/}
                                    {/*                              <p className="mb-4">*/}
                                    {/*                                  You can choose a username for yourself. to share it with others.*/}
                                    {/*                                  Please use only letters and numbers. Do not mark anything*/}
                                    {/*                              </p>*/}


                                    {/*                              <div className="mb-4">*/}
                                    {/*                                  <label*/}
                                    {/*                                      className="block text-gray-800 dark:text-gray-400 text-sm mb-2"*/}
                                    {/*                                      htmlFor="username">*/}
                                    {/*                                      username*/}
                                    {/*                                  </label>*/}
                                    {/*                                  <input*/}
                                    {/*                                      type="text"*/}
                                    {/*                                      id="username"*/}
                                    {/*                                      className="w-full p-2 rounded-lg dark:bg-gray-900 dark:text-white bg-gray-200 text-black focus:outline-none focus:border-white"*/}
                                    {/*                                      defaultValue={profileData.Username || ''}*/}
                                    {/*                                  />*/}
                                    {/*                              </div>*/}

                                    {/*                              <p className="mb-4">*/}
                                    {/*                                  Username selected. It should not be repeated*/}
                                    {/*                              </p>*/}

                                    {/*                              <div className="flex justify-between">*/}
                                    {/*                                  <button onClick={Target_model_1}*/}
                                    {/*                                          className="bg-gray-600 text-white px-4 py-2 rounded hover:bg-gray-500">Cancel*/}
                                    {/*                                  </button>*/}


                                    {/*                                  {section_Part_w_1 === 1 && (*/}
                                    {/*                                      <button onClick={Section_Part_1}*/}
                                    {/*                                              className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600">Save*/}
                                    {/*                                      </button>*/}
                                    {/*                                  )}*/}


                                    {/*                                  {section_Part_w_1 === 2 && (*/}
                      {/*                                      <button disabled*/}
                      {/*                                              className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600">*/}
                      {/*                                          <div*/}
                      {/*                                              className="flex items-center justify-center  centered">*/}
                      {/*                                              <div className="loader_Page_Part_1 flex ">*/}
                      {/*                                                  <div></div>*/}
                      {/*                                                  <div></div>*/}
                      {/*                                                  <div></div>*/}
                      {/*                                                  <div></div>*/}
                      {/*                                                  <div></div>*/}
                      {/*                                                  <div></div>*/}
                      {/*                                              </div>*/}
                      {/*                                          </div>*/}
                      {/*                                      </button>*/}

                      {/*                                  )}*/}


                      {/*                              </div>*/}
                      {/*                          </div>*/}
                      {/*                      </div>*/}


                      {/*                  </div>*/}
                      {/*              )}*/}


                                </div>
                            </div>


                        </div>
                    </div>
                    {/*p3*/}


                    {section_Part_w_2 === 1 && (
                        <div
                            className="bg-gray-100  text-black dark:bg-gray-800 dark:text-white w-full mt-2  mx-auto p-4  rounded-lg shadow-lg">
                            <h2 className="text-xl font-semibold mb-4">Authentication: </h2>


                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div className="mb-4">
                                    <label className="block  font-semibold mb-2" htmlFor="Name">
                                        Name
                                    </label>
                                    {profileData && (
                                        <input
                                            className="dark:bg-gray-600 bg-gray-200 w-full p-2  rounded"
                                            id="Name_User"
                                            type="text"
                                            disabled
                                            value={profileData.name || ''}
                                        />
                                    )}

                                </div>
                                <div className="mb-4">

                                    <label className="block  font-semibold mb-2" htmlFor="last_name">
                                        Last Name
                                    </label>
                                    {profileData && (
                                        <input
                                            className="dark:bg-gray-600 bg-gray-200 w-full p-2  rounded"
                                            id="last_name"
                                            type="text"
                                            disabled
                                            value={profileData.last_name || ''}
                                        />
                                    )}


                                </div>

                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                <div className="mb-4">
                                    <label className="block  font-semibold mb-2" htmlFor="National_ID">
                                        National ID
                                    </label>
                                    {profileData && (
                                        <input
                                            className="dark:bg-gray-600 bg-gray-200 w-full p-2  rounded"
                                            id="National_ID"
                                            type="text"
                                            disabled
                                            value={profileData.Nationality || ''}
                                        />
                                    )}


                                </div>
                                <div className="mb-4">
                                    <label className="block  font-semibold mb-2" htmlFor="zip_code">
                                        zip code
                                    </label>
                                    {profileData && (
                                        <input
                                            className="dark:bg-gray-600 bg-gray-200 w-full p-2  rounded"
                                            id="zip_code"
                                            type="text"
                                            disabled
                                            value={profileData.zip_code || ''}
                                        />
                                    )}

                                </div>
                                <div className="mb-4">
                                    <label className="block  font-semibold mb-2" htmlFor="document_Type">
                                        Document Type
                                    </label>

                                    {profileData && (

                                        <input
                                            className="dark:bg-gray-600 bg-gray-200 w-full p-2  rounded"
                                            id="zip_code"
                                            type="text"
                                            disabled
                                            value={profileData.document || ''}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
                                <div className="mb-4">
                                    <label className="block  font-semibold mb-2" htmlFor="Address">
                                        Address
                                    </label>
                                    {profileData && (
                                        <textarea value={profileData.address || ''} disabled
                                                  className="max-h-[80px] min-h-[80px] dark:bg-gray-600 bg-gray-200 w-full p-2  rounded"
                                                  id="Address">

                                    </textarea>
                                    )}

                                </div>


                            </div>

                        </div>
                    )}

                    {section_Part_w_2 === 2 && (
                        <div className="w-full ">
                            <div
                                className="bg-gray-100 text-black dark:bg-gray-900 dark:text-white p-6 rounded-lg shadow-lg w-full text-center mt-4 mb-4">
                                <div className="flex items-center justify-center mb-4">
                                    <h2 className="text-xl font-semibold">Start your authentication now</h2>
                                </div>

                                <div className="flex  flex-col sm:flex-row sm:space-x-4 space-y-3 sm:space-y-0">
                                    <Link to='/account/profile/identity'
                                          className="w-full max-w-[300px] sm:w-2/2 bg-yellow-500 text-black px-4 py-2 rounded-lg font-semibold hover:bg-yellow-600">Start
                                        authentication
                                    </Link>
                                </div>

                            </div>
                        </div>
                    )}


                    {/*p3*/}
                    <div
                        className="bg-gray-100  text-black dark:bg-gray-800 dark:text-white p-6 rounded-lg shadow-lg mt-3  w-full">
                        <h2 className="text-xl font-semibold mb-4">Account</h2>

                        <div className="space-y-4">


                            <div className="flex items-center justify-between">
                                <div className="flex items-center space-x-2">
                            <span
                                className="inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-800 text-white">
                                     <i className="fa-duotone fa-solid fa-shield-halved"></i>
                            </span>
                                    <div>
                                        <p className="font-medium"><b className="text-red-700">Delete</b> Account</p>
                                        <p className="text-sm text-gray-600 dark:text-gray-400 hidden sm:hidden md:inline-flex">
                                            Delete your account permanently.
                                        </p>
                                    </div>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <button onClick={Target_model_2}
                                            className="dark:bg-gray-700  bg-gray-300 px-4 py-2 rounded text-sm">Manage
                                    </button>
                                    {is_model_2 && (
                                        <div>


                                            <div
                                                className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
                                                onClick={Target_model_2}>
                                                <div className="relative w-full max-w-lg dark:bg-gray-800 bg-white p-6
                      md:max-w-xl md:m-auto
                      md:p-3 md:rounded-lg md:shadow-lg
                      md:relative
                      h-full md:h-auto"
                                                     onClick={(e) => e.stopPropagation()}
                                                >


                                                    {section_Part_w_4 === 1 && (
                                                        <div>
                                                            <div className="flex justify-between items-center mb-4">
                                                                <h2 className="text-xl font-semibold">Delete
                                                                    Account</h2>
                                                                <button onClick={Target_model_2}
                                                                        className="text-xl dark:text-gray-400 text-gray-400 dark:hover:text-white hover:text-gray-900">
                                                                    <i className="fa-solid fa-xmark"></i>
                                                                </button>
                                                            </div>
                                                            <p className="mb-4">
                                                                By requesting to delete the user account, all your
                                                                information
                                                                will be completely deleted from the system. All assets
                                                                on
                                                                the
                                                                website will be destroyed.
                                                                <br/><br/>
                                                                Your request will be processed after 15 days. And your
                                                                account
                                                                will be completely deleted.
                                                                <br/><br/>
                                                                If you regret your request, contact support
                                                            </p>
                                                            <div className="mb-4">
                                                                <label
                                                                    className="block text-gray-800 dark:text-gray-400 text-sm mb-2"
                                                                    htmlFor="Validation">
                                                                    Validation ( Write the word "Delete" )
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    id="Validation"
                                                                    className="w-full p-2 rounded-lg dark:bg-gray-900 dark:text-white bg-gray-200 text-black focus:outline-none focus:border-white"

                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                    {section_Part_w_4 === 2 && (
                                                        <div>
                                                            <div className="flex justify-between items-center mb-4">
                                                                <h2 className="text-xl font-semibold">Delete
                                                                    Account</h2>
                                                                <button onClick={Target_model_2}
                                                                        className="text-xl dark:text-gray-400 text-gray-400 dark:hover:text-white hover:text-gray-900">
                                                                    <i className="fa-solid fa-xmark"></i>
                                                                </button>
                                                            </div>
                                                            <p className="mb-4">
                                                                Enter the security code sent to your email. So that your
                                                                request is successfully registered
                                                            </p>
                                                            <div className="mb-4">
                                                                <label
                                                                    className="block text-gray-800 dark:text-gray-400 text-sm mb-2"
                                                                    htmlFor="Code_Delete_Account">
                                                                    Code
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    id="Code_Delete_Account"
                                                                    className="w-full p-2 rounded-lg dark:bg-gray-900 dark:text-white bg-gray-200 text-black focus:outline-none focus:border-white"

                                                                />
                                                            </div>
                                                        </div>
                                                    )}


                                                    <div className="flex justify-between">
                                                        <button onClick={Target_model_2}
                                                                className="bg-gray-600 text-white px-4 py-2 rounded hover:bg-gray-500">Cancel
                                                        </button>


                                                        {section_Part_w_3 === 3 && (
                                                            <button onClick={Section_Part_3}
                                                                    className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600">Delete
                                                                Account
                                                            </button>
                                                        )}

                                                        {section_Part_w_3 === 1 && (
                                                            <button onClick={Section_Part_2}
                                                                    className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600">Next
                                                            </button>
                                                        )}


                                                        {section_Part_w_3 === 2 && (
                                                            <button disabled
                                                                    className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600">
                                                                <div
                                                                    className="flex items-center justify-center  centered">
                                                                    <div className="loader_Page_Part_1 flex ">
                                                                        <div></div>
                                                                        <div></div>
                                                                        <div></div>
                                                                        <div></div>
                                                                        <div></div>
                                                                        <div></div>
                                                                    </div>
                                                                </div>
                                                            </button>

                                                        )}


                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    )}

                                </div>
                            </div>


                        </div>
                    </div>


                </div>


            </div>

            <div>
                <br/>
                <div className="md:hidden">
                    <br/><br/><br/><br/><br/>
                </div>
            </div>


        </div>


    );
};

export default UserCard;
