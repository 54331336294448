import React, { useState } from 'react';
import {Link} from "react-router-dom";

function Wallet() {



    return (
        <div className="p-4">


            <nav className="flex justify-center">
                <ul className="flex flex-wrap items-center font-medium text-sm">
                    <li className="p-4 lg:px-8">
                        <a className="text-slate-800 hover:text-slate-900" href="#">Prospects</a>
                    </li>
                    <li className="p-4 lg:px-8">
                        <a className="text-slate-800 hover:text-slate-900" href="#">History</a>
                    </li>

                    <li className="p-4 lg:px-8">
                        <a className="text-slate-800 hover:text-slate-900" href="#">Contacts</a>
                    </li>
                    <li className="p-4 lg:px-8">
                        <a className="text-slate-800 hover:text-slate-900" href="#">Numbers</a>
                    </li>
                </ul>
            </nav>


            <div className="bg-gray-800 text-white rounded-lg p-6 w-full ">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-lg font-semibold">Estimated Balance</h2>
                    <button className="text-gray-400 hover:text-gray-200">
                        👁️
                    </button>
                </div>
                <div className="text-3xl font-bold mb-1">0.00000214 <span className="text-sm">BTC</span></div>
                <div className="text-gray-400 mb-4">≈ $0.14706082</div>

                <div className="flex justify-between space-x-2 mb-4">
                    <Link to="/account/wallet/deposit"
                          className="bg-gray-700 hover:bg-gray-600 text-white py-2 px-4 rounded">Deposit</Link>
                    <Link to="/account/wallet/withdraw"
                          className="bg-gray-700 hover:bg-gray-600 text-white py-2 px-4 rounded">Withdraw</Link>
                    <Link to="/account/wallet/Swap"
                          className="bg-gray-700 hover:bg-gray-600 text-white py-2 px-4 rounded">Swap</Link>
                </div>

            </div>

        </div>
    );


}

export default Wallet;
