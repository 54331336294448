import React, {useEffect, useState} from 'react';
import { getCookie } from '../../Format/En/Cookie';
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import LoadingPage from '../Loading-Page';

function ProfileIdentity() {
    // Connect to run page
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    useEffect(() => {
        if (!isDataLoaded) {
            Load_Pages_Min().then(() => {
                setIsDataLoaded(true);
            });
        }
    }, [isDataLoaded]);
    //  END Connect to run page
    const [profileData, setProfileData] = useState(null);

    async function Load_Pages_Min() {
        const loaderElement  = document.getElementById('Loading_Page');
        const pageElement    = document.getElementById('Page');
        let meeting                = getCookie("meeting");
        try {
            const requestData = {
                Session_ID:            meeting,
            };
            const response = await fetch(`${window.Web_Url_Api}/v1/Profile/identity/Information.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData)
            });

            if (!response.ok) {
                toast('Internet connection error');
                return;
            }

            pageElement.classList.remove('hidden');
            loaderElement.classList.add('hidden');

            const data = await response.json();
            setProfileData(data);
            if (data.status_Page === 'create'){
                section_Part_Re_w_1(1);

            }else {
                if (data.status_Page === 'Pending'){
                    section_Part_Re_w_1(6);
                }else {
                    section_Part_Re_w_1(7);
                }
            }
        } catch (error) {
            toast('An error has occurred');
        }

    }
//--------------------------------------------------------------------------------------------------------------------//
    const [section_Part_w_1, section_Part_Re_w_1] = useState(1);
    const [section_Part_w_2, section_Part_Re_w_2] = useState(1);
//--------------------------------------------------------------------------------------------------------------------//
    const [Document_review, setDocumentType] = useState("");
//--------------------------------------------------------------------------------------------------------------------//
    const Section_Part_1 = () => {
        section_Part_Re_w_1(1);
    };
    const Section_Part_2 = () => {
        section_Part_Re_w_1(2);
    };
//--------------------------------------------------------------------------------------------------------------------//
    const Section_Part_3 = () => {
        var country            = document.getElementById('country').value;
        if (country !== ''){
            window.identity_country = country ;
            if (Document_review !== ''){
                if (Document_review === 'Passport'){
                    section_Part_Re_w_1(3);
                }else {
                    if (Document_review === 'ID Card' ){
                        section_Part_Re_w_1(8);
                    }else {
                        if ( Document_review === 'Driver\'s License'){
                            section_Part_Re_w_1(9);
                        }
                    }
                }
            }else {
                toast('Complete all sections');
            }
        }else {
            toast('Complete all sections');
        }
    };
//--------------------------------------------------------------------------------------------------------------------//
    const Section_Part_4 = () => {
        section_Part_Re_w_1(4);
    };
//--------------------------------------------------------------------------------------------------------------------//
    const Section_Part_5 = () => {
        var nationalId_1            = document.getElementById('nationalId_1').value;
        if (nationalId_1 !== ''){
            window.identity_nationalId_1 = nationalId_1;
            section_Part_Re_w_1(5);
        }else {
            toast('Enter the document ID');
        }
    };
//--------------------------------------------------------------------------------------------------------------------//
    const Section_Part_6 = () => {
        const meeting = getCookie("meeting");
        const firstName             = document.getElementById('firstName').value;
        const lastName              = document.getElementById('lastName').value;
        const address               = document.getElementById('address').value;
        const postalCode            = document.getElementById('postalCode').value;
        if (firstName !== '' && lastName !== '' && address !== '' && postalCode !== '' ){
            section_Part_Re_w_2(2);

            const data = {
                Session_ID:            meeting,

                firstName:             firstName,
                lastName:              lastName,
                address:               address,
                postalCode:            postalCode,
                nationalId:            window.identity_nationalId_1,
                country:               window.identity_country,
                Document_review:       Document_review,
                Post:                  window.identity_Psot_Passport,

            };

            const actionURL = `${window.Web_Url_Api}/v1/Profile/identity/identity-send-Passport.php`;

            fetch(actionURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then(response => response.json())
                .then(data => {
                    section_Part_Re_w_2(1);
                    toast(data.message);
                    if (data.status === 'true'){
                        section_Part_Re_w_1(6);
                    }
                })
                .catch(() => {
                    toast('An error has occurred');
                    section_Part_Re_w_1(1);
                });

        }else {
            toast('Complete all sections' );
        }
    };
//--------------------------------------------------------------------------------------------------------------------//
    const [selectedImage_post_1, setSelectedImage_post_1] = useState(null);
    const [isImageValid_post_1, setIsImageValid_post_1] = useState(false);
//--------------------------------------------------------------------------------------------------------------------//
    if (selectedImage_post_1 !== null) {
        window.identity_Psot_Passport = selectedImage_post_1
    }
    const handleImageUpload_post_1 = (event) => {
        const file_post_1 = event.target.files[0];
        if (file_post_1) {
            const fileSizeInMB_post_1 = file_post_1.size / (1024 * 1024);
            const validFormats_post_1 = ["image/jpeg", "image/png", "image/jpg"];

            if (fileSizeInMB_post_1 > 5) {
                toast('File size exceeds 5MB' );
                return;
            }
            if (!validFormats_post_1.includes(file_post_1.type)) {
                toast('Invalid file format. Only .jpg, .jpeg, and .png are allowed.' );
                return;
            }
            const reader_post_1 = new FileReader();
            reader_post_1.onloadend = () => {
                setSelectedImage_post_1(reader_post_1.result);
                setIsImageValid_post_1(true);
            };
            reader_post_1.readAsDataURL(file_post_1);
        }
    };
    const removeImage_post_1 = () => {
        setSelectedImage_post_1(null);
        setIsImageValid_post_1(false);
    };

    return (

        <div>
            <LoadingPage/>
            <div className="hidden pl-4 pr-4" id="Page">
                {section_Part_w_1 === 1 && (
                    <div>
                        <div className="flex items-center space-x-2 text-black dark:text-white ">
                            <Link to="/account/profile/" className=" text-2xl">
                                <i className="fa-solid fa-arrow-left"></i>
                            </Link>
                            <h1 className='text-2xl  p-5 rounded-[3px]'>Identity </h1>

                        </div>
                        <div className='flex items-center space-x-4 p-4 rounded-lg mt-1'>
                            <div className='w-20 h-20 rounded-full bg-gray-200 flex items-center justify-center'>
                                <i className='fa-solid fa-user text-yellow-500 text-5xl'></i>
                            </div>
                            <div>
                                <div id="Username_Data_1" className=' text-black dark:text-white font-semibold text-lg'>
                                    {profileData ? profileData.Username : 'Loading...'}
                                </div>
                                <div id="status_Account"
                                     dangerouslySetInnerHTML={{__html: profileData ? profileData.status_Account : ''}}>
                                </div>

                            </div>

                        </div>
                        <hr/>


                        <div className='space-y-6'>
                            <div className='flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4'>
                                <div className='w-full sm:w-2/3'>
                                    <div
                                        className="bg-gray-300 text-black dark:bg-gray-900 dark:text-white p-6 rounded-lg shadow-lg w-full text-center mt-4 mb-4">
                                        <div className="flex items-center justify-center mb-4">
                                            <h2 className="text-xl font-semibold">Start your authentication now</h2>
                                        </div>
                                        <div className="flex items-center justify-center mb-4">
                                            <p className="text-gray-500 font-semibold">In less than 5 minutes, you can complete all the steps to verify your account and use all Emorad services. Please use the place where you are staying when verifying your identity.</p>
                                        </div>

                                        <div className="flex  flex-col sm:flex-row sm:space-x-4 space-y-3 sm:space-y-0">
                                            <button
                                                onClick={Section_Part_2}
                                                className="w-full max-w-[300px] sm:w-2/2 bg-yellow-500 text-black px-4 py-2 rounded-lg font-semibold hover:bg-yellow-600">
                                                Start authentication
                                            </button>

                                        </div>
                                        <div className="mt-6">
                                            <p className="text-gray-500">Need help?</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-full sm:w-1/3'>
                                    <div className="text-black dark:text-white p-4 w-full">
                                        <h2 className="text-lg font-semibold mb-2">Authentication steps: </h2>
                                        <div className="text-gray-500">
                                            <p>1. Start the application</p>
                                            <p>2. Authentication type</p>
                                            <p>3. Degree requirements</p>
                                            <p>4. Upload the document</p>
                                            <p>5. Personal information</p>
                                            <p>6. Submit request</p>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>


                        <div
                            className=" mt-4 mx-auto   p-6">
                            <hr/>
                            <div className="text-black dark:text-white  p-2  w-full">
                                <h2 className="text-xl font-semibold mb-4">Account Limits</h2>
                                <div className="space-y-3">
                                    <div className="flex justify-between items-center">
                                        <div className="flex items-center">
                                            <span className="mr-2">
                                           <i className="fa-duotone fa-solid fa-lock-keyhole"></i>
                                            </span>
                                            <p>Unlimited withdrawals allowed</p>
                                        </div>
                                        <span>---</span>
                                    </div>

                                    <div className="flex justify-between items-center">
                                        <div className="flex items-center">
                                            <span className="mr-2"> <i
                                                className="fa-duotone fa-solid fa-lock-keyhole"></i> </span>
                                            <p>Authorized to use the service</p>
                                        </div>
                                        <span>---</span>
                                    </div>

                                    <div className="flex justify-between items-center">
                                        <div className="flex items-center">
                                            <span className="mr-2"> <i
                                                className="fa-duotone fa-solid fa-lock-keyhole"></i> </span>
                                            <p>Obtaining loans and financial support</p>
                                        </div>
                                        <span>---</span>
                                    </div>

                                    <div className="flex justify-between items-center">
                                        <div className="flex items-center">
                                            <span className="mr-2"> <i
                                                className="fa-duotone fa-solid fa-lock-keyhole"></i> </span>
                                            <p>Wallet services</p>
                                        </div>
                                        <span>---</span>
                                    </div>
                                    <div className="flex justify-between items-center">
                                        <div className="flex items-center">
                                            <span className="mr-2"> <i
                                                className="fa-duotone fa-solid fa-lock-keyhole"></i> </span>
                                            <p>No limit on daily transactions</p>
                                        </div>
                                        <span>---</span>
                                    </div>
                                    <div className="flex justify-between items-center">
                                        <div className="flex items-center">
                                            <span className="mr-2"> <i
                                                className="fa-duotone fa-solid fa-lock-keyhole"></i> </span>
                                            <p>Investment in all sectors</p>
                                        </div>
                                        <span>---</span>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                )}
                {section_Part_w_1 === 2 && (
                    <div>

                        <div className="flex items-center space-x-2 text-black dark:text-white ">
                            <button onClick={Section_Part_1} className=" text-2xl">
                                <i className="fa-solid fa-arrow-left"></i>
                            </button>
                            <h1 className='text-2xl  p-5 rounded-[3px]'>Identity - Documents </h1>
                        </div>


                        <div className="flex  justify-center items-center">
                            <div
                                className="text-black bg-gray-200 dark:bg-gray-900 dark:text-white p-8 rounded-lg shadow-lg max-w-md w-full">
                                <h2 className="text-2xl font-semibold  mb-4">
                                    Document Verification
                                </h2>
                                <p className=" mb-1">
                                    Your documents will be scanned. And the inquiry is done.
                                </p>
                                <p className=" mb-6">
                                    Your documents and address must be absolute to the country you choose
                                </p>


                                {/* Country/Region Selection */}
                                <div className="mb-4">
                                    <label
                                        htmlFor="country"
                                        className="block text-sm font-medium  mb-2"
                                    >
                                        Document Issuing Country/Region
                                    </label>
                                    <select
                                        id="country"
                                        className="block w-full mt-1 p-2 bg-gray-300 dark:bg-gray-800  rounded "
                                    >
                                        <option value="Malaysia">Malaysia</option>
                                        <option value="USA">USA</option>
                                        <option value="UK">UK</option>
                                    </select>
                                </div>


                                <div className="mb-6">
                                    <label className="block text-sm font-medium  mb-2">
                                        Document Type
                                    </label>
                                    <div className="space-y-4">
                                        <div
                                            className={`p-4 rounded-lg cursor-pointer flex items-center justify-between ${
                                                Document_review === "Driver's License"
                                                    ? "bg-gray-400 dark:bg-gray-700"
                                                    : "bg-gray-300 dark:bg-gray-800"
                                            }`}
                                            onClick={() => setDocumentType("Driver's License")}
                                        >
                                            <span>Driver's License</span>
                                            <input
                                                type="radio"
                                                name="documentType"
                                                value="Driver's License"
                                                checked={Document_review === "Driver's License"}
                                                onChange={() => setDocumentType("Driver's License")}
                                            />
                                        </div>
                                        <div
                                            className={`p-4 rounded-lg cursor-pointer flex items-center justify-between ${
                                                Document_review === "ID Card"
                                                    ? "bg-gray-400 dark:bg-gray-700"
                                                    : "bg-gray-300 dark:bg-gray-800"
                                            }`}
                                            onClick={() => setDocumentType("ID Card")}
                                        >
                                            <span>ID Card</span>
                                            <input
                                                type="radio"
                                                name="documentType"
                                                value="ID Card"
                                                checked={Document_review === "ID Card"}
                                                onChange={() => setDocumentType("ID Card")}
                                            />
                                        </div>
                                        <div
                                            className={`p-4 rounded-lg cursor-pointer flex items-center justify-between ${
                                                Document_review === "Passport"
                                                    ? "bg-gray-400 dark:bg-gray-700"
                                                    : "bg-gray-300 dark:bg-gray-800"
                                            }`}
                                            onClick={() => setDocumentType("Passport")}
                                        >
                                            <span>Passport</span>
                                            <input
                                                type="radio"
                                                name="documentType"
                                                value="Passport"
                                                checked={Document_review === "Passport"}
                                                onChange={() => setDocumentType("Passport")}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <button onClick={Section_Part_3}
                                        className="w-full bg-yellow-600 text-white py-2 rounded-lg">
                                    Continue
                                </button>
                            </div>
                        </div>


                    </div>
                )}

                {section_Part_w_1 === 3 && (
                    <div>
                        <div className="flex items-center space-x-2 text-black dark:text-white ">
                            <button onClick={Section_Part_2} className=" text-2xl">
                                <i className="fa-solid fa-arrow-left"></i>
                            </button>
                            <h1 className='text-2xl  p-5 rounded-[3px]'>Identity - Document </h1>
                        </div>


                        <div className=" flex items-center justify-center">
                            <div
                                className="bg-gray-200 text-black dark:text-white dark:bg-gray-900 p-8 rounded-lg shadow-lg w-full max-w-md">
                                <h2 className="text-2xl font-semibold  mb-6 text-center">
                                    Document Verification
                                </h2>
                                <div className="flex justify-center mb-6">
                                    <div className="relative">
                                        <div className="absolute inset-0  opacity-20"></div>
                                        <img
                                            src=""
                                            alt="ID card"
                                            className="relative z-10"
                                        />
                                    </div>
                                </div>
                                <ul className=" mb-6 space-y-2">
                                    <li>• Your documents must be of high quality and readable.</li>
                                    <li>• Your documents must be your own.</li>
                                    <li>• Make sure the identification documents are valid.</li>
                                    <li>• The background photo of the document must be fixed and one color.</li>
                                </ul>
                                <div className=" w-full">
                                    <button onClick={Section_Part_4}
                                            className="bg-yellow-500  w-full text-white py-2 px-4 rounded hover:bg-yellow-400">
                                        Continue
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                )}

                {section_Part_w_1 === 4 && (
                    <div>
                        <div className="flex items-center space-x-2 text-black dark:text-white ">
                            <button onClick={Section_Part_2} className=" text-2xl">
                                <i className="fa-solid fa-arrow-left"></i>
                            </button>
                            <h1 className='text-2xl  p-5 rounded-[3px]'>Identity - Upload  </h1>
                        </div>

                        <div className=" flex items-center justify-center">
                            <div
                                className="bg-gray-200 text-black dark:text-white dark:bg-gray-900  p-8 rounded-lg shadow-lg w-full max-w-md">
                                <h2 className="text-2xl font-semibold  mb-6 text-center">
                                    Upload photos
                                </h2>
                                <p className="mb-2">Upload your upload documents. </p>


                                <div className="space-y-2 mb-4">
                                    <label
                                        className="text-sm  font-medium leading-none"
                                        htmlFor="nationalId_1"
                                    >
                                        National ID:
                                    </label>
                                    <input
                                        className="flex  h-10 w-full rounded-md bg-gray-300 dark:text-white dark:bg-gray-800 px-3 py-2 text-sm placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-yellow-500"
                                        id="nationalId_1"
                                        placeholder="1234567890"
                                        required
                                    />
                                </div>

                                <div className="mb-6">
                                    {selectedImage_post_1 ? (
                                        <div className="relative">
                                            <div>
                                                <img
                                                    src={selectedImage_post_1}
                                                    alt="Preview"
                                                    className="w-full h-72   rounded-lg shadow-md"
                                                />
                                                <button
                                                    onClick={removeImage_post_1}
                                                    className="absolute top-2 right-2 bg-red-500 text-white px-2 py-1 rounded"
                                                >
                                                    Remove Image
                                                </button>
                                            </div>
                                        </div>

                                    ) : (
                                        <label className="border-2 border-dashed border-gray-600 p-4 rounded-lg w-full flex flex-col items-center justify-center text-gray-400 cursor-pointer hover:border-gray-500">
                                            <i  className="fa-solid fa-cloud-arrow-up text-5xl text-yellow-400"></i>
                                            <span className="text-center">Upload</span>
                                            <input
                                                type="file"
                                                className="hidden"
                                                accept="image/*"
                                                onChange={handleImageUpload_post_1}
                                            />
                                            <p className="text-sm text-gray-500 mt-1">
                                                Max 5 MB in .jpg/.jpeg/.png format
                                            </p>
                                        </label>
                                    )}
                                </div>

                                <button
                                    disabled={!isImageValid_post_1}
                                    className={`bg-yellow-500 text-white py-2 px-4 w-full rounded hover:bg-yellow-400 
                    ${!isImageValid_post_1 ? 'disabled:bg-gray-400 disabled:cursor-not-allowed' : ''}`}
                                    onClick={Section_Part_5}
                                >
                                    Next
                                </button>


                            </div>
                        </div>

                    </div>
                )}

                {section_Part_w_1 === 5 && (
                    <div>
                        <div className="flex items-center space-x-2 text-black dark:text-white ">
                            <button onClick={Section_Part_4} className=" text-2xl">
                                <i className="fa-solid fa-arrow-left"></i>
                            </button>
                            <h1 className='text-2xl  p-5 rounded-[3px]'> Identity - Information </h1>
                        </div>


                        <div className=" flex items-center justify-center ">
                            <div className="p-6 space-y-6   min-w-[400px] max-w-2xl w-full">
                                <div className="space-y-4 text-black dark:text-white">
                                    <div className="grid  grid-cols-2 gap-4">
                                        <div className="space-y-2">
                                            <label
                                                className="text-sm  font-medium leading-none"
                                                htmlFor="firstName"
                                            >
                                                First Name:
                                            </label>
                                            <input
                                                className="flex  h-10 w-full rounded-md  bg-gray-200 dark:bg-gray-800 px-3 py-2 text-sm placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-yellow-500"
                                                id="firstName"

                                                required
                                            />
                                        </div>

                                        <div className="space-y-2">
                                            <label
                                                className="text-sm  font-medium leading-none"
                                                htmlFor="lastName"
                                            >
                                                Last Name:
                                            </label>
                                            <input
                                                className="flex  h-10 w-full rounded-md bg-gray-200 dark:bg-gray-800 px-3 py-2 text-sm placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-yellow-500"
                                                id="lastName"

                                                required
                                            />
                                        </div>
                                    </div>


                                    <div className="space-y-2">
                                        <label
                                            className="text-sm  font-medium leading-none"
                                            htmlFor="address"
                                        >
                                            Address:
                                        </label>
                                        <textarea
                                            className="flex  w-full rounded-md  bg-gray-200 dark:bg-gray-800 px-3 py-2 text-sm placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-yellow-500 min-h-[100px]"
                                            id="address"

                                            required
                                        />
                                    </div>

                                    <div className="space-y-2">
                                        <label className="text-sm  font-medium leading-none" htmlFor="postalCode">
                                            Postal Code:
                                        </label>
                                        <input
                                            className="flex  h-10 w-full rounded-md bg-gray-200 dark:bg-gray-800 px-3 py-2 text-sm placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-yellow-500"
                                            id="postalCode"
                                            required/>
                                    </div>


                                    {section_Part_w_2 === 1 && (
                                        <button onClick={Section_Part_6}
                                                className="inline-flex text-black items-center justify-center whitespace-nowrap rounded-md text-sm font-medium bg-yellow-500 hover:bg-yellow-600 h-10 px-4 py-2 w-full"
                                                type="submit">
                                            Submit Request
                                        </button>

                                    )}

                                    {section_Part_w_2 === 2 && (
                                        <div>
                                            <button disabled
                                                    className="mt-6 mb-4 disabled loader_Page_Part_1 w-full bg-yellow-500 text-black py-2 rounded-lg font-semibold flex justify-center items-center not-allowed">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </button>
                                        </div>

                                    )}


                                </div>
                            </div>
                        </div>


                    </div>
                )}

                {section_Part_w_1 === 6 && (
                    <div>
                        <div className="flex items-center space-x-2 text-black dark:text-white ">
                            <Link to="/account/profile"  className=" text-2xl">
                                <i className="fa-solid fa-arrow-left"></i>
                            </Link>
                            <h1 className='text-2xl  p-5 rounded-[3px]'>Identity - waiting </h1>
                        </div>


                        <div className=" flex items-center justify-center">
                            <div
                                className="bg-gray-200 text-black dark:text-white dark:bg-gray-900 p-8 rounded-lg shadow-lg w-full max-w-md">
                                <h2 className="text-2xl font-semibold  mb-6 text-center">
                                    The Request Was Sent
                                </h2>
                                <div className="flex justify-center mb-6">
                                    <div className="relative">
                                        <div className="absolute inset-0  opacity-20"></div>
                                        <img
                                            src=""
                                            alt="ID card"
                                            className="relative z-10"
                                        />
                                    </div>
                                </div>

                                <p className="mt-2 mb-4">We are reviewing your request. And in 7 working days we will
                                    send
                                    the result via email</p>

                                <div className=" w-full">
                                    <button
                                        className="bg-yellow-500  w-full text-white py-2 px-4 rounded hover:bg-yellow-400">
                                        Contact support
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                )}

                {section_Part_w_1 === 7 && (
                    <div>
                        <div className="flex items-center space-x-2 text-black dark:text-white ">
                            <button onClick={Section_Part_2} className=" text-2xl">
                                <i className="fa-solid fa-arrow-left"></i>
                            </button>
                            <h1 className='text-2xl  p-5 rounded-[3px]'> Identity </h1>
                        </div>


                        <div className='flex items-center space-x-4 p-4 rounded-lg mt-1'>
                            <div className='w-20 h-20 rounded-full bg-gray-200 flex items-center justify-center'>
                                <i className='fa-solid fa-user text-yellow-500 text-5xl'></i>
                            </div>
                            <div>
                                <div id="Username_Data_1" className=' text-black dark:text-white font-semibold text-lg'>
                                    {profileData ? profileData.Username : 'Loading...'}
                                </div>
                                <div id="status_Account"
                                     dangerouslySetInnerHTML={{__html: profileData ? profileData.status_Account : ''}}>
                                </div>

                            </div>

                        </div>

                        <div
                            className="text-black bg-gray-100 dark:bg-gray-900 dark:text-white p-6 rounded-lg shadow-lg flex justify-between items-center border border-yellow-600">
                            <div>
                                <h3 className="text-lg font-bold">
                                    Your authentication has been successfully verified
                                </h3>
                                <p className="text-gray-400 mt-2 cursor-pointer">Request to change information</p>
                            </div>
                            <div>
                                <div
                                    className="bg-blue-800 text-white text-2xl p-4 rounded-full flex items-center justify-center">
                                    <i className="fa-solid fa-badge-check "></i>
                                </div>
                            </div>
                        </div>


                        <div
                            className=" mt-4 mx-auto p-6">

                            <div className="text-black dark:text-white  p-2  w-full">
                                <h2 className="text-xl font-semibold mb-4">Account Limits</h2>
                                <div className="space-y-3">
                                    <div className="flex justify-between items-center">
                                        <div className="flex items-center">
                                            <span className="mr-2">
                                                <i className="fa-duotone fa-solid fa-lock-open"></i>
                                            </span>
                                            <p>Unlimited withdrawals allowed</p>
                                        </div>
                                        <span className="text-green-500">allowed</span>
                                    </div>

                                    <div className="flex justify-between items-center">
                                        <div className="flex items-center">
                                            <span className="mr-2"> <i className="fa-duotone fa-solid fa-lock-open"></i></span>
                                            <p>Authorized to use the service</p>
                                        </div>
                                        <span className="text-green-500">allowed</span>
                                    </div>

                                    <div className="flex justify-between items-center">
                                        <div className="flex items-center">
                                            <span className="mr-2"> <i className="fa-duotone fa-solid fa-lock-open"></i></span>
                                            <p>Obtaining loans and financial support</p>
                                        </div>
                                        <span className="text-green-500">allowed</span>
                                    </div>

                                    <div className="flex justify-between items-center">
                                        <div className="flex items-center">
                                            <span className="mr-2"> <i className="fa-duotone fa-solid fa-lock-open"></i></span>
                                            <p>Wallet services</p>
                                        </div>
                                        <span className="text-green-500">allowed</span>
                                    </div>
                                    <div className="flex justify-between items-center">
                                        <div className="flex items-center">
                                            <span className="mr-2"> <i className="fa-duotone fa-solid fa-lock-open"></i></span>
                                            <p>No limit on daily transactions</p>
                                        </div>
                                        <span className="text-green-500">allowed</span>
                                    </div>
                                    <div className="flex justify-between items-center">
                                        <div className="flex items-center">
                                            <span className="mr-2"> <i className="fa-duotone fa-solid fa-lock-open"></i></span>
                                            <p>Investment in all sectors</p>
                                        </div>
                                        <span className="text-green-500">allowed</span>
                                    </div>
                                </div>
                            </div>


                        </div>


                    </div>
                )}

                {section_Part_w_1 === 8 && (
                    <div>
                        <div className="flex items-center space-x-2 text-black dark:text-white ">
                            <button onClick={Section_Part_2} className=" text-2xl">
                                <i className="fa-solid fa-arrow-left"></i>
                            </button>
                            <h1 className='text-2xl  p-5 rounded-[3px]'>Identity - Document </h1>
                        </div>


                        <div className=" flex items-center justify-center">
                            <div
                                className="bg-gray-200 text-black dark:text-white dark:bg-gray-900 p-8 rounded-lg shadow-lg w-full max-w-md">
                                <h2 className="text-2xl font-semibold  mb-6 text-center">
                                    Document Verification
                                </h2>
                                <div className="flex justify-center mb-6">
                                    <div className="relative">
                                        <div className="absolute inset-0  opacity-20"></div>
                                        <img
                                            src=""
                                            alt="ID card"
                                            className="relative z-10"
                                        />
                                    </div>
                                </div>
                                <ul className=" mb-6 space-y-2">
                                    <li>• Your documents must be of high quality and readable.</li>
                                    <li>• Your documents must be your own.</li>
                                    <li>• Make sure the identification documents are valid.</li>
                                    <li>• The background photo of the document must be fixed and one color.</li>
                                </ul>
                                <div className=" w-full">
                                    <button onClick={Section_Part_4}
                                            className="bg-yellow-500  w-full text-white py-2 px-4 rounded hover:bg-yellow-400">
                                        Continue
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                )}

                {section_Part_w_1 === 9  && (
                    <div>
                        <div className="flex items-center space-x-2 text-black dark:text-white ">
                            <button onClick={Section_Part_2} className=" text-2xl">
                                <i className="fa-solid fa-arrow-left"></i>
                            </button>
                            <h1 className='text-2xl  p-5 rounded-[3px]'>Identity - Document </h1>
                        </div>


                        <div className=" flex items-center justify-center">
                            <div
                                className="bg-gray-200 text-black dark:text-white dark:bg-gray-900 p-8 rounded-lg shadow-lg w-full max-w-md">
                                <h2 className="text-2xl font-semibold  mb-6 text-center">
                                    Document Verification driver
                                </h2>
                                <div className="flex justify-center mb-6">
                                    <div className="relative">
                                        <div className="absolute inset-0  opacity-20"></div>
                                        <img
                                            src=""
                                            alt="ID card"
                                            className="relative z-10"
                                        />
                                    </div>
                                </div>
                                <ul className=" mb-6 space-y-2">
                                    <li>• Your documents must be of high quality and readable.</li>
                                    <li>• Your documents must be your own.</li>
                                    <li>• Make sure the identification documents are valid.</li>
                                    <li>• The background photo of the document must be fixed and one color.</li>
                                </ul>
                                <div className=" w-full">
                                    <button onClick={Section_Part_4}
                                            className="bg-yellow-500  w-full text-white py-2 px-4 rounded hover:bg-yellow-400">
                                        Continue
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                )}

            </div>

        </div>
    );
}
export default ProfileIdentity;