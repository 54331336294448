import React, { useEffect } from 'react';
import {Link} from "react-router-dom";
import LoadingPage from "../Loading-Page";
import { Sign_out_account } from '../Information';

function HomePage() {
    useEffect(() => {
        setTimeout(() => {
            Load_Pages_Min();
        }, 100);
    }, []);



    return (
        <>
            <LoadingPage />
            <div className="p-2">
                <div id="Page">
                    <h1 className="text-2xl dark:text-white text-black p-5 rounded-[3px]">User Profile</h1>
                    <div className="w-full text-white ">
                        <ul className="divide-y divide-gray-900 text-gray-900 dark:text-gray-200">

                            <Link to="user">
                                <li className="p-4 flex justify-between items-center hover:bg-gray-300 dark:hover:bg-gray-700 rounded-[10px] cursor-pointer">
                                    <div className="flex items-center">
                                        <i className="fa-duotone fa-solid fa-user text-2xl"></i>
                                        <span className=" ml-3">User information</span>
                                    </div>
                                    <i className="fa-solid fa-chevron-up fa-rotate-90 text-2xl"></i>
                                </li>
                            </Link>
                            <Link to="identity">
                                <li className="p-4 flex justify-between items-center hover:bg-gray-300 dark:hover:bg-gray-700 rounded-[10px] cursor-pointer">
                                    <div className="flex items-center">
                                        <i className="fa-solid fa-face-viewfinder text-2xl"></i>
                                        <span className=" ml-3">Authentication</span>
                                    </div>
                                    <i className="fa-solid fa-chevron-up fa-rotate-90 text-2xl"></i>
                                </li>
                            </Link>
                            <Link to='security'>
                                <li className="p-4 flex justify-between items-center hover:bg-gray-300 dark:hover:bg-gray-700 rounded-[10px] cursor-pointer">
                                    <div className="flex items-center">
                                        <i className="fa-solid fa-shield-check text-2xl"></i>
                                        <span className=" ml-3">Security</span>
                                    </div>
                                    <i className="fa-solid fa-chevron-up fa-rotate-90 text-2xl"></i>
                                </li>
                            </Link>
                            <Link to='/account/profile/Notification' >
                                <li className="p-4 flex justify-between items-center hover:bg-gray-300 dark:hover:bg-gray-700 rounded-[10px] cursor-pointer">
                                    <div className="flex items-center">
                                        <i className="fa-solid fa-bell text-2xl"></i>

                                        <span className=" ml-3">Notification</span>
                                    </div>
                                    <i className="fa-solid fa-chevron-up fa-rotate-90 text-2xl"></i>
                                </li>
                            </Link>
                            <Link to="/account/profile/message" >
                                <li className="p-4 flex justify-between items-center hover:bg-gray-300 dark:hover:bg-gray-700 rounded-[10px] cursor-pointer">
                                    <div className="flex items-center">
                                        <i className="fa-solid fa-comment fa-flip-horizontal text-2xl"></i>
                                        <span className=" ml-3">Messages</span>
                                    </div>
                                    <i className="fa-solid fa-chevron-up fa-rotate-90 text-2xl"></i>
                                </li>
                            </Link>
                            <Link to="/account/profile/History" >
                                <li className="p-4 flex justify-between items-center hover:bg-gray-300 dark:hover:bg-gray-700 rounded-[10px] cursor-pointer">
                                    <div className="flex items-center">
                                        <i className="fa-duotone fa-solid fa-arrow-right-to-arc text-2xl"></i>
                                        <span className=" ml-3">Login history</span>
                                    </div>
                                    <i className="fa-solid fa-chevron-up fa-rotate-90 text-2xl"></i>

                                </li>
                            </Link>

                            <Link onClick={Sign_out_account}>
                                <li className="p-4 flex justify-between items-center hover:bg-gray-300 dark:hover:bg-gray-700 rounded-[10px] cursor-pointer">
                                    <div className="flex items-center">
                                        <i className="fa-solid fa-arrow-up-left-from-circle text-2xl"></i>
                                        <span className=" ml-3"></span><b className="text-red-700">Log out</b>
                                    </div>
                                    <i className="fa-solid fa-chevron-up fa-rotate-90 text-2xl"></i>

                                </li>
                            </Link>

                        </ul>
                    </div>
                </div>
                </div>

        </>
    );
}

function Load_Pages_Min() {


    const loaderElement = document.getElementById('Loading_Page');
    const pageElement = document.getElementById('Page');
    loaderElement.classList.add('hidden');
    pageElement.classList.remove('hidden');


}

export default HomePage;
