import React, { useState } from 'react';
import {Link} from "react-router-dom";

function Money() {

    const [activeTab, setActiveTab] = useState("openOrders");


    return (

        <div className='p-3'>


            <div className="flex items-center space-x-2 text-black dark:text-white ">
                <Link to="/account/wallet/index" className=" text-2xl">
                    <i className="fa-solid fa-arrow-left"></i>
                </Link>
                <h1 className='text-2xl p-5 rounded-[3px]'>   Money  </h1>
            </div>

            <div className="p-4">
                {/* Tabs Header */}
                <div className="flex space-x-4 border-b-2 border-gray-600 pb-2">
                    <button
                        onClick={() => setActiveTab("openOrders")}
                        className={`text-lg font-semibold ${
                            activeTab === "openOrders" ? "text-purple-500 border-b-2 border-purple-500" : "text-gray-300"
                        }`}
                    >
                        My active accounts
                    </button>
                    <button
                        onClick={() => setActiveTab("openPositions")}
                        className={`text-lg font-semibold ${
                            activeTab === "openPositions" ? "text-purple-500 border-b-2 border-purple-500" : "text-gray-300"
                        }`}
                    >
                        All accounts
                    </button>
                </div>

                {/* Tabs Content */}
                <div className="mt-4">
                    {activeTab === "openOrders" && (
                        <div className="text-white">
                            <div className="p-4 flex justify-center">
                                <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 w-full max-w-7xl">

                                    <div
                                        className="bg-green-500 rounded-lg p-6 flex flex-col justify-between items-center shadow-lg relative w-full h-48">
                                        <div className="absolute top-4 left-4 text-white text-lg font-semibold">Dollar
                                        </div>
                                        <div
                                            className="absolute top-4 right-4 bg-green-600 text-white w-10 h-10 rounded-full flex items-center justify-center text-xl">$
                                        </div>
                                        <h2 className="text-5xl font-bold text-white mt-8">$98,320<span
                                            className="text-2xl">.21</span><span className="text-lg">USD</span></h2>
                                        <div className="mt-4 flex gap-4">
                                            <button
                                                className="bg-white text-gray-800 px-5 py-2 rounded-full border border-white">Deposit
                                            </button>
                                            <Link to='/account/money/withdraw'
                                                className="bg-white text-gray-800 px-5 py-2 rounded-full border border-white">Withdraw
                                            </Link>
                                        </div>
                                    </div>

                                    <div
                                        className="bg-yellow-500 rounded-lg p-6 flex flex-col justify-between items-center shadow-lg relative w-full h-48">
                                        <div className="absolute top-4 left-4 text-white text-lg font-semibold">Dollar
                                        </div>
                                        <div
                                            className="absolute top-4 right-4 bg-yellow-600 text-white w-10 h-10 rounded-full flex items-center justify-center text-xl">$
                                        </div>
                                        <h2 className="text-5xl font-bold text-white mt-8">$98,320<span
                                            className="text-2xl">.21</span><span className="text-lg">USD</span></h2>
                                        <div className="mt-4 flex gap-4">
                                            <button
                                                className="bg-white text-gray-800 px-5 py-2 rounded-full border border-white">Deposit
                                            </button>
                                            <button
                                                className="bg-white text-gray-800 px-5 py-2 rounded-full border border-white">Withdraw
                                            </button>
                                        </div>
                                    </div>

                                    <div
                                        className="bg-red-500 rounded-lg p-6 flex flex-col justify-between items-center shadow-lg relative w-full h-48">
                                        <div className="absolute top-4 left-4 text-white text-lg font-semibold">Dollar
                                        </div>
                                        <div
                                            className="absolute top-4 right-4 bg-red-600 text-white w-10 h-10 rounded-full flex items-center justify-center text-xl">$
                                        </div>
                                        <h2 className="text-5xl font-bold text-white mt-8">$98,320<span
                                            className="text-2xl">.21</span><span className="text-lg">USD</span></h2>
                                        <div className="mt-4 flex gap-4">
                                            <button
                                                className="bg-white text-gray-800 px-5 py-2 rounded-full border border-white">Deposit
                                            </button>
                                            <button
                                                className="bg-white text-gray-800 px-5 py-2 rounded-full border border-white">Withdraw
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    )}
                    {activeTab === "openPositions" && (
                        <div className="text-white ">

                            <div>
                                <h2 className="text-gray-500 dark:text-gray-300 text-sm mb-2">Crypto List</h2>
                                <div className="w-full text-black dark:bg-gray-800 dark:text-gray-100 space-y-2">
                                    {/* Bitcoin */}
                                    <div
                                        className="p-4 border border-gray-300 dark:border-gray-700 rounded-lg flex items-center justify-between hover:shadow-md">
                                        <div className="flex items-center space-x-2">
                                            <img src="https://cryptologos.cc/logos/bitcoin-btc-logo.png" alt="icon"
                                                 className="w-8 h-8 rounded-full"/>
                                            <div>
                                                <span className="font-bold">BTC</span>
                                                <p className="text-sm text-gray-500 dark:text-gray-300">Bitcoin -
                                                    Bitcoin</p>
                                            </div>
                                        </div>
                                        <button
                                            className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600">
                                            ایجاد کیف پول
                                        </button>
                                    </div>

                                    {/* Tron */}
                                    <div
                                        className="p-4 border border-gray-300 dark:border-gray-700 rounded-lg flex items-center justify-between hover:shadow-md">
                                        <div className="flex items-center space-x-2">
                                            <img src="https://cryptologos.cc/logos/tron-trx-logo.png" alt="icon"
                                                 className="w-8 h-8 rounded-full"/>
                                            <div>
                                                <span className="font-bold">TRX</span>
                                                <p className="text-sm text-gray-500 dark:text-gray-300">Tron - TRC20</p>
                                            </div>
                                        </div>
                                        <button
                                            className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600">
                                            ایجاد کیف پول
                                        </button>
                                    </div>

                                    {/* Tether (Tron) */}
                                    <div
                                        className="p-4 border border-gray-300 dark:border-gray-700 rounded-lg flex items-center justify-between hover:shadow-md">
                                        <div className="flex items-center space-x-2">
                                            <img src="https://cryptologos.cc/logos/tether-usdt-logo.png?v=035"
                                                 alt="icon" className="w-8 h-8 rounded-full"/>
                                            <div>
                                                <span className="font-bold">USDT</span>
                                                <p className="text-sm text-gray-500 dark:text-gray-300">Tether -
                                                    Tron</p>
                                            </div>
                                        </div>
                                        <button
                                            className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600">
                                            ایجاد کیف پول
                                        </button>
                                    </div>

                                    {/* Dogecoin */}
                                    <div
                                        className="p-4 border border-gray-300 dark:border-gray-700 rounded-lg flex items-center justify-between hover:shadow-md">
                                        <div className="flex items-center space-x-2">
                                            <img src="https://cryptologos.cc/logos/dogecoin-doge-logo.png" alt="icon"
                                                 className="w-8 h-8 rounded-full"/>
                                            <div>
                                                <span className="font-bold">DOGE</span>
                                                <p className="text-sm text-gray-500 dark:text-gray-300">Dogecoin -
                                                    Doge</p>
                                            </div>
                                        </div>
                                        <button
                                            className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600">
                                            ایجاد کیف پول
                                        </button>
                                    </div>

                                    {/* Tether (Ethereum) */}
                                    <div
                                        className="p-4 border border-gray-300 dark:border-gray-700 rounded-lg flex items-center justify-between hover:shadow-md">
                                        <div className="flex items-center space-x-2">
                                            <img src="https://cryptologos.cc/logos/tether-usdt-logo.png?v=035"
                                                 alt="icon" className="w-8 h-8 rounded-full"/>
                                            <div>
                                                <span className="font-bold">USDT</span>
                                                <p className="text-sm text-gray-500 dark:text-gray-300">Tether -
                                                    Ethereum</p>
                                            </div>
                                        </div>
                                        <button
                                            className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600">
                                            Create
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    )}
                </div>
            </div>


        </div>


    );
}

export default Money;
