import React, {useEffect, useState} from 'react';
import {getCookie} from "../../Format/En/Cookie";
import {toast} from "react-toastify";
import LoadingPage from "../Loading-Page";
import {Link} from "react-router-dom";
import { QRCodeSVG } from 'qrcode.react';

const PAuthentication = () => {
    // Connect to run page
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    useEffect(() => {
        if (!isDataLoaded) {
            Load_Pages_Min().then(() => {
                setIsDataLoaded(true);
            });
        }
    }, [isDataLoaded]);
    //  END Connect to run page
    const [profileData, setProfileData] = useState(null);
    async function Load_Pages_Min() {
        const loaderElement = document.getElementById('Loading_Page');
        const pageElement = document.getElementById('Page');
        let meeting = getCookie("meeting");
        try {
            const requestData = {
                Session_ID:            meeting,
            };
            const response = await fetch(`${window.Web_Url_Api}/v1/Profile/Authentication/Information.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData)
            });
            if (!response.ok) {
                toast('Internet connection error');
                return;
            }
            pageElement.classList.remove('hidden');
            loaderElement.classList.add('hidden');
            const data = await response.json();
            setProfileData(data);
            if (data.Authenticator_status === 'ON'){
                section_Part_Re_w_1(2);
            }else{
                section_Part_Re_w_1(1);
            }
        } catch (error) {
            toast('An error has occurred');
        }
    }
    // Unitization
    const [section_Part_w_1, section_Part_Re_w_1] = useState(1);
    const [section_Part_w_2, section_Part_Re_w_2] = useState(1);
    const [section_Part_w_3, section_Part_Re_w_3] = useState(1);
    const [section_Part_w_4, section_Part_Re_w_4] = useState(1);
    // End Unitization
    const Section_Part_1 = () => {
        section_Part_Re_w_1(1);
    };
    const Section_Part_3 = () => {
        section_Part_Re_w_1(3);
    };
    // Qrcode
    const [qrcode, setQrcode]              = useState(null);
    const [Email_Qrcode, setEmail_Qrcode]  = useState(null);
    // End Qrcode
    const Section_Part_4 = () => {
        section_Part_Re_w_2(2);
        let meeting = getCookie("meeting");
        var data = {
            Session_ID:            meeting,
        };
        var actionURL = `${window.Web_Url_Api}/v1/Profile/Authentication/Authentication-add.php`;
        fetch(actionURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(data => {
                section_Part_Re_w_2(1);
                toast(data.message);
                if (data.status === 'true'){
                    setQrcode(data.Code);
                    window.Authentication_add_code  = data.Code
                    setEmail_Qrcode(data.Email);
                    section_Part_Re_w_1(4);
                }
            })
            .catch(() => {
                toast('An error has occurred');
                section_Part_Re_w_2(1);
            });
    };
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//
    const Section_Part_5 = () => {
        section_Part_Re_w_1(5);
    };
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -//
    const Section_Part_6 = () => {
        const Authenticator  = document.getElementById('Authenticator').value;
        let meeting = getCookie("meeting");

        if (/^\d{6}$/.test(Authenticator)) {
            section_Part_Re_w_3(2);
            var data = {
                Session_ID:            meeting,
                Code:                  window.Authentication_add_code,
                Authenticator:         Authenticator,
            };
            var actionURL = `${window.Web_Url_Api}/v1/Profile/Authentication/Authentication-New.php`;
            fetch(actionURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then(response => response.json())
                .then(data => {

                    toast(data.message);
                    if (data.status === 'true') {
                        section_Part_Re_w_1(2);
                        Window.Code_delete_account = data.Code
                    }
                    section_Part_Re_w_3(1);
                })
                .catch(() => {
                    toast('An error has occurred');
                    section_Part_Re_w_3(1);
                });

        }else {
            toast('The entered value is not correct');
        }

    };
    const Edit = () => {
        toast('To edit, delete the previous code');
    }
        const remove = () => {
        section_Part_Re_w_1(6);
    }
// -------------------------------------------------------------------------------------------------------------------//
    const Section_Part_7 = () => {
        var Authenticator_Remove = document.getElementById('Authenticator_Remove').value;
        let meeting = getCookie("meeting");
        if (/^\d{6}$/.test(Authenticator_Remove)) {
            section_Part_Re_w_4(2);
            const data = {
                Session_ID:            meeting,
                Authenticator:         Authenticator_Remove,
                Authenticator_Code:    profileData.Authenticator_Code,
                Authenticator_id:      profileData.Authenticator_id,
            };
            const actionURL = `${window.Web_Url_Api}/v1/Profile/Authentication/Authentication-Remove.php`;
            fetch(actionURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then(response => response.json())
                .then(data => {

                    toast(data.message);

                    if (data.status === 'true') {
                        section_Part_Re_w_1(1);
                    }

                    section_Part_Re_w_4(1);
                })
                .catch(() => {
                    toast('An error has occurred');
                    section_Part_Re_w_4(1);
                });

        }else {
            toast('The entered value is not correct');
        }
    }





    return (
        <div>
            <LoadingPage/>
            <div id="Page" className="hidden p-2">
                {section_Part_w_1 === 1 && (
                    <div>

                        <div className="flex items-center space-x-2 text-black dark:text-white ">
                            <Link to='/account/profile/security' className=" text-2xl">
                                <i className="fa-solid fa-arrow-left"></i>
                            </Link>
                            <h1 className='text-2xl  p-5 rounded-[3px]'> Authenticator  </h1>
                        </div>
                        <div className=' dark:text-white text-black p-6  '>
                            <div className='flex justify-center mb-4'>
                                <div className='bg-gray-700 p-4 rounded-full relative'>
                                    <i className='fas fa-comment-alt text-yellow-500 text-4xl'></i>
                                </div>
                            </div>
                            <h2 className='text-xl font-bold text-center mb-4'>
                                You can enter Authenticator for the security of your account
                            </h2>
                            <p className='text-center text-gray-400 mb-6'>
                                Authenticator can secure your account in word to account and withdrawals.An ID only for
                                Emorad
                            </p>
                            <div className='flex justify-center'>
                                <button onClick={Section_Part_3}
                                        className='bg-yellow-500 text-black font-bold py-2 px-4 rounded-lg hover:bg-yellow-600'>
                                    Add Authenticator
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {section_Part_w_1 === 2 && (
                    <div className=' text-white relative'>

                        <div className="flex items-center space-x-2 text-black dark:text-white ">
                            <Link to="/account/profile/security" className=" text-2xl">
                                <i className="fa-solid fa-arrow-left"></i>
                            </Link>
                            <h1 className='text-2xl  p-5 rounded-[3px]'>Authentication </h1>
                        </div>


                        <div
                            className='bg-gray-100 text-black dark:bg-gray-800 dark:text-white  flex items-center justify-between  p-4 rounded-lg'>
                            <div className='flex items-center space-x-3'>
                           <span className=' text-lg'>
                               <i className="fa-sharp-duotone fa-solid fa-shield-halved  text-4xl"></i>
                           </span>
                                <div>
                                <p className='font-medium'>Authenticator</p>
                                    <p className='text-gray-400 text-sm'>Added: {profileData.Time} </p>
                                </div>
                            </div>
                            <div className='flex space-x-3'>
                                <button onClick={Edit}
                                    className='text-gray-400 hover:text-black dark:text-gray-400 dark:hover:text-white'>
                                    <i className='fas fa-edit'></i>
                                </button>
                                <button onClick={remove}
                                    className='text-gray-400 hover:text-black dark:text-gray-400 dark:hover:text-white'>
                                    <i className='fas fa-trash-alt'></i>
                                </button>
                            </div>
                        </div>


                    </div>
                )}

                {section_Part_w_1 === 3 && (
                    <div>
                        <div className="flex items-center space-x-2 text-black dark:text-white ">
                            <button onClick={Section_Part_1} className=" text-2xl">
                                <i className="fa-solid fa-arrow-left"></i>
                            </button>
                            <h1 className='text-2xl  p-5 rounded-[3px]'>Authentication - Create  </h1>
                        </div>


                        <div className=" flex items-center justify-center">
                            <div
                                className="bg-gray-100 text-black dark:text-white dark:bg-gray-700 p-8 rounded-lg shadow-lg w-full max-w-md">
                                <h2 className="text-2xl font-semibold  mb-6 text-center">
                                    Create Authentication
                                </h2>
                                <div className="flex justify-center mb-6">
                                    <div className="relative">
                                        <div className="absolute inset-0  opacity-20"></div>
                                        <img
                                            src="https://i.pcmag.com/imagery/reviews/04syxCXYgOp2n7ORxuXtTVz-1.fit_scale.size_1028x578.v1726696178.jpg"
                                            alt="ID card"
                                            className="relative z-10"
                                        />
                                    </div>
                                </div>
                                <p className=" mb-1 space-y-2">1. Download the Authentication app</p>
                                <p className=" mb-1 space-y-2">2. Install the program</p>
                                <p className=" mb-1 space-y-2">3. Scan the code. to be added Security code</p>
                                <p className=" mb-1 space-y-2">4. Activate the authentication code after scanning</p>
                                <p className=" mb-1 space-y-2">5. The two-step system will be activated for you.</p>

                                {section_Part_w_2 === 1 && (

                                    <div className=" w-full mt-4">
                                        <button onClick={Section_Part_4} className="bg-yellow-500  w-full text-white py-2 px-4 rounded hover:bg-yellow-400">
                                            Next
                                        </button>
                                    </div>

                                )}

                                {section_Part_w_2 === 2 && (

                                    <div>
                                        <button disabled
                                                className="mt-6 mb-4 disabled loader_Page_Part_1 w-full bg-yellow-500 text-black py-2 rounded-lg font-semibold flex justify-center items-center not-allowed">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </button>
                                    </div>


                                )}

                            </div>
                        </div>

                    </div>
                )}


                {section_Part_w_1 === 4 && (
                    <div>
                        <div className="flex items-center space-x-2 text-black dark:text-white ">
                            <button onClick={Section_Part_3} className=" text-2xl">
                                <i className="fa-solid fa-arrow-left"></i>
                            </button>
                            <h1 className='text-2xl  p-5 rounded-[3px]'>Authentication - create  </h1>
                        </div>


                        <div className=" flex items-center justify-center">
                            <div
                                className="bg-gray-200 text-black dark:text-white dark:bg-gray-900 p-8 rounded-lg shadow-lg w-full max-w-md">
                                <h2 className="text-2xl font-semibold  mb-6 text-center">
                                    Scan the ID
                                </h2>
                                <div className="flex justify-center mb-6">
                                    <div className="relative">

                                        <div>
                                            {Email_Qrcode && qrcode ? (
                                                <QRCodeSVG
                                                    value={`otpauth://totp/${Email_Qrcode}?secret=${qrcode}&issuer=Emorad.com`}
                                                    size={256}
                                                    className="bg-black"
                                                    level="H"
                                                    includeMargin={true}
                                                />
                                            ) : null}
                                        </div>

                                        {Email_Qrcode && qrcode ? (
                                            <div className="mt-2 bg-gray-300 border rounded px-4 py-2 text-gray-600 flex items-center justify-center">
                                                {qrcode}
                                            </div>
                                        ) : null}

                                    </div>
                                </div>

                                <div className=" w-full">
                                <button onClick={Section_Part_5}
                                            className="bg-yellow-500  w-full text-white py-2 px-4 rounded hover:bg-yellow-400">
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                )}


                {section_Part_w_1 === 5 && (
                    <div>
                        <div className="flex items-center space-x-2 text-black dark:text-white ">
                            <button onClick={Section_Part_3} className=" text-2xl">
                                <i className="fa-solid fa-arrow-left"></i>
                            </button>
                            <h1 className='text-2xl  p-5 rounded-[3px]'>Authentication - Validation </h1>
                        </div>


                        <div className=" flex items-center justify-center">
                            <div
                                className="bg-gray-200 text-black dark:text-white dark:bg-gray-900 p-8 rounded-lg shadow-lg w-full max-w-md">
                                <h2 className="text-2xl font-semibold  mb-6 text-center">
                                    Validation
                                </h2>


                                <p className=" mb-1 space-y-2">
                                    When you scan the ID, you will receive a timed code. Enter it.
                                </p>

                                <div className="mb-4 mt-4">
                                    <label
                                        className="block text-gray-800 dark:text-gray-400 text-sm mb-2"
                                        htmlFor="Authenticator">
                                        Authenticator code
                                    </label>
                                    <input
                                        type="text"
                                        id="Authenticator"
                                        className="w-full p-2 rounded-lg dark:bg-gray-800 dark:text-white bg-gray-300 text-black focus:outline-none focus:border-white"
                                    />
                                </div>

                                {section_Part_w_3 === 2 && (
                                    <div>
                                        <button disabled className="mt-6 mb-4 disabled loader_Page_Part_1 w-full bg-yellow-500 text-black py-2 rounded-lg font-semibold flex justify-center items-center not-allowed">
                                            <div></div><div></div><div></div><div></div><div></div><div></div>
                                        </button>
                                    </div>
                                )}

                                {section_Part_w_3 === 1 && (
                                    <div className=" w-full">
                                        <button onClick={Section_Part_6}
                                                className="bg-yellow-500  w-full text-white py-2 px-4 rounded hover:bg-yellow-400">
                                            Register the code
                                        </button>
                                    </div>
                                )}


                            </div>
                        </div>

                    </div>
                )}



                {section_Part_w_1 === 6 && (
                    <div>
                        <div className="flex items-center space-x-2 text-black dark:text-white ">
                            <button onClick={Load_Pages_Min} className=" text-2xl">
                                <i className="fa-solid fa-arrow-left"></i>
                            </button>
                            <h1 className='text-2xl  p-5 rounded-[3px]'>Authentication - Remove </h1>
                        </div>


                        <div className=" flex items-center justify-center">
                            <div
                                className="bg-gray-200 text-black dark:text-white dark:bg-gray-900 p-8 rounded-lg shadow-lg w-full max-w-md">
                                <h2 className="text-2xl font-semibold  mb-6 text-center">
                                    Remove Authentication
                                </h2>


                                <p className=" mb-1 space-y-2">
                                    You can turn off authentication. By deleting it in two steps, your account will be deactivated. To reactivate, you must create a new code again.
                                    <br/><br/>
                                    If you are sure about this, enter the chance to disable it.
                                </p>

                                <div className="mb-4 mt-4">
                                    <label
                                        className="block text-gray-800 dark:text-gray-400 text-sm mb-2"
                                        htmlFor="Authenticator_Remove">
                                        Authenticator code
                                    </label>
                                    <input
                                        type="text"
                                        id="Authenticator_Remove"
                                        className="w-full p-2 rounded-lg dark:bg-gray-800 dark:text-white bg-gray-300 text-black focus:outline-none focus:border-white"
                                    />
                                </div>

                                {section_Part_w_4 === 2 && (
                                    <div>
                                        <button disabled className="mt-6 mb-4 disabled loader_Page_Part_1 w-full bg-yellow-500 text-black py-2 rounded-lg font-semibold flex justify-center items-center not-allowed">
                                            <div></div><div></div><div></div><div></div><div></div><div></div>
                                        </button>
                                    </div>
                                )}

                                {section_Part_w_4 === 1 && (
                                    <div className=" w-full">
                                        <button onClick={Section_Part_7} className="bg-yellow-500  w-full text-white py-2 px-4 rounded hover:bg-yellow-400">
                                            Remove Authentication
                                        </button>
                                    </div>
                                )}


                            </div>
                        </div>

                    </div>
                )}



            </div>


            <div>
                <br/><br/><br/>
            </div>

        </div>


    )
        ;
};

export default PAuthentication;
