import React from 'react';



function LoadingPage() {


    return (

        <div style={{height: 'calc(100vh - 150px)'}} id="Loading_Page"
             className="  flex items-center justify-center  centered">
            <div className="loader_Page flex">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>

    );
}

export default LoadingPage;
