import React, { useState, useEffect, useRef } from 'react'; // اضافه کردن useRef
import { Route, Routes, Link, useLocation} from 'react-router-dom';
import Logo_01_Png from '../Format/Picture/Logo/Logo-02.png';
import 'react-toastify/dist/ReactToastify.css';
// import './Format/Library/tailwindcss/output.css';
import  NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import '../Format/En/nprogress.css';
import '../Format/Library/fontawesome/css/all.css';

import Profile       from './Profile/index';
import ProfileUser  from './Profile/user';
import ProfileIdentity  from './Profile/identity';
import ProfileSecurity  from './Profile/security';
import ProfilesAuthentication   from './Profile/Authenticator';
import ProfileNotification  from './Profile/Notification';
import ProfileMessenger  from './Profile/message';
import ProfilesEmail    from './Profile/Security-Email';
import Profilespassword  from './Profile/Password';
import ProfileHistory  from './Profile/History';

import WalletIndex  from './wallet/index';
import WalletWithdraw  from './wallet/withdraw';
import Walletdeposit  from './wallet/deposit';
import WalletSwap  from './wallet/Swap';


import Money  from './money/index';
import Moneywithdraw  from './money/withdraw';
import Moneydeposit  from './money/deposit';



import {toast, ToastContainer} from "react-toastify";

import { Navigate } from 'react-router-dom';
import {getCookie, setCookie} from "../Format/En/Cookie";

import { Sign_out_account } from './Information';
function AccountLanding() {

    let email_cookie  = getCookie("email_cookie");
    let meeting       = getCookie("meeting");


    useEffect(() => {

        const isAllowed = localStorage.getItem("Root");

        let email_cookie  = getCookie("email_cookie");
        let meeting       = getCookie("meeting");


        if (!meeting){
            window.location.href = "/register/";
            return;
        }
        if (!email_cookie){
            email_cookies()
            return;
        }
        if (email_cookie){
            toast.success('Word was successful');

        }


    }, []);
    async function email_cookies() {
        let email_cookie = getCookie("email_cookie");
        var data = {
            email_cookie: email_cookie,
        };
        var actionURL = `${window.Web_Url_Api}/v1/License/Validity.php`;
        fetch(actionURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(data => {

                toast(data.message);

                if (data.status === "true") {

                    setCookie("email_cookie", `${data.Email}`, 7);
                    setCookie("meeting", `${data.Code}`, 7);



                } else {
                    window.location.href = "/register/";
                }
            })
            .catch(() => {
                toast.error('An error occurred!');
                localStorage.setItem("Allowed_request", "false");
                window.location.href = "/register/";
            });
    }

    // --------------------------------------------
    const [darkMode, setDarkMode] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        const savedTheme = localStorage.getItem('theme');
        if (savedTheme === 'dark') {
            setDarkMode(true);
            document.documentElement.classList.add('dark');
        } else {
            setDarkMode(false);
            document.documentElement.classList.remove('dark');
        }

        if (window.innerWidth >= 768) {
            setMenuOpen(true);
        } else {
            setMenuOpen(false);
        }
        const handleResize = () => {
            if (window.innerWidth >= 768) {
                setMenuOpen(true);
            } else {
                setMenuOpen(false);
            }
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const toggleTheme = () => {
        if (darkMode) {
            document.documentElement.classList.remove('dark');
            localStorage.setItem('theme', 'light');
            setDarkMode(false);
        } else {
            document.documentElement.classList.add('dark');
            localStorage.setItem('theme', 'dark');
            setDarkMode(true);
        }
    };
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);

        setleft_menu(false);
    };


    // const [menuOpen1, setMenuOpen1] = useState(false);
    //
    // const toggleMenu1 = () => {
    //     setMenuOpen1(!menuOpen1);
    // };
    const [isDropdownOpen, setIsDropdownOpen] = useState(false); // وضعیت باز بودن منو
    const menuRef = useRef(null); // ارجاع به منو

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen); // باز و بسته کردن منو
    };

    const closeDropdown = () => {
        setIsDropdownOpen(false); // بستن منو
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                closeDropdown(); // بستن منو در صورتی که کاربر خارج از آن کلیک کند
            }
        };

        // اضافه کردن event listener برای کلیک خارج از منو
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            // حذف event listener هنگام خروج از کامپوننت
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuRef]);



    // -------------
    const [isMessengerDropdownOpen, setIsMessengerDropdownOpen] = useState(false); // وضعیت باز بودن منوی پیام‌رسان
    const messengerMenuRef = useRef(null); // ارجاع به منوی پیام‌رسان

    const toggleMessengerDropdown = () => {
        setIsMessengerDropdownOpen(!isMessengerDropdownOpen); // باز و بسته کردن منوی پیام‌رسان
    };

    const closeMessengerDropdown = () => {
        setIsMessengerDropdownOpen(false); // بستن منوی پیام‌رسان
    };

    useEffect(() => {
        const handleClickOutsideMessenger = (event) => {
            if (messengerMenuRef.current && !messengerMenuRef.current.contains(event.target)) {
                closeMessengerDropdown(); // بستن منوی پیام‌رسان در صورتی که کاربر خارج از آن کلیک کند
            }
        };

        // اضافه کردن event listener برای کلیک خارج از منوی پیام‌رسان
        document.addEventListener('mousedown', handleClickOutsideMessenger);

        return () => {
            // حذف event listener هنگام خروج از کامپوننت
            document.removeEventListener('mousedown', handleClickOutsideMessenger);
        };
    }, [messengerMenuRef]);

    // ---------
    const [isNotificationDropdownOpen, setIsNotificationDropdownOpen] = useState(false); // وضعیت باز بودن منوی اعلان‌ها
    const notificationMenuRef = useRef(null); // ارجاع به منوی اعلان‌ها

    const toggleNotificationDropdown = () => {
        setIsNotificationDropdownOpen(!isNotificationDropdownOpen); // باز و بسته کردن منوی اعلان‌ها
    };

    const closeNotificationDropdown = () => {
        setIsNotificationDropdownOpen(false); // بستن منوی اعلان‌ها
    };

    useEffect(() => {
        const handleClickOutsideNotification = (event) => {
            if (notificationMenuRef.current && !notificationMenuRef.current.contains(event.target)) {
                closeNotificationDropdown();
            }
        };


        document.addEventListener('mousedown', handleClickOutsideNotification);

        return () => {
            document.removeEventListener('mousedown', handleClickOutsideNotification);
        };
    }, [notificationMenuRef]);


    // Meno left button
    const [openMenus, setOpenMenus] = useState({});
    const toggleSubmenu = (submenuId) => {
        setOpenMenus((prev) => ({
            ...prev,
            [submenuId]: !prev[submenuId],
        }));
    };
    // Top menu
    const toggleSubmenu_1 = (submenuId) => {

        setOpenMenus((prev) => ({
            ...prev,
            [submenuId]: !prev[submenuId],
        }));
    };
    // Show left menu
    const [left_menu, setleft_menu] = useState(false);

    const Show_left_menu = () => {
        setleft_menu((prevState) => !prevState);
    };

    return (

            <div>
            <ProgressBar />
            <div className=" right-0 left-0 bg-gradient-to-b from-white to-blue-50 dark:from-gray-900 dark:to-gray-800 font-sans ">
                <header className="bg-white dark:bg-gray-800 shadow-md p-4 flex justify-between items-center">

                    <div className="flex items-center space-x-4">
                        <button onClick={toggleMenu} className="md:hidden text-gray-800 dark:text-gray-200 text-xl">
                            <i className="fa-duotone fa-solid fa-bars text-2xl"></i>
                        </button>
                        <img src={Logo_01_Png} alt="CoinEx Logo" className="h-8"/>
                        <nav className="hidden md:flex space-x-6">


                            <button onClick={Show_left_menu}
                                  className="text-gray-800 dark:text-gray-200 hover:text-gray-900 dark:hover:text-white">
                                {left_menu ? (
                                    <i className="fa-solid fa-tally-4 text-xl text-yellow-500 rotate-90"></i>
                                ) : (
                                    <i className="fa-solid fa-tally-4 text-xl rotate-90"></i>
                                )}


                            </button>
                            <Link to="/account/profile"
                                  className="text-gray-800 dark:text-gray-200 hover:text-gray-900 dark:hover:text-white">
                                Dashboard
                            </Link>


                            {/*----*/}


                            <li
                                className="  relative flex items-center space-x-1 z-50"
                                onMouseEnter={() => toggleSubmenu_1(1)}
                                onMouseLeave={() => toggleSubmenu_1(1)}
                            >
                                <a
                                    className="text-slate-800 hover:text-slate-900"
                                    aria-expanded={openMenus[1]}
                                >
                                    Money
                                </a>
                                <button
                                    className="shrink-0 "
                                    aria-expanded={openMenus[1]}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        toggleSubmenu_1(1);
                                    }}
                                >

                                    <i
                                        className={`fa-solid rotate-90 fa-chevron-right transform transition-transform duration-300 ${
                                            openMenus[1] ? '-rotate-90' : ''
                                        }`}
                                    ></i>


                                </button>
                                {openMenus[1] && (
                                    <ul
                                        className="duration-300 origin-top-right absolute top-full left-1/2 -translate-x-1/2 min-w-[240px] bg-white border border-slate-200 p-2 rounded-lg shadow-xl"
                                    >
                                        <li>
                                            <Link to="/account/wallet/index"
                                                  className="text-slate-800 hover:bg-slate-50  flex items-center p-2">
                                                <i className="fa-solid fa-money-bill-trend-up mr-2 "></i>
                                                <span className="whitespace-nowrap">My Money</span>
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to="/account/wallet/index"
                                                  className="text-slate-800 hover:bg-slate-50  flex items-center p-2">
                                                <i className="fa-regular fa-money-simple-from-bracket mr-2"></i>
                                                <span className="whitespace-nowrap">Deposit</span>
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to="/account/wallet/index"
                                                  className="text-slate-800 hover:bg-slate-50  flex items-center p-2">
                                                <i className="fa-solid fa-money-bill-transfer mr-2"></i>
                                                <span className="whitespace-nowrap">Transfer</span>
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to="/account/wallet/index"
                                                  className="text-slate-800 hover:bg-slate-50  flex items-center p-2">
                                                <i className="fa-solid fa-money-bill-transfer mr-2"></i>
                                                <span className="whitespace-nowrap">History</span>
                                            </Link>
                                        </li>


                                    </ul>
                                )}
                            </li>


                            <li
                                className="  relative flex items-center space-x-1 z-50"
                                onMouseEnter={() => toggleSubmenu_1(2)}
                                onMouseLeave={() => toggleSubmenu_1(2)}
                            >
                                <a
                                    className="text-slate-800 hover:text-slate-900"
                                    aria-expanded={openMenus[2]}
                                >
                                    wallet
                                </a>
                                <button
                                    className="shrink-0 "
                                    aria-expanded={openMenus[2]}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        toggleSubmenu_1(2);
                                    }}
                                >

                                    <i
                                        className={`fa-solid rotate-90 fa-chevron-right transform transition-transform duration-300 ${
                                            openMenus[2] ? '-rotate-90' : ''
                                        }`}
                                    ></i>


                                </button>
                                {openMenus[2] && (
                                    <ul
                                        className="duration-300 origin-top-right absolute top-full left-1/2 -translate-x-1/2 min-w-[240px] bg-white border border-slate-200 p-2 rounded-lg shadow-xl"
                                    >
                                        <li>
                                            <Link to="/account/wallet/index"
                                                  className="text-slate-800 hover:bg-slate-50  flex items-center p-2">
                                                <i className="fa-solid fa-money-bill-trend-up mr-2 "></i>
                                                <span className="whitespace-nowrap">My Wallet</span>
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to="/account/wallet/index"
                                                  className="text-slate-800 hover:bg-slate-50  flex items-center p-2">
                                                <i className="fa-regular fa-money-simple-from-bracket mr-2"></i>
                                                <span className="whitespace-nowrap">Deposit</span>
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to="/account/wallet/index"
                                                  className="text-slate-800 hover:bg-slate-50  flex items-center p-2">
                                                <i className="fa-solid fa-money-bill-transfer mr-2"></i>
                                                <span className="whitespace-nowrap">Withdraw</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/account/wallet/index"
                                                  className="text-slate-800 hover:bg-slate-50  flex items-center p-2">
                                                <i className="fa-solid fa-money-bill-transfer mr-2"></i>
                                                <span className="whitespace-nowrap">Swap</span>
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to="/account/wallet/index"
                                                  className="text-slate-800 hover:bg-slate-50  flex items-center p-2">
                                                <i className="fa-solid fa-money-bill-transfer mr-2"></i>
                                                <span className="whitespace-nowrap">Deposit history</span>
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to="/account/wallet/index"
                                                  className="text-slate-800 hover:bg-slate-50  flex items-center p-2">
                                                <i className="fa-solid fa-money-bill-transfer mr-2"></i>
                                                <span className="whitespace-nowrap">Withdrawal history</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/account/wallet/index"
                                                  className="text-slate-800 hover:bg-slate-50  flex items-center p-2">
                                                <i className="fa-solid fa-money-bill-transfer mr-2"></i>
                                                <span className="whitespace-nowrap">Transfer history</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/account/wallet/index"
                                                  className="text-slate-800 hover:bg-slate-50  flex items-center p-2">
                                                <i className="fa-solid fa-money-bill-transfer mr-2"></i>
                                                <span className="whitespace-nowrap">Withdrawal history</span>
                                            </Link>
                                        </li>


                                    </ul>
                                )}
                            </li>


                            {/*----*/}


                            <Link to="/account/profile"
                                  className="text-gray-800 dark:text-gray-200 hover:text-gray-900 dark:hover:text-white">Registration</Link>
                            <Link to="/account/profile"
                                  className="text-gray-800 dark:text-gray-200 hover:text-gray-900 dark:hover:text-white">Login</Link>


                        </nav>
                    </div>


                    <div className="flex items-center space-x-4">


                    <Link className="md:hidden block" to="/account/profile">
                        <i className="fa-solid fa-bell text-xl" style={{color: '#F0B90B'}}></i>
                    </Link>
                        <Link className="md:hidden block" to="/account/profile">
                            <i className="fa-solid fa-envelope text-xl " style={{color: '#F0B90B'}}></i>
                        </Link>


                        <div className="relative md:inline-block text-left hidden " ref={notificationMenuRef}>

                            <div>
                                <button type="button" onClick={toggleNotificationDropdown}
                                        className="inline-flex justify-center">
                                    <i className="fa-solid fa-bell text-xl" style={{color: '#F0B90B'}}></i>
                                </button>
                            </div>


                            {isNotificationDropdownOpen && (
                                <div
                                    className="w-80 origin-top-right absolute right-0 mt-2 z-20 rounded-md shadow-lg bg-white dark:bg-gray-900 ring-1 ring-black ring-opacity-5">
                                    <div className=" p-2" role="menu" aria-orientation="vertical"
                                         aria-labelledby="options-menu">

                                        <div
                                            className="p-2 text-black dark:text-white relative hover:bg-gray-200 dark:hover:bg-gray-800 rounded-xl ">
                                            <div
                                                className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
                                                <div className="flex items-start flex-1"><i
                                                    className="fa-solid fa-envelope text-2xl text-blue-400 mr-3 mt-1"></i>
                                                    <div className="flex-1"><span
                                                        className="font-semibold text-lg block whitespace-nowrap overflow-hidden overflow-ellipsis">Registration-code</span><span
                                                        className="text-sm text-gray-400 sm:max-w-2xl max-w-[220px] block mt-1 truncate">Email: alireza.dansh.0.1@gmail.com</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="p-2 text-black dark:text-white relative hover:bg-gray-200 dark:hover:bg-gray-800 rounded-xl ">
                                            <div
                                                className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
                                                <div className="flex items-start flex-1"><i
                                                    className="fa-solid fa-envelope text-2xl text-blue-400 mr-3 mt-1"></i>
                                                    <div className="flex-1"><span
                                                        className="font-semibold text-lg block whitespace-nowrap overflow-hidden overflow-ellipsis">Registration-code</span><span
                                                        className="text-sm text-gray-400 sm:max-w-2xl max-w-[220px] block mt-1 truncate">Email: alireza.dansh.0.1@gmail.com</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="p-2 text-black dark:text-white relative hover:bg-gray-200 dark:hover:bg-gray-800 rounded-xl ">
                                            <div
                                                className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
                                                <div className="flex items-start flex-1"><i
                                                    className="fa-solid fa-envelope text-2xl text-blue-400 mr-3 mt-1"></i>
                                                    <div className="flex-1"><span
                                                        className="font-semibold text-lg block whitespace-nowrap overflow-hidden overflow-ellipsis">Registration-code</span><span
                                                        className="text-sm text-gray-400 sm:max-w-2xl max-w-[220px] block mt-1 truncate">Email: alireza.dansh.0.1@gmail.com</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="p-2 text-black dark:text-white relative hover:bg-gray-200 dark:hover:bg-gray-800 rounded-xl ">
                                            <div
                                                className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
                                                <div className="flex items-start flex-1"><i
                                                    className="fa-solid fa-envelope text-2xl text-blue-400 mr-3 mt-1"></i>
                                                    <div className="flex-1"><span
                                                        className="font-semibold text-lg block whitespace-nowrap overflow-hidden overflow-ellipsis">Registration-code</span><span
                                                        className="text-sm text-gray-400 sm:max-w-2xl max-w-[220px] block mt-1 truncate">Email: alireza.dansh.0.1@gmail.com</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="p-2 text-black dark:text-white relative hover:bg-gray-200 dark:hover:bg-gray-800 rounded-xl ">
                                            <div
                                                className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
                                                <div className="flex items-start flex-1"><i
                                                    className="fa-solid fa-envelope text-2xl text-blue-400 mr-3 mt-1"></i>
                                                    <div className="flex-1"><span
                                                        className="font-semibold text-lg block whitespace-nowrap overflow-hidden overflow-ellipsis">Registration-code</span><span
                                                        className="text-sm text-gray-400 sm:max-w-2xl max-w-[220px] block mt-1 truncate">Email: alireza.dansh.0.1@gmail.com</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <Link to='/account/profile' onClick={closeNotificationDropdown}
                                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                              role="menuitem">
                                            New Comment
                                        </Link>

                                    </div>
                                </div>
                            )}
                        </div>


                        <div className="relative md:inline-block text-left hidden" ref={messengerMenuRef}>

                            <div>
                                <button type="button" onClick={toggleMessengerDropdown}
                                        className="inline-flex justify-center   ">
                                    <i className="fa-solid fa-envelope text-xl " style={{color: '#F0B90B'}}></i>
                                </button>
                            </div>

                            {isMessengerDropdownOpen && (
                                <div
                                    className="origin-top-right absolute right-0 mt-2 w-[420px] z-20 rounded-md shadow-lg bg-white dark:bg-gray-900 dark:text-white ring-1 ring-black ring-opacity-5">
                                    <div className="py-1 p-2" role="menu" aria-orientation="vertical"
                                         aria-labelledby="options-menu">


                                        <div className="flex mb-2 items-center justify-between p-3 bg-gray-100 dark:bg-gray-800 rounded-lg shadow-sm">
                                            <div className="flex items-center space-x-3">
                                                <div className="bg-yellow-500 p-2 rounded-full">
                                                    <i className="fa-solid fa-messages"></i>
                                                </div>
                                                <span
                                                    className="text-gray-800 dark:text-white text-md font-medium ">11 new message(s)</span>
                                            </div>
                                            <a
                                                href="#"
                                                className="text-yellow-600 text-sm font-medium hover:underline"
                                            >
                                                Read All
                                            </a>
                                        </div>
                                        <div className="p-4 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-xl ">
                                            <Link to="/account/profile/Notification">
                                                <div className="flex items-center">
                                                    <span className="bg-green-400 h-3 w-3 rounded-full mr-2"></span>
                                                    <h3 className="font-semibold text-gray-800 dark:text-white truncate">Unrecognized
                                                        Log-in
                                                        Reminder</h3>
                                                </div>
                                                <div className="text-gray-600 dark:text-gray-200 mt-2">
                                                    <p className="line-clamp-2 overflow-hidden">
                                                        We noticed that your IP
                                                        address is not consistent with the last visit. Time: 2024-10-25
                                                        18:19:29 IP: 198.145.148.250...</p>

                                                </div>
                                            </Link>

                                        </div>


                                        <Link to="/account/profile" onClick={closeDropdown}
                                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                              role="menuitem">
                                            Upgrade to Premium
                                        </Link>
                                        <button onClick={Sign_out_account}
                                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                role="menuitem">
                                            Log out
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>


                        <div className="relative inline-block text-left" ref={menuRef}>

                            <div>
                                <button type="button" onClick={toggleDropdown}
                                        className="inline-flex justify-center   ">
                                    <i className="fa-solid fa-user-circle text-xl " style={{color: '#F0B90B'}}></i>
                                </button>
                            </div>


                            {isDropdownOpen && (
                                <div
                                    className="origin-top-right absolute right-0 mt-2 w-80 z-20 rounded-md shadow-lg bg-white dark:bg-gray-900 dark:text-white ring-1 ring-black ring-opacity-5">
                                    <div className="py-1 p-2" role="menu" aria-orientation="vertical"
                                         aria-labelledby="options-menu">


                                        <div className=" p-4 rounded-lg ">
                                            <div className="flex items-center mb-4">
                                                <div className="w-10 h-10 bg-gray-300 rounded-full mr-3">
                                                    <img src="https://www.coinex.com/assets/img/my/info/basic/avatar-default.svg?1" alt="User Avatar"
                                                         className="w-full h-full rounded-full"/>
                                                </div>
                                                <p className=" font-semibold">alireza2209</p>
                                            </div>
                                            <div className="flex items-center">
                                                 <span className="flex items-center px-2 py-1 text-xs text-gray-700 bg-gray-200 rounded-full">
                                                    VIP 0
                                                 </span>
                                                <span className="flex items-center ml-3 px-2 py-1 text-xs text-gray-500 bg-gray-100 border border-gray-300 rounded-full">
                                                    Unverified
                                                </span>
                                            </div>
                                        </div>


                                        <Link to="/account/wallet/index"
                                              className="rounded-xl hover:bg-slate-50 dark:hover:bg-gray-800  flex items-center p-2">
                                            <i className="fa-solid fa-money-bill-trend-up mr-2 text-xl "></i>
                                            <span className="whitespace-nowrap">My Profile</span>
                                        </Link>

                                        <Link to="/account/profile" onClick={closeDropdown}
                                              className="rounded-xl hover:bg-slate-50 dark:hover:bg-gray-800  flex items-center p-2">
                                            <i className="fa-solid fa-address-card mr-2 text-xl"></i>
                                            <span className="whitespace-nowrap">Information</span>
                                        </Link>
                                        <Link to="/account/profile" onClick={closeDropdown}
                                              className="rounded-xl hover:bg-slate-50 dark:hover:bg-gray-800  flex items-center p-2">
                                            <i className="fa-duotone fa-solid fa-shield-halved mr-2 text-xl"></i>
                                            <span className="whitespace-nowrap">security</span>
                                        </Link>
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                Sign_out_account();
                                            }}
                                            className="rounded-xl w-full hover:bg-slate-50 dark:hover:bg-gray-800  flex items-center p-2">
                                            <i className="fa-solid fa-right-from-bracket mr-2"></i>
                                            <span className="whitespace-nowrap text-red-500">Log out</span>
                                        </button>


                                    </div>
                                </div>
                            )}
                        </div>


                        <button onClick={toggleTheme} className="text-red-600  dark:text-red-100 ">
                            {darkMode ? (
                                <i className=" fa-solid fa-sun-bright  text-xl" style={{color: '#F0B90B'}}></i>
                            ) : (
                                <i className="fa-solid fa-moon text-xl" style={{color: '#F0B90B'}}></i>

                            )}
                        </button>

                    </div>
                </header>

                <div className="flex flex-col md:flex-row ">
                    {/* Sidebar */}
                    <aside
                        className={`fixed slide-in ${menuOpen ? 'show' : ''}  z-10 inset-0 bg-white dark:bg-gray-900 shadow-md p-6 md:p-2 md:relative md:w-1/5 main_Div_left_menu overflow-y-auto ${
                            darkMode ? 'div_Meno_Dark' : 'div_Meno_lite'
                        }
                        ${left_menu ? 'hidden ' : ''}
                         
                        
                        `}
                    >

                        <div className="flex justify-between items-center">

                            <div>
                                <img className="md:hidden w-[100px]"
                                     src="http://localhost:3000/static/media/Logo-02.5b2b86addea93a820747.png"
                                     alt="Emorad"/>
                            </div>

                            <button onClick={toggleMenu} className="md:hidden text-gray-800 dark:text-gray-200 text-xl">
                                <i className="fa-solid fa-xmark-large"></i>
                            </button>


                        </div>
                        <nav className="mt-1">


                            <ul>

                                <Link
                                    className="hover:bg-[#F5F5F5] dark:text-gray-200 dark:hover:bg-[#2B3139] flex items-center gap-2 rounded-md px-3 py-2 text-sm font-medium transition-colors hover:bg-muted hover:text-foreground dark:hover:bg-muted-dark dark:hover:text-foreground-dark"
                                    to="/account/wallet/index">
                                    <i className="fa-duotone fa-user"></i> Dashboard
                                </Link>


                                <li>
                                    <button
                                        className="w-full hover:bg-[#F5F5F5] dark:text-gray-200 dark:hover:bg-[#2B3139] flex items-center justify-between gap-2 rounded-md px-3 py-2 text-sm font-medium transition-colors hover:bg-muted hover:text-foreground dark:hover:bg-muted-dark dark:hover:text-foreground-dark"
                                        onClick={() => toggleSubmenu('submenu1')}
                                    >
                                        <div className="flex items-center gap-2">
                                            <i className="fa-solid fa-money-bill-simple-wave"></i> Money
                                        </div>
                                        <i
                                            className={`fa-solid fa-chevron-right transform transition-transform duration-300 ${
                                                openMenus['submenu1'] ? 'rotate-90' : ''
                                            }`}
                                        ></i>
                                    </button>


                                    <ul
                                        className={`submenu   space-y-2 transition-max-height ease duration-300 overflow-hidden ${
                                            openMenus['submenu1'] ? 'max-h-56' : 'max-h-0'
                                        }`}
                                    >
                                        <li>
                                            <Link
                                                className="hover:bg-[#F5F5F5] pl-8  dark:text-gray-200 dark:hover:bg-[#2B3139] flex items-center gap-2 rounded-md px-3 py-2 text-sm font-medium transition-colors hover:bg-muted hover:text-foreground dark:hover:bg-muted-dark dark:hover:text-foreground-dark"
                                                to="/account/money/index">
                                                My Mmney
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="hover:bg-[#F5F5F5] pl-8  dark:text-gray-200 dark:hover:bg-[#2B3139] flex items-center gap-2 rounded-md px-3 py-2 text-sm font-medium transition-colors hover:bg-muted hover:text-foreground dark:hover:bg-muted-dark dark:hover:text-foreground-dark"
                                                to="/account/money/deposit">
                                                Deposit
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="hover:bg-[#F5F5F5] pl-8  dark:text-gray-200 dark:hover:bg-[#2B3139] flex items-center gap-2 rounded-md px-3 py-2 text-sm font-medium transition-colors hover:bg-muted hover:text-foreground dark:hover:bg-muted-dark dark:hover:text-foreground-dark"
                                                to="/account/money/index">
                                                Transfer
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="hover:bg-[#F5F5F5] pl-8  dark:text-gray-200 dark:hover:bg-[#2B3139] flex items-center gap-2 rounded-md px-3 py-2 text-sm font-medium transition-colors hover:bg-muted hover:text-foreground dark:hover:bg-muted-dark dark:hover:text-foreground-dark"
                                                to="/account/money/index">
                                                History
                                            </Link>
                                        </li>
                                    </ul>
                                </li>


                                <li>
                                    <button
                                        className="w-full hover:bg-[#F5F5F5] dark:text-gray-200 dark:hover:bg-[#2B3139] flex items-center justify-between gap-2 rounded-md px-3 py-2 text-sm font-medium transition-colors hover:bg-muted hover:text-foreground dark:hover:bg-muted-dark dark:hover:text-foreground-dark"
                                        onClick={() => toggleSubmenu('submenu2')}
                                    >
                                        <div className="flex items-center gap-2">
                                            <i className="fa-duotone fa-solid fa-wallet"></i> wallet
                                        </div>
                                        <i
                                            className={`fa-solid fa-chevron-right transform transition-transform duration-300 ${
                                                openMenus['submenu2'] ? 'rotate-90' : ''
                                            }`}
                                        ></i>
                                    </button>


                                    <ul
                                        className={`submenu  space-y-2 transition-max-height ease duration-300 overflow-hidden ${
                                            openMenus['submenu2'] ? 'max-h-96' : 'max-h-0'
                                        }`}
                                    >
                                        <li>
                                            <Link
                                                className="hover:bg-[#F5F5F5] pl-8  dark:text-gray-200 dark:hover:bg-[#2B3139] flex items-center gap-2 rounded-md px-3 py-2 text-sm font-medium transition-colors hover:bg-muted hover:text-foreground dark:hover:bg-muted-dark dark:hover:text-foreground-dark"
                                                to="/account/money/index">
                                                My wallet
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="hover:bg-[#F5F5F5] pl-8  dark:text-gray-200 dark:hover:bg-[#2B3139] flex items-center gap-2 rounded-md px-3 py-2 text-sm font-medium transition-colors hover:bg-muted hover:text-foreground dark:hover:bg-muted-dark dark:hover:text-foreground-dark"
                                                to="/account/money/index">
                                                Deposit
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="hover:bg-[#F5F5F5] pl-8 dark:text-gray-200 dark:hover:bg-[#2B3139] flex items-center gap-2 rounded-md px-3 py-2 text-sm font-medium transition-colors hover:bg-muted hover:text-foreground dark:hover:bg-muted-dark dark:hover:text-foreground-dark"
                                                to="/account/money/index">
                                                Withdraw
                                            </Link>
                                        </li>

                                        <li>
                                            <Link
                                                className="hover:bg-[#F5F5F5] pl-8 dark:text-gray-200 dark:hover:bg-[#2B3139] flex items-center gap-2 rounded-md px-3 py-2 text-sm font-medium transition-colors hover:bg-muted hover:text-foreground dark:hover:bg-muted-dark dark:hover:text-foreground-dark"
                                                to="/account/money/index">
                                                Swap
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="hover:bg-[#F5F5F5] pl-8 dark:text-gray-200 dark:hover:bg-[#2B3139] flex items-center gap-2 rounded-md px-3 py-2 text-sm font-medium transition-colors hover:bg-muted hover:text-foreground dark:hover:bg-muted-dark dark:hover:text-foreground-dark"
                                                to="/account/money/index">
                                                Deposit history
                                            </Link>
                                        </li>

                                        <li>
                                            <Link
                                                className="hover:bg-[#F5F5F5] pl-8 dark:text-gray-200 dark:hover:bg-[#2B3139] flex items-center gap-2 rounded-md px-3 py-2 text-sm font-medium transition-colors hover:bg-muted hover:text-foreground dark:hover:bg-muted-dark dark:hover:text-foreground-dark"
                                                to="/account/money/index">
                                                Withdrawal history
                                            </Link>
                                        </li>

                                        <li>
                                            <Link
                                                className="hover:bg-[#F5F5F5] pl-8 dark:text-gray-200 dark:hover:bg-[#2B3139] flex items-center gap-2 rounded-md px-3 py-2 text-sm font-medium transition-colors hover:bg-muted hover:text-foreground dark:hover:bg-muted-dark dark:hover:text-foreground-dark"
                                                to="/account/money/index">
                                                Transfer history
                                            </Link>
                                        </li>

                                        <li>
                                            <Link
                                                className="hover:bg-[#F5F5F5] pl-8 dark:text-gray-200 dark:hover:bg-[#2B3139] flex items-center gap-2 rounded-md px-3 py-2 text-sm font-medium transition-colors hover:bg-muted hover:text-foreground dark:hover:bg-muted-dark dark:hover:text-foreground-dark"
                                                to="/account/money/index">
                                                Swap History
                                            </Link>
                                        </li>
                                    </ul>
                                </li>


                                <li>
                                    <button
                                        className="w-full hover:bg-[#F5F5F5] dark:text-gray-200 dark:hover:bg-[#2B3139] flex items-center justify-between gap-2 rounded-md px-3 py-2 text-sm font-medium transition-colors hover:bg-muted hover:text-foreground dark:hover:bg-muted-dark dark:hover:text-foreground-dark"
                                        onClick={() => toggleSubmenu('submenu3')}
                                    >
                                        <div className="flex items-center gap-2">
                                            <i className="fa-sharp-duotone fa-solid fa-address-card"></i> Profile
                                        </div>
                                        <i
                                            className={`fa-solid fa-chevron-right transform transition-transform duration-300 ${
                                                openMenus['submenu3'] ? 'rotate-90' : ''
                                            }`}
                                        ></i>
                                    </button>


                                    <ul
                                        className={`submenu  space-y-2 transition-max-height ease duration-300 overflow-hidden ${
                                            openMenus['submenu3'] ? 'max-h-96' : 'max-h-0'
                                        }`}
                                    >

                                        <li>
                                            <Link
                                                className="hover:bg-[#F5F5F5] pl-8  dark:text-gray-200 dark:hover:bg-[#2B3139] flex items-center gap-2 rounded-md px-3 py-2 text-sm font-medium transition-colors hover:bg-muted hover:text-foreground dark:hover:bg-muted-dark dark:hover:text-foreground-dark"
                                                to="/account/profile/">
                                                My Profile
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="hover:bg-[#F5F5F5] pl-8  dark:text-gray-200 dark:hover:bg-[#2B3139] flex items-center gap-2 rounded-md px-3 py-2 text-sm font-medium transition-colors hover:bg-muted hover:text-foreground dark:hover:bg-muted-dark dark:hover:text-foreground-dark"
                                                to="/account/profile/user">
                                                Information
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="hover:bg-[#F5F5F5] pl-8  dark:text-gray-200 dark:hover:bg-[#2B3139] flex items-center gap-2 rounded-md px-3 py-2 text-sm font-medium transition-colors hover:bg-muted hover:text-foreground dark:hover:bg-muted-dark dark:hover:text-foreground-dark"
                                                to="/account/profile/identity">
                                                identity
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="hover:bg-[#F5F5F5] pl-8 dark:text-gray-200 dark:hover:bg-[#2B3139] flex items-center gap-2 rounded-md px-3 py-2 text-sm font-medium transition-colors hover:bg-muted hover:text-foreground dark:hover:bg-muted-dark dark:hover:text-foreground-dark"
                                                to="/account/profile/security">
                                                security
                                            </Link>
                                        </li>

                                        <li>
                                            <Link
                                                className="hover:bg-[#F5F5F5] pl-8 dark:text-gray-200 dark:hover:bg-[#2B3139] flex items-center gap-2 rounded-md px-3 py-2 text-sm font-medium transition-colors hover:bg-muted hover:text-foreground dark:hover:bg-muted-dark dark:hover:text-foreground-dark"
                                                to="/account/profile/Notification">
                                                Notification
                                            </Link>
                                        </li>

                                        <li>
                                            <Link
                                                className="hover:bg-[#F5F5F5] pl-8 dark:text-gray-200 dark:hover:bg-[#2B3139] flex items-center gap-2 rounded-md px-3 py-2 text-sm font-medium transition-colors hover:bg-muted hover:text-foreground dark:hover:bg-muted-dark dark:hover:text-foreground-dark"
                                                to="/account/profile/message">
                                                Message
                                            </Link>
                                        </li>

                                        <li>
                                            <Link
                                                className="hover:bg-[#F5F5F5] pl-8 dark:text-gray-200 dark:hover:bg-[#2B3139] flex items-center gap-2 rounded-md px-3 py-2 text-sm font-medium transition-colors hover:bg-muted hover:text-foreground dark:hover:bg-muted-dark dark:hover:text-foreground-dark"
                                                to="/account/profile/History">
                                                History
                                            </Link>
                                        </li>

                                    </ul>
                                </li>

                                <hr className="mt-1"/>
                                <Link to="/account/profile/message"
                                      className="hover:bg-[#F5F5F5] mt-1 dark:text-gray-200 dark:hover:bg-[#2B3139] flex items-center gap-2 rounded-md px-3 py-2 text-sm font-medium transition-colors hover:bg-muted hover:text-foreground dark:hover:bg-muted-dark dark:hover:text-foreground-dark">
                                    <i className="fa-solid fa-arrow-right-from-bracket"></i> <b className="text-red-500">Log out</b>
                                </Link>


                            </ul>
                        </nav>
                    </aside>


                    <main className="w-full  overflow-auto  main_test_stert ">


                        <Routes>

                            <Route path="/" element={<Navigate to="Dashboard"/>}/>


                            <Route path="profile" element={<Profile/>}/>
                            <Route path="profile/user" element={<ProfileUser/>}/>
                            <Route path="profile/identity" element={<ProfileIdentity/>}/>
                            <Route path="profile/security" element={<ProfileSecurity/>}/>
                            <Route path="profile/Notification" element={<ProfileNotification/>}/>
                            <Route path="profile/Authentication" element={<ProfilesAuthentication/>}/>
                            <Route path="profile/Security-Email" element={<ProfilesEmail/>}/>
                            <Route path="profile/message" element={<ProfileMessenger/>}/>
                            <Route path="profile/password" element={<Profilespassword/>}/>
                            <Route path="profile/History" element={<ProfileHistory/>}/>

                            <Route path="wallet/index" element={<WalletIndex/>}/>
                            <Route path="wallet/withdraw" element={<WalletWithdraw/>}/>
                            <Route path="wallet/deposit" element={<Walletdeposit/>}/>
                            <Route path="wallet/Swap" element={<WalletSwap/>}/>


                            <Route path="money/index" element={<Money/>}/>
                            <Route path="money/withdraw/:symbol" element={<Moneywithdraw/>}/>
                            <Route path="money/withdraw" element={<Moneywithdraw/>}/>

                            <Route path="money/deposit" element={<Moneydeposit/>}/>


                        </Routes>

                        <ToastContainer
                            position="bottom-center"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme={darkMode ? 'dark' : 'light'}
                        />

                    </main>
                </div>

                {/* Bottom Navigation for Mobile */}
                <div className="fixed bottom-0 left-0 right-0 bg-white dark:bg-gray-800 shadow-lg p-4 md:hidden ">
                    <div className="flex justify-between mb-6">
                        <Link to="/account/profile"
                              className="flex flex-col items-center text-gray-600 dark:text-gray-400 hover:text-blue-500">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M3 12h18m-9-9v18"/>
                            </svg>
                            <span className="text-sm">Home</span>
                        </Link>
                        <Link to="/account/profile"
                              className="flex flex-col items-center text-gray-600 dark:text-gray-400 hover:text-blue-500">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M12 8c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0 8c2.485 0 4.5 2.015 4.5 4.5S14.485 21 12 21s-4.5-2.015-4.5-4.5S9.515 16 12 16z"/>
                            </svg>
                            <span className="text-sm">Wallet</span>
                        </Link>
                        <Link to="/account/wallet/index"
                              className="flex flex-col items-center text-gray-600 dark:text-gray-400 hover:text-yellow-500">
                            <i className="fa-duotone fa-solid fa-wallet text-2xl"></i>
                            <span className="text-sm"><b>Wallet</b></span>
                        </Link>
                        <Link to="/account/profile/"
                              className="flex flex-col items-center text-gray-600 dark:text-gray-400 hover:text-yellow-500">
                            <i className="fa-duotone fa-solid fa-user text-2xl"></i>
                            <span className="text-sm"><b>Profile</b></span>
                        </Link>
                    </div>
                </div>
            </div>
            </div>
    );
}

function ProgressBar() {
    const location = useLocation();
    useEffect(() => {
        NProgress.start();
        NProgress.done();
        return () => {
            NProgress.done();
        };
    }, [location]);
    return null;
}


export default AccountLanding;
