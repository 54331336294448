import React, {useEffect, useState} from 'react';
import {getCookie} from "../../Format/En/Cookie";
import {toast} from "react-toastify";
import LoadingPage from "../Loading-Page";
import {Link} from "react-router-dom";

const Profile_password = () => {
    // Connect to run page
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    useEffect(() => {
        if (!isDataLoaded) {
            Load_Pages_Min().then(() => {
                setIsDataLoaded(true);
            });
        }
    }, [isDataLoaded]);
    //  END Connect to run page
    async function Load_Pages_Min() {
        const loaderElement = document.getElementById('Loading_Page');
        const pageElement = document.getElementById('Page');
        pageElement.classList.remove('hidden');
        loaderElement.classList.add('hidden');
    }
//--------------------------------------------------------------------------------------------------------------------//
    const [section_Part_w_1, section_Part_Re_w_1] = useState(1);
//--------------------------------------------------------------------------------------------------------------------//
    const Section_Part_1 = () => {

        const old_password      = document.getElementById('old_password').value;
        const New_Password      = document.getElementById('New_Password').value;
        const confirm_password  = document.getElementById('confirm_password').value;

        if (old_password !== '111' && New_Password !== '' &&  confirm_password !== ''){
            if (New_Password === confirm_password ){

                section_Part_Re_w_1(2);

                let meeting = getCookie("meeting");
                const data = {
                    Session_ID:            meeting,
                    old_password:              old_password,
                    New_Password:              New_Password,
                    confirm_password:          confirm_password,
                };

                const actionURL = `${window.Web_Url_Api}/v1/Profile/Password/Change.php`;

                fetch(actionURL, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                })
                    .then(response => response.json())
                    .then(data => {
                        section_Part_Re_w_1(1);
                        toast(data.message);
                    })
                    .catch(() => {
                        toast('An error has occurred');
                    });

            }else {toast('The new password and its repetition are not the same');}
        }else {toast('Please complete all items.');}

    }





    return (
        <div>
            <LoadingPage/>
            <div id="Page" className="hidden p-2">

                <div className="flex items-center space-x-2 text-black dark:text-white ">
                    <Link to="/account/profile/security" className=" text-2xl">
                        <i className="fa-solid fa-arrow-left"></i>
                    </Link>
                    <h1 className='text-2xl  p-5 rounded-[3px]'>Password </h1>

                </div>


                <div className=" p-6 flex justify-center items-center">
                    <div className="dark:text-white text-black p-6 rounded-lg w-full max-w-3xl">

                        <div className="bg-yellow-700 text-yellow-200 p-4 rounded mb-6 text-sm">
                            <i className="fas fa-exclamation-circle mr-2"></i>
                            In order to protect your account, withdrawals from your account are blocked for 24 hours.
                            And some services are limited
                        </div>

                        <div className="mb-4">
                            <label className="block  text-sm mb-2" htmlFor="old_password">Old password</label>
                            <div className="relative">
                                <input id="old_password" type="password" className="bg-gray-200 text-black dark:bg-gray-700 dark:text-white p-3 rounded w-full placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-yellow-600"/>

                            </div>

                        </div>

                        <div className="mb-4">
                            <label className="block text-sm mb-2" htmlFor="New_Password">New Password</label>
                            <div className="relative">
                                <input id="New_Password" type="password" className="bg-gray-200 text-black dark:bg-gray-700 dark:text-white p-3 rounded w-full placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-yellow-600"/>

                            </div>
                        </div>

                        <div className="mb-6">
                            <label className="block  text-sm mb-2" htmlFor="confirm_password">Confirm
                                Password</label>
                            <div className="relative">
                                <input id="confirm_password" type="password" className="bg-gray-200 text-black dark:bg-gray-700 dark:text-white p-3 rounded w-full placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-yellow-600"/>
                            </div>
                        </div>

                        {section_Part_w_1 === 1 && (
                            <button onClick={Section_Part_1} className="bg-yellow-600 text-white py-3 w-full rounded hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-opacity-50">
                                Change Password
                            </button>
                        )}


                        {section_Part_w_1 === 2 && (
                            <button disabled className="bg-yellow-600 text-white py-3 w-full rounded hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-opacity-50">
                                <div className="flex items-center justify-center  centered">
                                    <div className="loader_Page_Part_1 flex "><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                </div>
                            </button>
                        )}

                    </div>
                </div>


            </div>


            <div>
                <br/><br/><br/><br/>
            </div>

        </div>


    );
};

export default Profile_password;
