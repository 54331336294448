import React, {useEffect, useState , useRef } from 'react';
import {getCookie} from "../../Format/En/Cookie";
import {toast} from "react-toastify";
import LoadingPage from "../Loading-Page";
import {Link} from "react-router-dom";

const Profile_Security_Email = () => {
    // Connect to run page
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    useEffect(() => {
        if (!isDataLoaded) {
            Load_Pages_Min().then(() => {
                setIsDataLoaded(true);
            });
        }
    }, [isDataLoaded]);
    //  END Connect to run page

    const [profileData, setProfileData] = useState(null);

    async function Load_Pages_Min() {

        const loaderElement = document.getElementById('Loading_Page');
        const pageElement = document.getElementById('Page');

        // loaderElement.classList.add('hidden');

        let meeting = getCookie("meeting");


        try {
            const requestData = {
                Session_ID:            meeting,
            };
            const response = await fetch(`${window.Web_Url_Api}/v1/Profile/Security-Email/Information.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData)
            });

            if (!response.ok) {
                toast('Internet connection error');
                return;
            }

            pageElement.classList.remove('hidden');
            loaderElement.classList.add('hidden');


            const data = await response.json();
            setProfileData(data);


            if (data.Two_step_Email === 'ON'){
                iphoneToggleRef.current.classList.remove('bg-gray-400');
                iphoneToggleRef.current.classList.add('bg-yellow-500');
                iphoneToggleRef.current.querySelector('div').classList.add('translate-x-8');

                setIsOn(true);

            }else {

                iphoneToggleRef.current.classList.remove('bg-yellow-500');
                iphoneToggleRef.current.classList.add('bg-gray-400');
                iphoneToggleRef.current.querySelector('div').classList.remove('translate-x-8');
                setIsOn(false);
            }

        } catch (error) {
            toast('An error has occurred');
        }
    }




    // -----------

    const [isOn, setIsOn] = useState(false);
    const iphoneToggleRef = useRef(null);

    const handleToggle = () => {
        setIsOn(!isOn);

        if (iphoneToggleRef.current) {
            if (!isOn) {
                iphoneToggleRef.current.classList.remove('bg-gray-400');
                iphoneToggleRef.current.classList.add('bg-yellow-500');
                iphoneToggleRef.current.querySelector('div').classList.add('translate-x-8');
                window.isOn_status_Mail = true
            } else {
                iphoneToggleRef.current.classList.remove('bg-yellow-500');
                iphoneToggleRef.current.classList.add('bg-gray-400');
                iphoneToggleRef.current.querySelector('div').classList.remove('translate-x-8');
                window.isOn_status_Mail = false
            }
        }

// -------------------------------------------------------------------------------------------------------------------//
        let meeting = getCookie("meeting");


        var data = {
            Session_ID:            meeting,
            status_Mail:           window.isOn_status_Mail,

        };


        console.log()

        var actionURL = `${window.Web_Url_Api}/v1/Profile/Security-Email/Change-status.php`;

        fetch(actionURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(data => {

                toast(data.message);

            })
            .catch(() => {
                toast('An error has occurred');
            });

// -------------------------------------------------------------------------------------------------------------------//



    };




    return (
        <div>
            <LoadingPage/>
            <div id="Page" className="hidden p-2">

                <div className="flex items-center space-x-2 text-black dark:text-white ">
                    <Link to="/account/profile/security" className=" text-2xl">
                        <i className="fa-solid fa-arrow-left"></i>
                    </Link>
                    <h1 className='text-2xl  p-5 rounded-[3px]'>Security </h1>

                </div>


                {/*    -------------*/}


                <div className="bg-gray-100 dark:bg-gray-800 dark:text-white  rounded-lg shadow-md w-full">

                    <div className="flex items-center justify-between  p-4 rounded-lg">
                        <div className="flex items-center">

                            <i className="fa-solid fa-envelope text-3xl mr-4"></i>
                            <div>
                                {profileData?.Email && <p className=" font-medium">{profileData.Email}</p>}
                                <p className="text-gray-400 text-sm">Send a 6-digit code</p>
                            </div>
                        </div>
                        <div className='flex space-x-3'>
                            <button className='text-gray-400 hover:text-black dark:hover:text-white'>
                                <p>Off</p>
                            </button>
                            <button onClick={handleToggle} className='text-gray-400 hover:text-white'>
                                <div
                                    id="Two_step_email"
                                    ref={iphoneToggleRef}
                                    className="relative w-16 h-8 bg-gray-400 rounded-full cursor-pointer transition-colors duration-300"
                                >
                                    <div
                                        className="absolute top-1 left-1 w-6 h-6 bg-white rounded-full shadow-md transition-transform duration-300"
                                    ></div>
                                </div>
                            </button>
                            <button className='text-gray-400 hover:text-black dark:hover:text-white'>
                                <p>On</p>
                            </button>
                        </div>
                    </div>
                </div>


                {/*    -------------*/}


            </div>


            <div>
                <br/><br/><br/>
            </div>

        </div>


    );
};

export default Profile_Security_Email;
