import React, { useState, useEffect, useRef } from 'react';
import { getCookie } from "../../Format/En/Cookie";
import { toast } from "react-toastify";
import LoadingPage from "../Loading-Page";
import {Link} from "react-router-dom";

function SwapComponent() {
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [fromToken, setFromToken] = useState(null);
    const [toToken, setToToken] = useState(null);
    const [fromAmount, setFromAmount] = useState('');
    const [toAmount, setToAmount] = useState('');
    const [isFromTokenListOpen, setIsFromTokenListOpen] = useState(false);
    const [isToTokenListOpen, setIsToTokenListOpen] = useState(false);
    const [fromSearchTerm, setFromSearchTerm] = useState('');
    const [toSearchTerm, setToSearchTerm] = useState('');
    const [insufficientBalance, setInsufficientBalance] = useState(false);
    const fromDropdownRef = useRef(null);
    const toDropdownRef = useRef(null);
    const [tokens, setTokens] = useState([]);
    const [selectedFromToken, setSelectedFromToken] = useState(null);
    const [selectedToToken, setSelectedToToken] = useState(null);
    const [userSelectedTokens, setUserSelectedTokens] = useState({ from: false, to: false });

    const [section_Part_w_1, section_Part_Re_w_1] = useState(1);
    const [section_Part_w_2, section_Part_Re_w_2] = useState(1);


    useEffect(() => {
        if (!isDataLoaded) {
            Load_Pages_Min().then(() => {
                setIsDataLoaded(true);
            });
        }
    }, [isDataLoaded]);

    async function Load_Pages_Min() {
        Section_Part_4()

        const urlSender = new URLSearchParams(window.location.search);
        const NewSender = urlSender.get('Sender') || "BTC";
        const NewSenderNetwork = urlSender.get('SenderNetwork') || "Ethereum";
        const urlRecipient = new URLSearchParams(window.location.search);
        const NewRecipient = urlRecipient.get('recipient') || "USDT";
        const NewRecipientNetwork = urlRecipient.get('recipientNetwork') || "TRC20";

        const meeting = getCookie("meeting");
        const loaderElement = document.getElementById('Loading_Page');
        const pageElement = document.getElementById('Page');

        try {
            const requestData = { Session_ID: meeting };
            const response = await fetch(`${window.Web_Url_Api}/v1/wallet/Swap/Information.php`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(requestData)
            });
            if (!response.ok) {
                toast('Internet connection error');
                return;
            }

            const data = await response.json();
            if (data.status === "true" && Array.isArray(data.data)) {
                setTokens(data.data);

                if (!userSelectedTokens.from) {
                    const defaultFromToken = data.data.find(token => token.symbol === NewSender && token.network === NewSenderNetwork);
                    setFromToken(defaultFromToken || data.data[1]);
                    setSelectedFromToken(defaultFromToken || data.data[1]);
                }

                if (!userSelectedTokens.to) {
                    const defaultToToken = data.data.find(token => token.symbol === NewRecipient && token.network === NewRecipientNetwork);
                    setToToken(defaultToToken || data.data[2]);
                    setSelectedToToken(defaultToToken || data.data[2]);
                }
            }

            pageElement.classList.remove('hidden');
            loaderElement.classList.add('hidden');
        } catch (error) {
            toast('An error has occurred');
        }
    }

    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //
    //     }, 5000);
    //     return () => clearInterval(intervalId);
    // }, []);

    async function Load_Pages_Min_2() {
        const meeting = getCookie("meeting");
        try {
            const requestData = { Session_ID: meeting };
            const response = await fetch(`${window.Web_Url_Api}/v1/wallet/Swap/Information.php`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(requestData)
            });
            if (!response.ok) {
                toast('Internet connection error');
                return;
            }
            const data = await response.json();
            if (data.status === "true" && Array.isArray(data.data)) {
                setTokens(data.data);

                // Update selected fromToken if it exists in the new data
                if (selectedFromToken) {
                    const updatedFromToken = data.data.find(token => token.symbol === selectedFromToken.symbol && token.network === selectedFromToken.network);
                    if (updatedFromToken) {
                        setSelectedFromToken(updatedFromToken);
                        setFromToken(updatedFromToken);
                    }
                }

                // Update selected toToken if it exists in the new data
                if (selectedToToken) {
                    const updatedToToken = data.data.find(token => token.symbol === selectedToToken.symbol && token.network === selectedToToken.network);
                    if (updatedToToken) {
                        setSelectedToToken(updatedToToken);
                        setToToken(updatedToToken);
                    }
                }
            }
        } catch (error) {
            toast('An error has occurred');
        }
    }

    useEffect(() => {
        if (tokens.length > 0) {
            const defaultFromToken = tokens.find(token => token.symbol === selectedFromToken?.symbol && token.network === selectedFromToken?.network);
            if (defaultFromToken) {
                setSelectedFromToken(defaultFromToken);
                setFromToken(defaultFromToken);
            }

            const defaultToToken = tokens.find(token => token.symbol === selectedToToken?.symbol && token.network === selectedToToken?.network);
            if (defaultToToken) {
                setSelectedToToken(defaultToToken);
                setToToken(defaultToToken);
            }
        }
    }, [tokens]);

    const handleTokenSelect = (token, type) => {
        if (type === 'from') {
            setSelectedFromToken(token);
            setFromToken(token);
            setUserSelectedTokens(prev => ({ ...prev, from: true }));


            if (toToken && toToken.symbol === token.symbol && toToken.network === token.network) {
                changeToRandomToken(token);

            }
        } else {
            setSelectedToToken(token);
            setToToken(token);
            setUserSelectedTokens(prev => ({ ...prev, to: true }));

            // اگر توکن‌ها بعد از انتخاب مشابه شدند، توکن دوم تغییر کند
            if (fromToken && fromToken.symbol === token.symbol && fromToken.network === token.network) {
                changeToRandomToken(fromToken); // تغییر توکن دوم به یک توکن متفاوت
            }
        }

        // تنظیم مجدد مقادیر برای مابقی ورودی‌ها
        setFromAmount('');
        setToAmount('');
        setInsufficientBalance(false);
        setIsFromTokenListOpen(false);
        setIsToTokenListOpen(false);
        setFromSearchTerm('');
        setToSearchTerm('');
    };

// تابع تغییر توکن دوم به یک توکن متفاوت
    const changeToRandomToken = (excludeToken) => {
        toast('It cannot be one in two parts');
        const availableTokens = tokens.filter(token => token.symbol !== excludeToken.symbol || token.network !== excludeToken.network);
        if (availableTokens.length === 0) {
            return;
        }
        const randomToken = availableTokens[Math.floor(Math.random() * availableTokens.length)];
        setSelectedToToken(randomToken);
        setToToken(randomToken);
        setUserSelectedTokens(prev => ({ ...prev, to: true }));
    };


    const swapTokens = () => {
        const temp = fromToken;
        setFromToken(toToken);
        setToToken(temp);
        setSelectedFromToken(toToken);
        setSelectedToToken(temp);
        setUserSelectedTokens({ from: true, to: true });
        setFromAmount('');
        setToAmount('');
        setInsufficientBalance(false);
    };

    useEffect(() => {
        if (fromToken && toToken) {
            const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?Sender=${fromToken.symbol}&SenderNetwork=${fromToken.network}&recipient=${toToken.symbol}&recipientNetwork=${toToken.network}`;
            window.history.replaceState({ path: newUrl }, '', newUrl);
        }
    }, [fromToken, toToken]);

    const setMaxAmount = () => {
        setFromAmount(fromToken.amount);
        calculateToAmount(fromToken.amount);
    };

    const calculateToAmount = (amount) => {
        const result = (amount * fromToken.rate) / toToken.rate;
        setToAmount(result.toFixed(8));
    };

    const calculateFromAmount = (amount) => {
        const result = (amount * toToken.rate) / fromToken.rate;
        setFromAmount(result.toFixed(8));

        if (parseFloat(result) > fromToken.amount) {
            setInsufficientBalance(true);
        } else {
            setInsufficientBalance(false);
        }
    };

    const handleFromAmountChange = (e) => {
        const value = e.target.value;
        const regex = /^\d*\.?\d*$/;

        if (regex.test(value)) {
            const [integerPart, decimalPart] = value.split(".");

            if (decimalPart && decimalPart.length > 8) {
                setFromAmount(`${integerPart}.${decimalPart.substring(0, 8)}`);
            } else {
                setFromAmount(value);
            }

            calculateToAmount(value);

            if (parseFloat(value) > fromToken.amount) {
                setInsufficientBalance(true);
            } else {
                setInsufficientBalance(false);
            }
        } else {
            setFromAmount("0");
            setToAmount("0");
            setInsufficientBalance(false);
        }
    };

    const handleToAmountChange = (e) => {
        const value = e.target.value;
        const regex = /^\d*\.?\d*$/;

        if (regex.test(value)) {
            const [integerPart, decimalPart] = value.split(".");

            if (decimalPart && decimalPart.length > 8) {
                setToAmount(`${integerPart}.${decimalPart.substring(0, 8)}`);
            } else {
                setToAmount(value);
            }

            calculateFromAmount(value);
        } else {
            setFromAmount("0");
            setToAmount("0");
            setInsufficientBalance(false);
        }
    };

    const filteredFromTokens = tokens.filter(token =>
        token.symbol.toLowerCase().includes(fromSearchTerm.toLowerCase()) ||
        token.network.toLowerCase().includes(fromSearchTerm.toLowerCase())
    );

    const filteredToTokens = tokens.filter(token =>
        token.symbol.toLowerCase().includes(toSearchTerm.toLowerCase()) ||
        token.network.toLowerCase().includes(toSearchTerm.toLowerCase())
    );

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (fromDropdownRef.current && !fromDropdownRef.current.contains(event.target)) {
                setIsFromTokenListOpen(false);
            }
            if (toDropdownRef.current && !toDropdownRef.current.contains(event.target)) {
                setIsToTokenListOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const referencePrice = fromToken && toToken ? (fromToken.rate / toToken.rate).toFixed(8) : '0.00000000';



    const [token_depositData, set_token_depositData] = useState([]);

    async function Section_Part_5() {

    }
    async function Section_Part_4() {
        section_Part_Re_w_1(2);

        let meeting = getCookie("meeting");
        try {
            const requestData = {
                Session_ID:      meeting,
            };
            const response = await fetch(`${window.Web_Url_Api}/v1/Swap/History.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            });

            if (!response.ok) {
                toast('Internet connection error');
                return;
            }

            const data = await response.json();

            if (Array.isArray(data)) {
                set_token_depositData(data);
            }
            section_Part_Re_w_1(1);
            // pageElement.classList.remove('hidden');
            // loaderElement.classList.add('hidden');


        } catch (error) {
            toast('An error has occurred');
        }

    }


    async function Section_Part_6() {
        let meeting = getCookie("meeting");

        if (!fromAmount){
            toast('Please enter amount');
            return;
        }
        if (!toAmount){
            toast('Please enter amount');
            return;
        }

        section_Part_Re_w_2(2);

        const data = {
            Session_ID:             meeting,

            From_symbol:                 fromToken.symbol ,
            From_Network:                fromToken.network ,
            From_id:                     fromToken.id ,
            From_amount:                 fromAmount,

            To_symbol:                   toToken.symbol ,
            To_Network:                  toToken.network ,
            To_id:                       toToken.id ,
            To_amount:                   toAmount,

        };

        const actionURL = `${window.Web_Url_Api}/v1/Swap/exchange.php`;

        fetch(actionURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(data => {

                if (data.status === "true") {
                    Section_Part_4()
                    Load_Pages_Min_2()
                }
                section_Part_Re_w_2(1);
                toast(data.message);
            })
            .catch(() => {
                toast('An error has occurred');
            });




    }


    return (
        <div>
            <LoadingPage />

            <div id="Page" className="p-3 hidden">

                <div className="flex items-center space-x-2 text-black dark:text-white ">
                    <Link to="/account/wallet/index" className=" text-2xl">
                        <i className="fa-solid fa-arrow-left"></i>
                    </Link>
                    <h1 className='text-2xl  p-5 rounded-[3px]'>Swap </h1>
                </div>

                <div className="text-center">
                    <div className="inline-flex items-center space-x-2 mb-2">
                        <i className="fa-regular fa-magnifying-glass-arrows-rotate text-3xl"></i>
                        <h1 className="text-3xl font-bold text-gray-800">Swap immediate</h1>
                    </div>
                    <p className="text-gray-600">Replace your icon without any fees. Get immediately</p>
                    <a className="mt-4 inline-block text-teal-600 hover:underline font-semibold">
                        Video Tutorial
                    </a>
                </div>


                <div className="flex justify-center items-center mt-4 ">
                    <div
                        className="flex flex-col text-black items-center p-6 bg-gray-100 dark:bg-gray-800 dark:text-white rounded-lg shadow-md w-full max-w-xl">
                        {/* From Token */}
                        <div className="w-full mb-2 relative" ref={fromDropdownRef}>
                            <label className="block  mb-1">From</label>
                            {/*<div*/}
                            {/*    className="flex items-center p-3 bg-gray-200 dark:bg-gray-700 rounded-lg border border-amber-400 ">*/}
                            {/*    <div className="flex items-center cursor-pointer"*/}
                            {/*         onClick={() => setIsFromTokenListOpen(!isFromTokenListOpen)}>*/}
                            {/*        {fromToken &&*/}
                            {/*            <img src={fromToken.icon} alt={fromToken.symbol} className="w-5 h-5 mr-2"/>}*/}
                            {/*        <span className="text-black dark:text-white">{fromToken ? `${fromToken.symbol} (${fromToken.network})` : 'Select Token'}</span>*/}
                            {/*        <span>*/}
                            {/*            {isFromTokenListOpen ? (*/}
                            {/*                <i className="fa-solid fa-chevron-up ml-2 text-xl"></i>*/}

                            {/*            ) : (*/}
                            {/*                <i className="fa-solid fa-chevron-down ml-2 text-xl"></i>*/}
                            {/*            )}*/}

                            {/*        </span>*/}
                            {/*    </div>*/}
                            {/*    <input*/}
                            {/*        type="text"*/}
                            {/*        value={fromAmount}*/}
                            {/*        onChange={handleFromAmountChange}*/}
                            {/*        placeholder="Enter amount"*/}
                            {/*        className="flex-1 ml-4 mr-4 bg-transparent outline-none text-left"*/}
                            {/*    />*/}
                            {/*    <button onClick={setMaxAmount} className="text-yellow-300 font-semibold">Max</button>*/}
                            {/*</div>*/}
                            {/*------------*/}


                            <div
                                className="flex flex-col  p-3 bg-gray-200 dark:bg-gray-700 rounded-lg border border-amber-400">
                                {/* بخش انتخاب توکن */}
                                <div className="flex items-center cursor-pointer ml-3"
                                     onClick={() => setIsFromTokenListOpen(!isFromTokenListOpen)}>
                                    {fromToken &&
                                        <img src={fromToken.icon} alt={fromToken.symbol} className="w-5 h-5 mr-2"/>}
                                    <span className="text-black dark:text-white">
      {fromToken ? `${fromToken.symbol} (${fromToken.network})` : 'Select Token'}
    </span>
                                    <span>
      {isFromTokenListOpen ? (
          <i className="fa-solid fa-chevron-up ml-2 text-xl"></i>
      ) : (
          <i className="fa-solid fa-chevron-down ml-2 text-xl"></i>
      )}
    </span>
                                </div>

                                {/* بخش وارد کردن مقدار */}
                                <div className="flex items-center mt-4">
                                    <input
                                        type="text"
                                        value={fromAmount}
                                        onChange={handleFromAmountChange}
                                        placeholder="Enter amount"
                                        className="flex-1 ml-4 mr-4 bg-transparent outline-none text-left"
                                    />
                                    <button onClick={setMaxAmount} className="text-yellow-600 font-semibold">Max
                                    </button>
                                </div>
                            </div>


                            {/*------------*/}
                            {isFromTokenListOpen && (
                                <div
                                    className="absolute top-full left-0 w-full bg-white text-black dark:bg-gray-900 dark:text-white  shadow-lg rounded-lg z-10 max-h-60 overflow-y-auto">
                                    <input
                                        type="text"
                                        placeholder="Search Coin or Network"
                                        className="p-2 w-full border-b dark:bg-black bg-white"
                                        value={fromSearchTerm}
                                        onChange={(e) => setFromSearchTerm(e.target.value)}
                                    />
                                    <ul className="max-h-40 overflow-y-auto div_slide_win">
                                        {filteredFromTokens.length > 0 ? (
                                            filteredFromTokens.map((token) => (
                                                <li
                                                    key={`${token.symbol}-${token.network}`}
                                                    className="flex items-center  dark:hover:bg-gray-700 p-2 hover:bg-gray-100 cursor-pointer"
                                                    onClick={() => handleTokenSelect(token, 'from')}
                                                >
                                                    <img src={token.icon} alt={token.symbol} className="w-6 h-6 mr-2"/>
                                                    {token.symbol} ({token.network})
                                                </li>
                                            ))
                                        ) : (
                                            <li className="p-2 text-center text-gray-500">An item is not to show</li>
                                        )}
                                    </ul>
                                </div>
                            )}
                            <div className="flex justify-between mt-1 text-sm">
                                <span className={insufficientBalance ? "text-red-500" : "text-transparent"}>
                                    Balance insufficient
                                </span>
                                <span className="text-gray-500 dark:text-gray-300">
                                    Available: {fromToken ? `${fromToken.amount} ${fromToken.symbol}` : '0'}
                                </span>
                            </div>
                        </div>

                        {/* Swap Button */}
                        <div className="text-center text-gray-600  my-2">
                            <button
                                onClick={swapTokens}
                                className="bg-gray-200 dark:bg-gray-700 dark:text-white p-2 rounded-full focus:outline-none"
                            >
                                <i className="fa-solid fa-arrow-up-arrow-down"></i>
                            </button>
                        </div>

                        {/* To Token */}
                        <div className="w-full mb-2 relative" ref={toDropdownRef}>
                            <label className="block  mb-1">To</label>
                            {/*<div*/}
                            {/*    className="flex items-center p-3 bg-gray-200 dark:bg-gray-700 rounded-lg border border-amber-400">*/}
                            {/*    <div className="flex items-center cursor-pointer"*/}
                            {/*         onClick={() => setIsToTokenListOpen(!isToTokenListOpen)}>*/}
                            {/*        {toToken && <img src={toToken.icon} alt={toToken.symbol} className="w-5 h-5 mr-2"/>}*/}
                            {/*        <span*/}
                            {/*            className="dark:text-white">{toToken ? `${toToken.symbol} (${toToken.network})` : 'Select Token'}</span>*/}
                            {/*        <span>*/}
                            {/*                     {isToTokenListOpen ? (*/}
                            {/*                         <i className="fa-solid fa-chevron-up ml-2 text-xl"></i>*/}

                            {/*                     ) : (*/}
                            {/*                         <i className="fa-solid fa-chevron-down ml-2 text-xl"></i>*/}
                            {/*                     )}*/}
                            {/*        </span>*/}
                            {/*    </div>*/}
                            {/*    <input*/}
                            {/*        type="text"*/}
                            {/*        value={toAmount}*/}
                            {/*        onChange={handleToAmountChange}*/}
                            {/*        placeholder="Enter amount"*/}
                            {/*        className="flex-1 ml-4 mr-4  bg-transparent outline-none text-left"*/}
                            {/*    />*/}
                            {/*</div>*/}
                            {/*--------------*/}
                            <div
                                className="flex flex-col items-start p-3 bg-gray-200 dark:bg-gray-700 rounded-lg border border-amber-400">
                                {/* بخش انتخاب توکن مقصد */}
                                <div className="flex items-center cursor-pointer w-full"
                                     onClick={() => setIsToTokenListOpen(!isToTokenListOpen)}>
                                    {toToken && <img src={toToken.icon} alt={toToken.symbol} className="w-5 h-5 mr-2"/>}
                                    <span className="dark:text-white">
      {toToken ? `${toToken.symbol} (${toToken.network})` : 'Select Token'}
    </span>
                                    <span>
      {isToTokenListOpen ? (
          <i className="fa-solid fa-chevron-up ml-2 text-xl"></i>
      ) : (
          <i className="fa-solid fa-chevron-down ml-2 text-xl"></i>
      )}
    </span>
                                </div>

                                {/* بخش وارد کردن مقدار */}
                                <div className="flex items-center mt-4 w-full">
                                    <input
                                        type="text"
                                        value={toAmount}
                                        onChange={handleToAmountChange}
                                        placeholder="Enter amount"
                                        className="flex-1 ml-4 mr-4 bg-transparent outline-none text-left"
                                    />
                                </div>
                            </div>

                            {/*--------------*/}
                            {isToTokenListOpen && (
                                <div
                                    className="absolute top-full left-0 w-full bg-white text-black dark:bg-gray-900 dark:text-white  shadow-lg rounded-lg z-10 max-h-60 overflow-y-auto">
                                    <input
                                        type="text"
                                        placeholder="Search Coin or Network"
                                        className="p-2 w-full border-b dark:bg-black bg-white"
                                        value={toSearchTerm}
                                        onChange={(e) => setToSearchTerm(e.target.value)}
                                    />
                                    <ul className="max-h-40 overflow-y-auto div_slide_win">
                                        {filteredToTokens.length > 0 ? (
                                            filteredToTokens.map((token) => (
                                                <li
                                                    key={`${token.symbol}-${token.network}`}
                                                    className="flex items-center p-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
                                                    onClick={() => handleTokenSelect(token, 'to')}
                                                >
                                                    <img src={token.icon} alt={token.symbol} className="w-6 h-6 mr-2"/>
                                                    {token.symbol} ({token.network})
                                                </li>
                                            ))
                                        ) : (
                                            <li className="p-2 text-center text-gray-500">An item is not to show</li>
                                        )}
                                    </ul>
                                </div>
                            )}
                        </div>

                        {/* Reference Price */}
                        <div className="text-gray-600 mt-1 dark:text-gray-200 text-left">
                            Reference Price:
                            1 {fromToken ? fromToken.symbol : ''} = {referencePrice} {toToken ? toToken.symbol : ''}
                        </div>

                        {section_Part_w_2 === 1 && (
                            <button onClick={Section_Part_6}
                                    className="w-full p-3 mt-4 bg-yellow-500 hover:bg-yellow-600 text-white rounded-lg">
                                Swap
                            </button>
                        )}

                        {section_Part_w_2 === 2 && (
                            <button
                                    className="w-full p-3 mt-4 bg-yellow-500 hover:bg-yellow-600 text-white rounded-lg">
                                <div
                                    className="flex items-center justify-center  centered">
                                    <div className="loader_Page_Part_1 flex ">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                            </button>
                        )}


                    </div>
                </div>


                <div className="dark:bg-gray-800 bg-gray-100 rounded-lg shadow-lg p-2 mt-3">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                            <h2 className="text-lg font-semibold text-gray-700 dark:text-white">
                                Last 10 Swap records
                            </h2>
                            <button onClick={Section_Part_4}>
                                <i className="fa-solid  fa-arrows-rotate text-yellow-400  cursor-pointer"></i>
                            </button>

                        </div>


                        <button
                            className="text-teal-600 border border-teal-600 rounded-lg px-3 py-1 hover:bg-teal-100 transition duration-200">
                            All Records
                        </button>

                    </div>
                    <div className="mt-2  text-gray-400 h-48">


                        {section_Part_w_1 === 1 && (
                            <div>
                                {token_depositData && token_depositData.length > 0 ? (
                                    <div>
                                        <div
                                            className="hidden sm:grid grid-cols-6 gap-4 dark:bg-gray-900 bg-gray-200 text-gray-900 dark:text-gray-100 p-4 text-center font-semibold rounded-t-lg">
                                            <div>From</div>
                                            <div>From value </div>
                                            <div> To</div>
                                            <div>To value</div>
                                            <div>Time</div>
                                            <div>Status</div>

                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <div className="flex flex-col items-center justify-center h-48 text-gray-400">
                                            <i className="fa-solid fa-database text-6xl text-yellow-400"></i>
                                            <p className="mt-4 text-gray-500">No data</p>
                                        </div>
                                    </div>

                                )}


                                {token_depositData
                                    .map((deposit, index) => (
                                        <div key={index}>
                                            <div
                                                className="hidden sm:grid dark:bg-gray-900 dark:text-white grid-cols-6 gap-4 items-center bg-gray-100 text-black p-4 border-b border-gray-600">

                                                <div className="text-center">{deposit.From_symbol} - {deposit.From_Network}</div>
                                                <div className="text-center">{deposit.From_amount}</div>
                                                <div className="text-center">{deposit.To_symbol} - {deposit.To_Network}</div>
                                                <div className="text-center">{deposit.To_amount}</div>
                                                <div className="text-center">{deposit.Time}</div>
                                                <div className="text-center">
                                                    {deposit.status === "true" ? (
                                                        <span
                                                            className="px-2 py-1 bg-green-600 text-white rounded-full text-xs">Successful</span>
                                                    ) : deposit.status === "pending" ? (
                                                        <span
                                                            className="px-2 py-1 bg-yellow-500 text-white rounded-full text-xs">Pending</span>
                                                    ) : (
                                                        <span
                                                            className="px-2 py-1 bg-red-600 text-white rounded-full text-xs">Failed</span>
                                                    )}
                                                </div>
                                            </div>

                                            {/*-----*/}
                                            <div
                                                className="sm:hidden bg-gray-100 text-black dark:bg-gray-800 dark:text-white p-4 mb-4 rounded-lg shadow">
                                                <div className="flex justify-between items-center mb-2">
                                                    <span className="text-xs">From</span>
                                                    <span className="text-gray-400">{deposit.From_symbol} - {deposit.From_Network}</span>
                                                </div>

                                                <div className="flex justify-between items-center mb-2">
                                                    <span className="text-xs">From value</span>
                                                    <span className="text-gray-400">   {deposit.From_amount}   </span>
                                                </div>

                                                <div className="flex justify-between items-center mb-2">
                                                    <span className="text-xs">To</span>
                                                    <span className="text-gray-400">   {deposit.To_symbol} - {deposit.To_Network}  </span>
                                                </div>

                                                <div className="flex justify-between items-center mb-2">
                                                    <span className="text-xs">To value</span>
                                                    <span className="text-gray-400">   {deposit.To_amount}   </span>
                                                </div>

                                                <div className="flex justify-between items-center mb-2">
                                                    <span className="text-xs">Time</span>
                                                    <span className="text-gray-400">{deposit.Time}</span>
                                                </div>

                                                <div className="flex justify-between items-center mb-2">
                                                    <span className="text-xs">status</span>
                                                    {deposit.status === "true" ? (
                                                        <span
                                                            className="px-2 py-1 bg-green-600 text-white rounded-full text-xs">Successful</span>
                                                    ) : deposit.status === "pending" ? (
                                                        <span
                                                            className="px-2 py-1 bg-yellow-500 text-white rounded-full text-xs">Pending</span>
                                                    ) : (
                                                        <span
                                                            className="px-2 py-1 bg-red-600 text-white rounded-full text-xs">Failed</span>
                                                    )}

                                                </div>

                                            </div>
                                        </div>
                                    ))}

                            </div>
                        )}

                        {section_Part_w_1 === 2 && (
                            <div>
                                <div className="flex flex-col items-center justify-center h-48 text-gray-400">
                                    <div className="loader_Page flex ">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>

                                </div>
                            </div>
                        )}


                    </div>
                </div>



                <div>
                    <br/><br/><br/><br/><br/>
                    <br/><br/><br/><br/><br/>
                    <br/><br/><br/><br/><br/>
                </div>

            </div>
        </div>
    );
}

export default SwapComponent;
