import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import {getCookie} from "../../Format/En/Cookie";
import LoadingPage from "../Loading-Page";

function Wallet() {

    const [items, setItems] = useState([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [is_model_1, Modal_Full_1] = useState(false);
    const [selectedToken, setSelectedToken] = useState(null);
    const [walletData, setwalletData] = useState(null);
    const [isChecked, setIsChecked] = useState(false);

    const [section_Part_w_1, section_Part_Re_w_1] = useState(1);
    const [section_Part_w_2, section_Part_Re_w_2] = useState(1);


    useEffect(() => {
        if (!isDataLoaded) {
            Load_Pages_Min().then(() => {
                setIsDataLoaded(true);
            });
        }
    }, [isDataLoaded]);

    async function Load_Pages_Min() {
        // const urlParams = new URLSearchParams(window.location.search);
        // const newParam = urlParams.get('symbol');
        // if (!newParam) {
        //     const newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?symbol=BTC';
        //     window.history.pushState({ path: newUrl }, '', newUrl);
        //     window.Section_Part_4 = "BTC";
        // }else {
        //
        //     // window.Section_Part_4 = newParam;
        // }


        let meeting = getCookie("meeting");
        const loaderElement = document.getElementById('Loading_Page');
        const pageElement = document.getElementById('Page');
        try {
            const requestData = {
                Session_ID: meeting,
            };
            const response = await fetch(`${window.Web_Url_Api}/v1/wallet/withdraw/Information.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData)
            });
            if (!response.ok) {
                toast('Internet connection error');
                return;
            }

            const data = await response.json();

            if (data.status === "true" && Array.isArray(data.data)) {
                setItems(data.data);

                if (data.withdraw !== "true") {
                    section_Part_Re_w_1(3);

                }

                // const tokenToShow = data.data.find(item => item.symbol.toLowerCase() === window.Section_Part_4.toLowerCase()); // نماد مورد نظر را به جای 'btc' قرار دهید
                // if (tokenToShow) {
                //     setSelectedToken(tokenToShow);
                // }

            }
            pageElement.classList.remove('hidden');
            loaderElement.classList.add('hidden');
        } catch (error) {
            toast('An error has occurred');
        }

        // window.Section_Part_4 = "1";

    }

    const filteredItems = items.filter(
        item =>
            item.symbol.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const Section_Part_1 = (token) => {

        Modal_Full_1(!is_model_1);
        setSelectedToken(token);
        Modal_Full_1(false);
        section_Part_Re_w_1(1);
        window.Section_Part_4 = token.symbol;

        let meeting = getCookie("meeting");
        const data = {
            Session_ID:            meeting,
            token_symbol:          token.symbol,

        };

        const actionURL = `${window.Web_Url_Api}/v1/wallet/withdraw/Check-wallet.php`;

        fetch(actionURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(data => {
                setwalletData(data);
                setActiveButton("normal")

                if (data.Structure === "Wallet") {
                    section_Part_Re_w_1(2);
                    section_Part_Re_w_2(2);

                }else{
                    window.New_TokenId = data.TokenId
                    section_Part_Re_w_1(5);
                    section_Part_Re_w_2(2);
                }
                if (data.status_wallet === "inactive"){
                    section_Part_Re_w_1(4);
                }



                const newUrls = window.location.protocol + "//" + window.location.host + window.location.pathname + `?symbol=${data.symbol}`;
                window.history.pushState({ path: newUrls }, '', newUrls);

            })
            .catch(() => {
                toast('An error has occurred');
            });






    };

    const Target_model_1 = () => {
        Modal_Full_1(!is_model_1);
    };

    const [is_model_2, Modal_Full_2] = useState(false);

    const Target_model_2 = () => {
        Modal_Full_2(!is_model_2);
    };


//----------------------------
    const Section_Part_222 = () => {
        let meeting = getCookie("meeting");
        const data = {
            Session_ID:            meeting,
            token_symbol:          window.Section_Part_4,

        };
        const actionURL = `${window.Web_Url_Api}/v1/wallet/deposit/Check-wallet.php`;
        fetch(actionURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(data => {
                setwalletData(data);
                if (data.Structure === "Wallet") {
                    section_Part_Re_w_1(2);
                    section_Part_Re_w_2(3);
                }else{
                    window.New_TokenId = data.TokenId
                    section_Part_Re_w_1(2);
                    section_Part_Re_w_2(1);
                }

                if (data.NoToken === "on") {
                    const newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?symbol=BTC';
                    window.history.pushState({ path: newUrl }, '', newUrl);
                    window.Section_Part_4 = "BTC"
                }




            })
            .catch(() => {
                toast('An error has occurred');
            });

    };
    // ------------------
    const [activeButton, setActiveButton] = useState("normal");
        const Section_Part_2 = () => {
            setActiveButton("normal")
            section_Part_Re_w_1(2);
        }
    const Section_Part_3 = () => {
        setActiveButton("inter-user")
        section_Part_Re_w_1(6);


    }
//------------------
    const [description, setDescription] = useState(''); // متغیری برای ذخیره متن
    const [savedDescription, setSavedDescription] = useState(''); // متغیر برای نمایش متن ذخیره‌شده
    const handleConfirm = () => {
        Modal_Full_2(!is_model_2);
        setSavedDescription(description);
    };
//------------------
    const [value, setValue] = useState('');

    const handleChange = (e) => {
        let inputValue = e.target.value;

        // بررسی اینکه مقدار ورودی عدد باشد و حداکثر تا 8 رقم اعشار را شامل شود
        if (
            inputValue === '' ||
            (/^\d*\.?\d{0,8}$/.test(inputValue) && !isNaN(Number(inputValue)))
        ) {
            setValue(inputValue);
        }
    };

    const handleBlur = () => {
        if (value === '' || isNaN(Number(value))) {
            setValue('0');
        }
    };

    const All_token = () => {
            setValue(walletData ? walletData.amount : '0');
    };
//------------------

    return (
        <div className="p-3">
            <LoadingPage/>

            <div id="Page" className='hidden'>
                <div className="flex items-center space-x-2 text-black dark:text-white ">
                    <Link to="/account/wallet/index" className=" text-2xl">
                        <i className="fa-solid fa-arrow-left"></i>
                    </Link>
                    <h1 className='text-2xl  p-5 rounded-[3px]'>Withdraw </h1>
                </div>


                <div
                    className="bg-white dark:bg-gray-800 dark:text-white rounded-lg p-4 w-full mx-auto mt-2 grid grid-cols-1 md:grid-cols-3 gap-4">

                    <div className="md:col-span-2">

                        <div>

                            <div className="mb-6">
                                <h2 className="text-lg font-semibold mb-2">Select Coin/Token</h2>
                                <button onClick={Target_model_1} className="w-full">
                                    {selectedToken ? (
                                        <div className="flex items-center bg-gray-100 dark:bg-gray-700 p-3 rounded-lg">
                                            <img src={selectedToken.icon} alt={selectedToken.symbol}
                                                 className="mr-2 h-8 w-8"/>
                                            <span className="text-md font-medium">{selectedToken.symbol}</span>
                                            <span className="ml-2 text-gray-400">{selectedToken.name}</span>
                                        </div>
                                    ) : (
                                        <div className="flex items-center bg-gray-100 dark:bg-gray-700 p-3 rounded-lg">
                                            <img src="https://cryptologos.cc/logos/bitcoin-btc-logo.png" alt="BTC"
                                                 className="mr-2 h-8 w-8"/>
                                            <span className="text-black dark:text-white text-md font-medium">BTC</span>
                                            <span className="ml-2 text-gray-400">Bitcoin</span>
                                        </div>
                                    )}
                                </button>
                            </div>


                            {is_model_1 && (
                                <div>
                                    <div
                                        className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
                                        onClick={Target_model_1}>
                                        <div className="relative w-full max-w-lg dark:bg-gray-800 bg-white p-6
                      md:max-w-4xl md:m-auto
                      md:p-3 md:rounded-lg md:shadow-lg
                      md:relative
                      h-full md:h-auto

                      "
                                             onClick={(e) => e.stopPropagation()}
                                        >

                                            <div className="flex justify-between items-center mb-4"><h2
                                                className="text-xl font-semibold">Choose a Coin/Token</h2>
                                                <button onClick={Target_model_1}
                                                        className="text-xl dark:text-gray-400 text-gray-400 dark:hover:text-white hover:text-gray-900">
                                                    <i className="fa-solid fa-xmark"></i></button>
                                            </div>
                                            <div className="w-full ">

                                                <div className="relative mb-4">
                                                    <input
                                                        type="text"
                                                        placeholder="Search by coin/token"
                                                        className="w-full px-4  bg-gray-200 py-2 dark:bg-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 mb-4"
                                                        value={searchTerm}
                                                        onChange={(e) => setSearchTerm(e.target.value)}
                                                    />
                                                    <button className="absolute right-2 top-2">

                                                    </button>
                                                </div>


                                                {searchTerm === "" && (
                                                    <div className="hidden">
                                                        <div className="mb-4">
                                                            <h2 className="text-gray-500 text-sm mb-2">History</h2>
                                                            <div className="flex flex-wrap gap-2">
                                                                <span
                                                                    className="bg-gray-100 text-gray-700 px-3 py-1 rounded-full text-sm">AURORA</span>
                                                                <span
                                                                    className="bg-gray-100 text-gray-700 px-3 py-1 rounded-full text-sm">USDT</span>
                                                                <span
                                                                    className="bg-gray-100 text-gray-700 px-3 py-1 rounded-full text-sm">TRX</span>
                                                                <span
                                                                    className="bg-gray-100 text-gray-700 px-3 py-1 rounded-full text-sm">BTC</span>
                                                            </div>
                                                        </div>
                                                        <div className="mb-4">
                                                            <h2 className="text-gray-500 text-sm mb-2 flex items-center">
                                                                Popular Coins
                                                                <span className="ml-1 text-yellow-500">🔥</span>
                                                            </h2>
                                                            <div className="flex flex-wrap gap-2">
                                                                <span
                                                                    className="bg-gray-100 text-gray-700 px-3 py-1 rounded-full text-sm">USDT</span>
                                                                <span
                                                                    className="bg-gray-100 text-gray-700 px-3 py-1 rounded-full text-sm">BELLSCOIN</span>
                                                                <span
                                                                    className="bg-gray-100 text-gray-700 px-3 py-1 rounded-full text-sm">FB</span>
                                                                <span
                                                                    className="bg-gray-100 text-gray-700 px-3 py-1 rounded-full text-sm">KAS</span>
                                                                <span
                                                                    className="bg-gray-100 text-gray-700 px-3 py-1 rounded-full text-sm">TRX</span>
                                                                <span
                                                                    className="bg-gray-100 text-gray-700 px-3 py-1 rounded-full text-sm">XNA</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}


                                                <div>
                                                    <h2 className="text-gray-500 dark:text-gray-300 text-sm mb-2">
                                                        Crypto List
                                                    </h2>

                                                    {filteredItems.length > 0 ? (
                                                        <div
                                                            className="w-full bg-white dark:bg-gray-800 dark:text-gray-100">
                                                            {filteredItems.map((item, index) => (
                                                                <div onClick={() => Section_Part_1(item)} key={index}
                                                                     className="cursor-pointer  flex items-center p-2  hover:bg-gray-200 dark:hover:bg-gray-700 rounded-md">
                                                                    <div className="flex items-center space-x-2">
                                                                        <img src={item.icon} alt="icon"
                                                                             className="w-8 h-8  rounded-full"/>
                                                                        <span
                                                                            className="font-bold truncate min-w-16 md:min-w-36">{item.symbol}</span>
                                                                    </div>
                                                                    <span
                                                                        className="ml-4 text-gray-500 dark:text-gray-300 truncate max-w-xs">{item.name} - {item.Network}</span>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ) : (
                                                        <div className="text-center text-gray-500 p-4">
                                                            دیتا ای نیست
                                                        </div>
                                                    )}
                                                </div>


                                            </div>

                                        </div>
                                    </div>


                                </div>
                            )}
                        </div>




                        {section_Part_w_1 === 1 && (
                            <div className="h-60 items-center justify-center   py-20 rounded   ">
                                <div className="flex items-center justify-center  centered">
                                    <div className="loader_Page flex ">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {section_Part_w_1 === 3 && (
                            <div className="h-60 items-center justify-center   py-20 rounded   ">
                                <div className="flex items-center justify-center  centered">

                                    <div className="text-center">
                                        <div className="text-4xl font-bold text-blue-500 mb-1">
                                            <i className="fa-duotone fa-solid fa-circle-info"></i>
                                        </div>

                                        <p className="text-gray-600 mb-6">
                                            Request for your account is disabled, contact support
                                        </p>
                                        <a href="#"
                                           className="bg-yellow-500 text-white px-6 py-3 rounded-lg hover:bg-yellow-600 transition">
                                            Support
                                        </a>
                                    </div>

                                </div>
                            </div>
                        )}

                        {section_Part_w_1 === 2 && (
                            <div>


                                {/**/}
                                <div className="flex flex-col space-y-2 mb-4 md:flex-row md:space-x-2 md:space-y-0">
                                    <button
                                        className={`px-4 dark:text-gray-100 dark:bg-gray-700 py-2 border font-medium rounded-lg focus:outline-none ${
                                            activeButton === "normal" ? "bg-white border-green-500 text-green-500 focus:ring-2 focus:ring-green-200" : "bg-gray-100 text-gray-400"
                                        }`}
                                        onClick={Section_Part_2}
                                    >
                                        Normal Transfer
                                    </button>
                                    <button
                                        className={`px-4 dark:text-gray-100 dark:bg-gray-700 py-2 border font-medium rounded-lg focus:outline-none ${
                                            activeButton === "inter-user" ? "bg-white border-green-500 text-green-500 focus:ring-2 focus:ring-green-200" : "bg-gray-100 text-gray-400"
                                        }`}
                                        onClick={Section_Part_3}
                                    >
                                        Inter-User Transfer (0 Fee)
                                    </button>
                                </div>

                                {/**/}

                                <div className="mb-4">
                                    <label className="block text-gray-700 dark:text-gray-200 text-sm font-medium mb-1"
                                           htmlFor="withdrawal-address">
                                        Withdrawal Address
                                    </label>
                                    <div className="relative bg-gray-100 dark:bg-gray-700 p-3 rounded-md ">

                                        <input
                                            type="text"
                                            placeholder=""
                                            className="w-full text-base font-light bg-transparent focus:outline-none"

                                        />

                                    </div>
                                    <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">Withdraw to a
                                        decentralized wallet?
                                        Try <a href="#" className="text-blue-500 underline">CoinEx Wallet</a></p>
                                </div>


                                <label className="block text-gray-700 dark:text-gray-200 text-sm font-medium mb-1"
                                       htmlFor="receive-amount">
                                    Receive amount
                                </label>
                                <div className=" bg-gray-100  dark:bg-gray-700 rounded-d">

                                    <div
                                        className="relative p-1 bg-gray-100 dark:bg-gray-700  rounded-md border dark:border-gray-700 border-gray-100">
                                        <input
                                            value={value}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            type="text" step="0.00000001" placeholder="0"
                                               className="w-full text-xl p-3 font-light focus:outline-none bg-gray-100 dark:bg-gray-700"/>
                                        <hr className="dark:hidden"/>
                                        <div
                                            className="absolute dark:text-gray-200 right-3 top-1/2 transform -translate-y-1/2 text-gray-600 flex items-center">
                                            <span className="pr-2">{walletData ? walletData.symbol : '...'}</span>
                                            <button onClick={All_token} className="ml-2 text-sm text-blue-500">All</button>
                                        </div>
                                    </div>
                                    <div
                                        className="flex justify-between mt-1 p-2 text-sm text-gray-500 dark:text-gray-300">
                                        <p>Withdrawal Fee: <span
                                            className="text-gray-700 dark:text-gray-200">{walletData ? walletData.withdrawal_fee : '...'} {walletData ? walletData.symbol : '...'}</span>
                                        </p>
                                    </div>
                                </div>

                                {savedDescription ? (
                                    <div className="flex items-center space-x-2 w-5/6 md:w-1/2">


                                            <p className="truncate">{savedDescription}</p>

                                        <button
                                            onClick={Target_model_2}
                                            className="text-yellow-400 font-medium hover:underline mb-1 mt-1">
                                            Open
                                        </button>
                                        <button
                                            onClick={() => {
                                                setDescription('');
                                                setSavedDescription('');
                                            }}
                                            className="text-yellow-400 font-medium hover:underline mb-1 mt-1">
                                            remove
                                        </button>
                                    </div>

                                ) : (

                                    <div>
                                        <button onClick={Target_model_2}
                                                className="text-teal-500 font-medium hover:underline mb-1 mt-1">Add
                                            Remark
                                        </button>
                                    </div>
                                )}


                                {is_model_2 && (
                                    <div>


                                    <div
                                            className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
                                            onClick={Target_model_2}>
                                            <div className="relative w-full max-w-lg dark:bg-gray-800 bg-white p-6
                      md:max-w-sm md:m-auto
                      md:p-3 md:rounded-lg md:shadow-lg
                      md:relative
                      h-full md:h-auto"
                                                 onClick={(e) => e.stopPropagation()}
                                            >


                                                <div className="flex justify-between items-center mb-4">
                                                    <h2 className="text-xl font-semibold">Description
                                                        Account</h2>
                                                    <button onClick={Target_model_2}
                                                            className="text-xl dark:text-gray-400 text-gray-400 dark:hover:text-white hover:text-gray-900">
                                                        <i className="fa-solid fa-xmark"></i>
                                                    </button>
                                                </div>


                                                <div className="p-4">
          <textarea
              className="w-full min-h-32 max-h-32 div_slide_win p-4 border border-gray-200 dark:border-gray-700 dark:text-white dark:bg-gray-700 rounded-lg text-gray-500 bg-gray-50"
              rows="4"
              placeholder="Please enter the transfer description"
              value={description}
              maxLength={250}
              onChange={(e) => setDescription(e.target.value)} // به‌روزرسانی متن در حین تایپ
          ></textarea>

                                                    <div className="flex justify-between mt-2">
                                                        <button
                                                            onClick={Target_model_2}
                                                            className="border border-teal-500 text-white font-medium py-2 px-6 rounded-full hover:bg-yellow-500 transition"
                                                        >
                                                            Cancel
                                                        </button>
                                                        <button
                                                            onClick={handleConfirm} // اجرای ذخیره متن هنگام کلیک روی "Confirm"
                                                            className="bg-yellow-500 text-white font-medium py-2 px-6 rounded-full hover:bg-yellow-600 transition"
                                                        >
                                                            Confirm
                                                        </button>
                                                    </div>

                                                </div>


                                            </div>
                                    </div>


                                    </div>
                                )}

                                <div className="space-y-2 mt-2 mb-2">
                                    <p className="text-gray-700 font-semibold dark:text-gray-200">Deduction:</p>
                                    <div className="flex items-center space-x-2">
                                        <img src={selectedToken.icon} alt="Deduction"
                                             className="w-6 h-6"/>
                                        <span
                                            className="text-lg font-bold">{walletData ? walletData.withdrawal_fee : '...'}</span>
                                        <span
                                            className="text-gray-500 dark:text-gray-300">{walletData ? walletData.symbol : '...'}</span>
                                    </div>
                                </div>
                                <hr/>
                                <div className="flex items-center justify-center mb-2 mt-2 ">
                                    <button
                                        className="bg-yellow-500 w-full max-w-xl text-white font-medium py-2 px-8 rounded-xl hover:bg-yellow-600 transition">
                                        Withdraw
                                    </button>
                                </div>


                            </div>
                        )}


                        {section_Part_w_1 === 4 && (
                            <div className="h-60 items-center justify-center   py-8 rounded   ">
                                <div className="flex items-center justify-center  centered">

                                    <div className="text-center">
                                        <div className="text-6xl dark:text-gray-100 font-bold text-blue-500 mb-1">
                                            <i className="fa-duotone fa-solid fa-circle-info"></i>
                                        </div>

                                        <p className="text-gray-600 dark:text-gray-200">
                                            This wallet has been deactivated. Contact support
                                        </p>
                                        <p className="text-gray-600 mb-6 dark:text-gray-200">
                                            Deposits are active
                                        </p>
                                        <a href="#"
                                           className="bg-yellow-500 text-white px-6 py-3 rounded-lg hover:bg-yellow-600 transition">
                                            Support
                                        </a>
                                    </div>

                                </div>
                            </div>
                        )}

                        {section_Part_w_1 === 5 && (
                            <div className="h-60 items-center justify-center   py-8 rounded   ">
                                <div className="flex items-center justify-center  centered">

                                    <div className="text-center">
                                        <div className="text-6xl font-bold text-blue-500 mb-1 dark:text-gray-100">
                                            <i className="fa-duotone fa-solid fa-wallet"></i>
                                        </div>

                                        <p className="text-gray-600 dark:text-gray-200 ">
                                            Create a wallet in the deposit section.
                                        </p>
                                        <p className="text-gray-600 mb-6 dark:text-gray-200">
                                            You don't have a wallet
                                        </p>
                                        <Link to={`/account/wallet/deposit?symbol=${walletData ? walletData.symbol : 'BTC'}`}
                                           className="bg-yellow-500 text-white px-6 py-3 rounded-lg hover:bg-yellow-600 transition">
                                            Create a wallet
                                        </Link>
                                    </div>

                                </div>
                            </div>
                        )}

                        {section_Part_w_1 === 6 && (
                            <div>


                                {/**/}
                                <div className="flex flex-col space-y-2 mb-4 md:flex-row md:space-x-2 md:space-y-0">
                                    <button
                                        className={`px-4 dark:text-gray-100 dark:bg-gray-700 py-2 border font-medium rounded-lg focus:outline-none ${
                                            activeButton === "normal" ? "bg-white border-green-500 text-green-500 focus:ring-2 focus:ring-green-200" : "bg-gray-100 text-gray-400"
                                        }`}
                                        onClick={Section_Part_2}
                                    >
                                        Normal Transfer
                                    </button>
                                    <button
                                        className={`px-4 dark:text-gray-100 dark:bg-gray-700 py-2 border font-medium rounded-lg focus:outline-none ${
                                            activeButton === "inter-user" ? "bg-white border-green-500 text-green-500 focus:ring-2 focus:ring-green-200" : "bg-gray-100 text-gray-400"
                                        }`}
                                        onClick={Section_Part_3}
                                    >
                                        Inter-User Transfer (0 Fee)
                                    </button>
                                </div>

                                {/**/}

                                <div className="mb-4">
                                    <label className="block text-gray-700 dark:text-gray-200 text-sm font-medium mb-1"
                                           htmlFor="withdrawal-address">
                                        Account number
                                    </label>
                                    <div className="relative bg-gray-100 dark:bg-gray-700 p-3 rounded-md ">

                                        <input
                                            type="text"
                                            placeholder=""
                                            className="w-full text-base font-light bg-transparent focus:outline-none"

                                        />

                                    </div>
                                    <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">Withdraw to a
                                        How can we transfer?
                                        Try <a href="#" className="text-blue-500 underline">Complete training</a></p>
                                </div>


                                <label className="block text-gray-700 dark:text-gray-200 text-sm font-medium mb-1"
                                       htmlFor="receive-amount">
                                    Receive amount
                                </label>
                                <div className=" bg-gray-100  dark:bg-gray-700 rounded-d">

                                    <div
                                        className="relative p-1 bg-gray-100 dark:bg-gray-700  rounded-md border dark:border-gray-700 border-gray-100">
                                        <input
                                            value={value}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            type="text" step="0.00000001" placeholder="0"
                                            className="w-full text-xl p-3 font-light focus:outline-none bg-gray-100 dark:bg-gray-700"/>
                                        <hr className="dark:hidden"/>
                                        <div
                                            className="absolute dark:text-gray-200 right-3 top-1/2 transform -translate-y-1/2 text-gray-600 flex items-center">
                                            <span className="pr-2">{walletData ? walletData.symbol : '...'}</span>
                                            <button onClick={All_token} className="ml-2 text-sm text-blue-500">All</button>
                                        </div>
                                    </div>
                                    <div
                                        className="flex justify-between mt-1 p-2 text-sm text-gray-500 dark:text-gray-300">
                                        <p>Withdrawal Fee: <span
                                            className="text-gray-700 dark:text-gray-200">0 {walletData ? walletData.symbol : '...'}</span>
                                        </p>
                                    </div>
                                </div>

                                {savedDescription ? (
                                    <div className="flex items-center space-x-2 w-5/6 md:w-1/2">


                                        <p className="truncate">{savedDescription}</p>

                                        <button
                                            onClick={Target_model_2}
                                            className="text-yellow-400 font-medium hover:underline mb-1 mt-1">
                                            Open
                                        </button>
                                        <button
                                            onClick={() => {
                                                setDescription('');
                                                setSavedDescription('');
                                            }}
                                            className="text-yellow-400 font-medium hover:underline mb-1 mt-1">
                                            remove
                                        </button>
                                    </div>

                                ) : (

                                    <div>
                                        <button onClick={Target_model_2}
                                                className="text-teal-500 font-medium hover:underline mb-1 mt-1">Add
                                            Remark
                                        </button>
                                    </div>
                                )}


                                {is_model_2 && (
                                    <div>


                                        <div
                                            className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
                                            onClick={Target_model_2}>
                                            <div className="relative w-full max-w-lg dark:bg-gray-800 bg-white p-6
                      md:max-w-sm md:m-auto
                      md:p-3 md:rounded-lg md:shadow-lg
                      md:relative
                      h-full md:h-auto"
                                                 onClick={(e) => e.stopPropagation()}
                                            >


                                                <div className="flex justify-between items-center mb-4">
                                                    <h2 className="text-xl font-semibold">Description
                                                        Account</h2>
                                                    <button onClick={Target_model_2}
                                                            className="text-xl dark:text-gray-400 text-gray-400 dark:hover:text-white hover:text-gray-900">
                                                        <i className="fa-solid fa-xmark"></i>
                                                    </button>
                                                </div>


                                                <div className="p-4">
          <textarea
              className="w-full min-h-32 max-h-32 div_slide_win p-4 border border-gray-200 dark:border-gray-700 dark:text-white dark:bg-gray-700 rounded-lg text-gray-500 bg-gray-50"
              rows="4"
              placeholder="Please enter the transfer description"
              value={description}
              maxLength={250}
              onChange={(e) => setDescription(e.target.value)} // به‌روزرسانی متن در حین تایپ
          ></textarea>

                                                    <div className="flex justify-between mt-2">
                                                        <button
                                                            onClick={Target_model_2}
                                                            className="border border-teal-500 text-white font-medium py-2 px-6 rounded-full hover:bg-yellow-500 transition"
                                                        >
                                                            Cancel
                                                        </button>
                                                        <button
                                                            onClick={handleConfirm} // اجرای ذخیره متن هنگام کلیک روی "Confirm"
                                                            className="bg-yellow-500 text-white font-medium py-2 px-6 rounded-full hover:bg-yellow-600 transition"
                                                        >
                                                            Confirm
                                                        </button>
                                                    </div>

                                                </div>


                                            </div>
                                        </div>


                                    </div>
                                )}

                                <div className="space-y-2 mt-2 mb-2">
                                    <p className="text-gray-700 font-semibold dark:text-gray-200">Deduction:</p>
                                    <div className="flex items-center space-x-2">
                                        <img src={selectedToken.icon} alt="Deduction"
                                             className="w-6 h-6"/>
                                        <span
                                            className="text-lg font-bold">0</span>
                                        <span
                                            className="text-gray-500 dark:text-gray-300">{walletData ? walletData.symbol : '...'}</span>
                                    </div>
                                </div>
                                <hr/>
                                <div className="flex items-center justify-center mb-2 mt-2 ">
                                    <button
                                        className="bg-yellow-500 w-full max-w-xl text-white font-medium py-2 px-8 rounded-xl hover:bg-yellow-600 transition">
                                        Withdraw
                                    </button>
                                </div>


                            </div>
                        )}


                    </div>


                    <div className="md:col-span-1">


                        {section_Part_w_2 === 1 && (
                            <div>


                                <div>
                                    <div className="mb-6">
                                        <h3 className="text-lg font-semibold">Total Assets</h3>
                                        <div>
                                            <div
                                                className="w-1/4 mt-2  h-4 bg-gray-300 rounded animate-pulse"></div>
                                        </div>
                                        <div className="flex justify-between text-gray-400 text-sm mt-2">
                                        <span>Available</span>
                                            <span className="text-red-600">---</span>
                                        </div>
                                        <div className="flex justify-between text-gray-400 text-sm mt-2">
                                            <span>Frozen</span>
                                            <span className="text-red-600">---</span>
                                        </div>
                                    </div>
                                    <div className="mb-6">
                                        <h3 className="text-lg font-semibold">Attention</h3>
                                        <div className="text-sm text-orange-500">
                                            <div className="w-2/4 mt-1 h-4 bg-gray-300 rounded animate-pulse"></div>
                                        </div>
                                        <div className="text-sm text-orange-500">
                                            <div className="w-1/4 mt-1 h-4 bg-gray-300 rounded animate-pulse"></div>
                                        </div>
                                        <div className="text-sm text-orange-500">
                                            <div className="w-2/4 mt-1 h-4 bg-gray-300 rounded animate-pulse"></div>
                                        </div>
                                        <div className="text-green-500 text-sm mt-2 block">
                                            <div className="w-1/4 mt-1 h-4 bg-gray-300 rounded animate-pulse"></div>
                                        </div>
                                    </div>

                                    <div>
                                        <h3 className="text-lg font-semibold">FAQ</h3>
                                        <div className="text-sm mb-2">
                                            <div className="text-blue-500">
                                                <div className="w-3/4 mt-1 h-4 bg-gray-300 rounded animate-pulse"></div>
                                            </div>
                                        </div>
                                        <div className="text-sm mb-2">
                                            <div className="text-blue-500">
                                                <div className="w-3/4 mt-1 h-4 bg-gray-300 rounded animate-pulse"></div>
                                            </div>
                                        </div>
                                        <div className="flex space-x-2 mt-2">
                                            <button
                                                className="bg-green-100 text-green-500 px-3 py-1 rounded-lg text-sm">
                                                <div className="w-2/4 mt-1 h-4 bg-gray-300 rounded animate-pulse"></div>
                                            </button>
                                            <button
                                                className="bg-green-100 text-green-500 px-3 py-1 rounded-lg text-sm">
                                                <div className="w-2/4 mt-1 h-4 bg-gray-300 rounded animate-pulse"></div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}


                        {section_Part_w_2 === 2 && (
                            <div>
                                <div className="mb-6">
                                    <h3 className="text-lg font-semibold">Total Assets</h3>
                                    <p>0 {walletData ? walletData.symbol : 'Loading...'}</p>
                                    <div className="flex justify-between text-gray-400 text-sm mt-2">
                                        <span>Available</span>
                                        <span>   {walletData ? walletData.amount : 'Loading...'} {walletData ? walletData.symbol : 'Loading...'}</span>
                                    </div>
                                    <div className="flex justify-between text-gray-400 text-sm mt-2">
                                        <span>Frozen</span>
                                        <span>   {walletData ? walletData.Frozen_amount : 'Loading...'} {walletData ? walletData.symbol : 'Loading...'}</span>
                                    </div>
                                </div>

                                    <h2 className="font-bold text-lg mb-2">Attention</h2>
                                    <p className="text-gray-700 mb-1 flex justify-between items-center dark:text-gray-400">
                                        <span>Minimum Withdrawal</span>
                                        <span className="text-orange-500 ">{walletData ? walletData.Minimum_withdrawal : 'Loading...'} {walletData ? walletData.symbol : 'Loading...'}</span>
                                    </p>
                                    <p className="text-gray-600 text-sm mb-2 dark:text-gray-400">
                                        Arrival time: Normal transfers are sent via crypto network, and the arrival time
                                        depends on the number of confirmations required by the recipient.
                                    </p>

                                <div>
                                    <h3 className="text-lg font-semibold dark:text-gray-200">FAQ</h3>
                                    <p className="text-sm mb-2 "><a href="#" className="text-blue-500 dark:text-gray-400">
                                        Withdrawal Unreceived?
                                    </a>
                                    </p>
                                    <p className="text-sm mb-2"><a href="#" className="text-blue-500 dark:text-gray-400">How to choose the
                                        How to choose the public chain for your withdrawal?
                                    </a></p>
                                    <div className="flex space-x-2 mt-2">
                                        <button className="bg-gray-100  text-black hover:bg-gray-200 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-900 px-3 py-1 rounded-md text-sm">
                                            Newbie Guide
                                        </button>
                                        <button className="bg-gray-100  text-black hover:bg-gray-200 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-900 px-3 py-1 rounded-md text-sm">
                                            Video Tutorial
                                        </button>
                                    </div>
                                </div>

                            </div>
                        )}


                    </div>
                </div>


                {/*------*/}


                {/*------*/}
                <div>
                    <br/><br/><br/><br/><br/>
                </div>
            </div>


        </div>
    );


}

export default Wallet;
