import React, { useState } from 'react';
import { useSpring, animated, useTransition } from 'react-spring';
import { useDrag } from '@use-gesture/react';
import { useMediaQuery } from 'react-responsive';

const SidebarMenu = () => {
    // تعریف وضعیت‌ها برای سه مدل
    const [is_model_1, Modal_Full_1] = useState(false);
    const [is_model_2, Modal_Full_2] = useState(false);
    const [is_model_3, Modal_Full_3] = useState(false);

    // بررسی اندازه صفحه نمایش
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    // توابع برای باز و بسته کردن مدل‌ها
    const Target_model_1 = () => Modal_Full_1(!is_model_1);
    const Target_model_2 = () => Modal_Full_2(!is_model_2);
    const Target_model_3 = () => Modal_Full_3(!is_model_3);

    const [{ y1 }, api1] = useSpring(() => ({ y1: 0 }));
    const [{ y2 }, api2] = useSpring(() => ({ y2: 0 }));
    const [{ y3 }, api3] = useSpring(() => ({ y3: 0 }));

    // تابع برای کشیدن
    const bind1 = useDrag(
        ({ last, movement: [, my], cancel }) => {
            if (my > 300) cancel(); // جلوگیری از کشیدن بیش از حد

            if (last) {
                if (my > 150) Modal_Full_1(false); // مدل را می‌بندد
                api1.start({ y1: 0 }); // بازگشت به موقعیت اولیه
            } else {
                api1.start({ y1: my });
            }
        },
        { from: () => [0, y1.get()], bounds: { top: 0 }, rubberband: true }
    );

    const bind2 = useDrag(
        ({ last, movement: [, my], cancel }) => {
            if (my > 300) cancel(); // جلوگیری از کشیدن بیش از حد

            if (last) {
                if (my > 150) Modal_Full_2(false); // مدل را می‌بندد
                api2.start({ y2: 0 }); // بازگشت به موقعیت اولیه
            } else {
                api2.start({ y2: my });
            }
        },
        { from: () => [0, y2.get()], bounds: { top: 0 }, rubberband: true }
    );

    const bind3 = useDrag(
        ({ last, movement: [, my], cancel }) => {
            if (my > 300) cancel(); // جلوگیری از کشیدن بیش از حد

            if (last) {
                if (my > 150) Modal_Full_3(false); // مدل را می‌بندد
                api3.start({ y3: 0 }); // بازگشت به موقعیت اولیه
            } else {
                api3.start({ y3: my });
            }
        },
        { from: () => [0, y3.get()], bounds: { top: 0 }, rubberband: true }
    );

    // تعریف transitions برای سه مدل
    const transitions_1 = useTransition(is_model_1, {
        from: isMobile ? { opacity: 0, transform: 'translateY(-100%)' } : { opacity: 0 },
        enter: isMobile ? { opacity: 1, transform: 'translateY(0%)' } : { opacity: 1 },
        leave: isMobile ? { opacity: 0, transform: 'translateY(100%)' } : { opacity: 0 },
    });

    const transitions_2 = useTransition(is_model_2, {
        from: isMobile ? { opacity: 0, transform: 'translateY(-100%)' } : { opacity: 0 },
        enter: isMobile ? { opacity: 1, transform: 'translateY(0%)' } : { opacity: 1 },
        leave: isMobile ? { opacity: 0, transform: 'translateY(100%)' } : { opacity: 0 },
    });

    const transitions_3 = useTransition(is_model_3, {
        from: isMobile ? { opacity: 0, transform: 'translateY(100%)' } : { opacity: 0 },
        enter: isMobile ? { opacity: 1, transform: 'translateY(0%)' } : { opacity: 1 },
        leave: isMobile ? { opacity: 0, transform: 'translateY(100%)' } : { opacity: 0 },
    });

    return (
        <div className="flex">
            {/* دکمه‌ها برای باز و بسته کردن مدل‌ها */}
            <button
                onClick={Target_model_1}
                className="text-xl dark:text-gray-400 text-gray-400 dark:hover:text-white hover:text-gray-900"
            >
                <i className="fa-solid fa-xmark"></i>
            </button>

            {/* مدل 1 */}
            {transitions_1(
                (styles, item) =>
                    item && (
                        <animated.div
                            {...(isMobile ? bind1() : {})}
                            style={{
                                ...styles,
                                touchAction: 'none',
                                position: 'fixed',
                                inset: 0,
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                zIndex: 50,
                            }}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <animated.div
                                style={{
                                    ...styles,
                                    transform: isMobile ? y1.to((y) => `translateY(${y}px)`) : 'none',
                                }}
                                className="relative w-full max-w-lg dark:bg-gray-800 bg-white p-6
                    md:max-w-xl md:m-auto md:p-3 md:rounded-lg md:shadow-lg
                    md:relative h-full md:h-auto"
                            >
                                <div>
                                    <div className="flex justify-between items-center mb-4">
                                        <h2 className="text-xl font-semibold">Delete Account1</h2>
                                        <button
                                            onClick={Target_model_1}
                                            className="text-xl dark:text-gray-400 text-gray-400 dark:hover:text-white hover:text-gray-900"
                                        >
                                            <i className="fa-solid fa-xmark"></i>
                                        </button>
                                    </div>
                                    <p className="mb-4">
                                        By requesting to delete the user account, all your information will be completely deleted from the system. All assets on the website will be destroyed.
                                        <br /><br />
                                        Your request will be processed after 15 days. And your account will be completely deleted.
                                        <br /><br />
                                        If you regret your request, contact support
                                    </p>
                                    <div className="mb-4">
                                        <label
                                            className="block text-gray-800 dark:text-gray-400 text-sm mb-2"
                                            htmlFor="Validation"
                                        >
                                            Validation ( Write the word "Delete" )
                                        </label>
                                        <input
                                            type="text"
                                            id="Validation"
                                            className="w-full p-2 rounded-lg dark:bg-gray-900 dark:text-white bg-gray-200 text-black focus:outline-none focus:border-white"
                                        />
                                    </div>
                                </div>
                            </animated.div>
                        </animated.div>
                    )
            )}

            <button
                onClick={Target_model_2}
                className="text-xl dark:text-gray-400 text-gray-400 dark:hover:text-white hover:text-gray-900"
            >
                <i className="fa-solid fa-xmark"></i>
            </button>

            {/* مدل 2 */}
            {transitions_2(
                (styles, item) =>
                    item && (
                        <animated.div
                            {...(isMobile ? bind2() : {})}
                            style={{
                                ...styles,
                                touchAction: 'none',
                                position: 'fixed',
                                inset: 0,
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                zIndex: 50,
                            }}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <animated.div
                                style={{
                                    ...styles,
                                    transform: isMobile ? y2.to((y) => `translateY(${y}px)`) : 'none',
                                }}
                                className="relative w-full max-w-lg dark:bg-gray-800 bg-white p-6
                    md:max-w-xl md:m-auto md:p-3 md:rounded-lg md:shadow-lg
                    md:relative h-full md:h-auto"
                            >
                                <div>
                                    <div className="flex justify-between items-center mb-4">
                                        <h2 className="text-xl font-semibold">Delete Account2</h2>
                                        <button
                                            onClick={Target_model_2}
                                            className="text-xl dark:text-gray-400 text-gray-400 dark:hover:text-white hover:text-gray-900"
                                        >
                                            <i className="fa-solid fa-xmark"></i>
                                        </button>
                                    </div>
                                    <p className="mb-4">
                                        By requesting to delete the user account, all your information will be completely deleted from the system. All assets on the website will be destroyed.
                                        <br /><br />
                                        Your request will be processed after 15 days. And your account will be completely deleted.
                                        <br /><br />
                                        If you regret your request, contact support
                                    </p>
                                    <div className="mb-4">
                                        <label
                                            className="block text-gray-800 dark:text-gray-400 text-sm mb-2"
                                            htmlFor="Validation"
                                        >
                                            Validation ( Write the word "Delete" )
                                        </label>
                                        <input
                                            type="text"
                                            id="Validation"
                                            className="w-full p-2 rounded-lg dark:bg-gray-900 dark:text-white bg-gray-200 text-black focus:outline-none focus:border-white"
                                        />
                                    </div>
                                </div>
                            </animated.div>
                        </animated.div>
                    )
            )}

            <button
                onClick={Target_model_3}
                className="text-xl dark:text-gray-400 text-gray-400 dark:hover:text-white hover:text-gray-900"
            >
                <i className="fa-solid fa-xmark"></i>
            </button>

            {/* مدل 3 */}
            {transitions_3(
                (styles, item) =>
                    item && (
                        <animated.div className="overlayStyles"
                                      {...(isMobile ? bind3() : {})}
                                      style={{
                                          ...styles,
                                          touchAction: 'none',
                                          position: 'fixed',
                                          inset: 0,

                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          zIndex: 50,
                                      }}
                                      onClick={(e) => e.stopPropagation()}
                        >
                            <animated.div
                                style={{
                                    ...styles,
                                    transform: isMobile ? y3.to((y) => `translateY(${y}px)`) : 'none',
                                }}
                                className="relative w-full mt-12 max-w-lg dark:bg-gray-800 bg-gray-100 p-2
                        md:max-w-xl md:m-auto md:p-3 md:rounded-lg
                        md:relative h-full md:h-auto
                        shadow-1xl
                       rounded-t-[30px]
                       dark:text-white
                        "
                            >
                                <div>
                                    <div className="w-20 h-2 dark:bg-gray-500 md:hidden rounded-2xl bg-gray-300 mb-4 m-auto"></div>
                                    <div className="p-3">
                                        <div className="flex justify-between items-center mb-4">
                                            <h2 className="text-xl font-semibold">Delete Account3</h2>
                                            <button
                                                onClick={Target_model_3}
                                                className="text-xl dark:text-gray-400 text-gray-400 dark:hover:text-white hover:text-gray-900"
                                            >
                                                <i className="fa-solid fa-xmark"></i>
                                            </button>
                                        </div>
                                        <p className="mb-4">
                                            By requesting to delete the user account, all your information will be
                                            completely deleted from the system. All assets on the website will be
                                            destroyed.
                                            <br/><br/>
                                            Your request will be processed after 15 days. And your account will be
                                            completely deleted.
                                            <br/><br/>
                                            If you regret your request, contact support
                                        </p>
                                        <div className="mb-4">
                                            <label
                                                className="block text-gray-800 dark:text-gray-400 text-sm mb-2"
                                                htmlFor="Validation"
                                            >
                                                Validation ( Write the word "Delete" )
                                            </label>
                                            <input
                                                type="text"
                                                id="Validation"
                                                className="w-full p-2 rounded-lg dark:bg-gray-900 dark:text-white bg-gray-200 text-black focus:outline-none focus:border-white"
                                            />
                                        </div>
                                    </div>


                                </div>
                            </animated.div>
                        </animated.div>
                    )
            )}
        </div>
    );
};

export default SidebarMenu;
