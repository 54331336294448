import React, { useEffect, useState } from 'react';
import { getCookie } from "../../Format/En/Cookie";
import { toast } from "react-toastify";
import LoadingPage from "../Loading-Page";
import { Link } from "react-router-dom";

const Profile_Notification = () => {
    // Connect to run page
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    useEffect(() => {
        if (!isDataLoaded) {
            Load_Pages_Min().then(() => {
                setIsDataLoaded(true);
            });
        }
    }, [isDataLoaded]);
    //  END Connect to run page
    const [profileData, setProfileData] = useState([]);

    async function Load_Pages_Min() {
        const loaderElement = document.getElementById('Loading_Page');
        const pageElement = document.getElementById('Page');
        pageElement.classList.add('hidden');
        loaderElement.classList.remove('hidden');
        let meeting = getCookie("meeting");

        try {
            const requestData = {
                Session_ID: meeting,
            };
            const response = await fetch(`${window.Web_Url_Api}/v1/Profile/Message/Information.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            });

            if (!response.ok) {
                toast('Internet connection error');
                return;
            }

            const data = await response.json();
            if (Array.isArray(data)) {
                setProfileData(data);

            } else {
                console.error("The response is not an array:", data);
                toast('Received invalid data format');
            }
            section_Part_Re_w_1(1);
            pageElement.classList.remove('hidden');
            loaderElement.classList.add('hidden');

        } catch (error) {
            toast('An error has occurred');
        }
    }
//--------------------------------------------------------------------------------------------------------------------//
    const [section_Part_w_1, section_Part_Re_w_1] = useState(1);
//--------------------------------------------------------------------------------------------------------------------//
    const [Data_Part_1, setData_Part_1] = useState([]);
//--------------------------------------------------------------------------------------------------------------------//
    const Section_Part_1 = (id) => {

        const loaderElement = document.getElementById('Loading_Page');
        const pageElement = document.getElementById('Page');
        pageElement.classList.add('hidden');
        loaderElement.classList.remove('hidden');

        let meeting = getCookie("meeting");
        var data = {
            Session_ID:            meeting,
            Id_Message:            id,

        };

        var actionURL = `${window.Web_Url_Api}/v1/Profile/Message/message.php`;

        fetch(actionURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(data => {
                section_Part_Re_w_1(2);
                setData_Part_1(data);
                pageElement.classList.remove('hidden');
                loaderElement.classList.add('hidden');
            })
            .catch(() => {
                toast('An error has occurred');
            });

    }

    return (
        <div>

            <LoadingPage />
            <div id="Page" className="hidden p-2">


                {section_Part_w_1 === 1 && (
                    <div>
                        <div className="flex items-center space-x-2 text-black dark:text-white ">
                            <Link to="/account/profile/" className="text-2xl">
                                <i className="fa-solid fa-arrow-left"></i>
                            </Link>
                            <h1 className='text-2xl p-5 rounded-[3px]'>Message </h1>
                        </div>

                        {profileData
                            .filter(notification => notification.status === "true")
                            .map((notification, index) => (
                                <div key={index}
                                     className="p-4 bg-gray-100 text-black dark:bg-gray-800 dark:text-white rounded-lg shadow-md mt-4 relative">



                                    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
                                        <div className="flex items-start flex-1">
                                            <button onClick={() => Section_Part_1(notification.id_code)} className="fa-solid fa-envelope text-2xl text-blue-400 mr-3 mt-1"></button>
                                            <div className="flex-1">
                                        <span className="font-semibold text-lg block whitespace-nowrap overflow-hidden overflow-ellipsis">
                                            <button onClick={() => Section_Part_1(notification.id_code)}> {notification.subject} </button>
                                        </span>
                                        <span className="text-sm text-gray-400 sm:max-w-2xl max-w-[220px] block mt-1 truncate">
                                            <button onClick={() => Section_Part_1(notification.id_code)}>{`${notification.Message_unit}`}</button>
                                        </span>
                                            </div>
                                        </div>
                                        <div className="mt-3 sm:mt-0 flex items-center space-x-2 sm:space-x-4">
                                        <span className={`w-3 h-3 ${notification.status === 'true' ? 'bg-green-500' : 'bg-red-500'} rounded-full sm:static absolute top-[23px] right-2 sm:top-auto sm:right-auto`}></span>
                                           <span className="text-gray-400 text-sm">
                                                <button onClick={() => Section_Part_1(notification.id_code)}>{new Date(parseInt(notification.Time_code) * 1000).toLocaleString()}</button>
                                           </span>
                                        </div>
                                    </div>

                                </div>
                            ))}

                    </div>
                )}

                {section_Part_w_1 === 2 && (
                    <div>
                        <div className="flex items-center space-x-2 text-black dark:text-white ">
                            <button onClick={Load_Pages_Min}  className="text-2xl">
                                <i className="fa-solid fa-arrow-left"></i>
                            </button>
                            <h1 className='text-2xl p-5 rounded-[3px]'>View Messages </h1>
                        </div>

                        <div className='p-6 text-black dark:text-white'>
                            <h2 className='text-2xl font-semibold mb-4'>  {Data_Part_1 ? Data_Part_1.subject : 'Loading...'} </h2>
                            <p className='text-sm text-gray-500 mb-6'>  {Data_Part_1 ? Data_Part_1.Time : 'Loading...'} </p>
                            <p className='text-lg dark:text-gray-400 text-gray-500 mb-4' dangerouslySetInnerHTML={{ __html: Data_Part_1 ? Data_Part_1.messages : '' }}></p>
                        </div>
                    </div>
                )}


            </div>

            <div>
                <br/><br/><br/>
            </div>
        </div>
    );
};

export default Profile_Notification;
